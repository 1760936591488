import { render, staticRenderFns } from "./ReportProdProy.vue?vue&type=template&id=6acf1dbc"
import script from "./ReportProdProy.vue?vue&type=script&lang=js"
export * from "./ReportProdProy.vue?vue&type=script&lang=js"
import style0 from "./ReportProdProy.vue?vue&type=style&index=0&id=6acf1dbc&prod&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports