import EjecutoraService from '@/services/Sirsam/ejecutora'

export default {
  'FIND_ALL_EJECUTORA': (_, params) => {
    return new Promise((resolve, reject) => {
      EjecutoraService.findAllEjecutora(params).then(res => {
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  }

}
