import apiCall from '../index.js'
const apiService = '/sirsam/api/v1/presupuesto/'

const service = {
  findAllAnio (params) {
    let query = 'getAnioSemaforo??secEjec=00'
    return new Promise((resolve, reject) => {
      apiCall.get(apiService + query).then(res => resolve(res.data)).catch(err => reject(err))
    })
  },
  findAllExpedienteSiaf (params) {
    let query = 'getExpedienteSiaf?anio=' + params.anio + '&ejecutora=' + params.ejecutora + '&expediente=' + params.expediente + '&nombreApellido=' + params.nombreApellido + '&ruc=' + params.ruc
    return new Promise((resolve, reject) => {
      apiCall.get(apiService + query).then(res => resolve(res.data)).catch(err => reject(err))
    })
  },
  findAllExpedienteSiafDetalleFase (params) {
    let query = 'getExpedienteSiafDetalleFase?anio=' + params.anio + '&ejecutora=' + params.ejecutora + '&expediente=' + params.expediente
    return new Promise((resolve, reject) => {
      apiCall.get(apiService + query).then(res => resolve(res.data)).catch(err => reject(err))
    })
  },
  /* REPORT 02 */
  findAllAnioResumen (params) {
    let query = 'getAnioResumen'
    return new Promise((resolve, reject) => {
      apiCall.get(apiService + query).then(res => resolve(res.data)).catch(err => reject(err))
    })
  },
  findAllGraficoAvancePresupuestal (params) {
    let query = 'getGraficoAvancePresupuestal?anio=' + params.anio + '&ejecutora=' + params.ejecutora
    return new Promise((resolve, reject) => {
      apiCall.get(apiService + query).then(res => resolve(res.data)).catch(err => reject(err))
    })
  },
  findAllGraficoAvancePresupuestalProyecto (params) {
    let query = 'getGraficoAvancePresupuestalProyecto?anio=' + params.anio + '&ejecutora=' + params.ejecutora
    return new Promise((resolve, reject) => {
      apiCall.get(apiService + query).then(res => resolve(res.data)).catch(err => reject(err))
    })
  },
  findAllGraficoAvancePresupuestalProducto (params) {
    let query = 'getGraficoAvancePresupuestalProducto?anio=' + params.anio + '&ejecutora=' + params.ejecutora
    return new Promise((resolve, reject) => {
      apiCall.get(apiService + query).then(res => resolve(res.data)).catch(err => reject(err))
    })
  },
  findAllGraficoAvancePresupuestalMensualizada (params) {
    let query = 'getGraficoAvancePresupuestalMensualizada?anio=' + params.anio + '&ejecutora=' + params.ejecutora
    return new Promise((resolve, reject) => {
      apiCall.get(apiService + query).then(res => resolve(res.data)).catch(err => reject(err))
    })
  },
  findAllGraficoAvancePresupuestalMensualizadaProyecto (params) {
    let query = 'getGraficoAvancePresupuestalMensualizadaProyecto?anio=' + params.anio + '&ejecutora=' + params.ejecutora
    return new Promise((resolve, reject) => {
      apiCall.get(apiService + query).then(res => resolve(res.data)).catch(err => reject(err))
    })
  },
  findAllGraficoAvancePresupuestalMensualizadaProducto (params) {
    let query = 'getGraficoAvancePresupuestalMensualizadaProducto?anio=' + params.anio + '&ejecutora=' + params.ejecutora
    return new Promise((resolve, reject) => {
      apiCall.get(apiService + query).then(res => resolve(res.data)).catch(err => reject(err))
    })
  },

  findAllTablaAvancePresupuestalProyecto (params) {
    let query = 'getTablaAvancePresupuestalProyecto?anio=' + params.anio + '&ejecutora=' + params.ejecutora
    return new Promise((resolve, reject) => {
      apiCall.get(apiService + query).then(res => resolve(res.data)).catch(err => reject(err))
    })
  },
  findAllTablaAvancePresupuestalGenerica (params) {
    let query = 'getTablaAvancePresupuestalGenerica?anio=' + params.anio + '&ejecutora=' + params.ejecutora
    return new Promise((resolve, reject) => {
      apiCall.get(apiService + query).then(res => resolve(res.data)).catch(err => reject(err))
    })
  },
  findAllTablaAvancePresupuestalFuente (params) {
    let query = 'getTablaAvancePresupuestalFuente?anio=' + params.anio + '&ejecutora=' + params.ejecutora
    return new Promise((resolve, reject) => {
      apiCall.get(apiService + query).then(res => resolve(res.data)).catch(err => reject(err))
    })
  },
  findAllTablaAvancePresupuestalCategoria (params) {
    let query = 'getTablaAvancePresupuestalCategoria?anio=' + params.anio + '&ejecutora=' + params.ejecutora
    return new Promise((resolve, reject) => {
      apiCall.get(apiService + query).then(res => resolve(res.data)).catch(err => reject(err))
    })
  }

}

export default service
