<template>
  <!-- TOP Nav Bar -->
  <div class="iq-top-navbar">
    <div class="iq-navbar-custom" :class="horizontal ? 'd-flex align-items-center justify-content-between' : ''">
      <div class="iq-sidebar-logo">
        <div class="top-logo">
          <router-link :to="homeURL">
            <img :src="logo" class="img-fluid" alt="logo">
            <span>GSPlanifica</span>
          </router-link>
        </div>
      </div>
      <div class="iq-menu-horizontal" v-if="horizontal">
        <div class="iq-sidebar-menu">
          <List :items="items" class="d-flex"></List>
        </div>
      </div>
      <nav class="navbar navbar-expand-lg navbar-light p-0 bg-graygradient rounded">
          <div class="navbar-left">
              <ul class="navbar-list float-left">
                <li class="rounded">
                  <a href="#" class="search-toggle iq-waves-effect d-flex align-items-center">
                    <img :src="logoSplash" class="img-fluid rounded" alt="logo">
                  </a>
                </li>
              </ul>
          </div>
          <b-navbar-toggle target="nav-collapse">
            <i class="ri-menu-3-line"></i>
          </b-navbar-toggle>
          <div class="iq-menu-bt align-self-center">
            <div class="wrapper-menu" @click="miniSidebar">
              <div class="main-circle"><i class="ri-arrow-left-s-line"></i></div>
              <div class="hover-circle"><i class="ri-arrow-right-s-line"></i></div>
            </div>
          </div>
          <b-collapse id="nav-collapse" is-nav>
            <slot name="responsiveRight" />
          </b-collapse>
          <slot name="right" />
      </nav>
    </div>
  </div>
  <!-- TOP Nav Bar END -->
</template>
<script>
import List from '../menus/ListStyle1'
import logoSplash from '../../../assets/images/logo-splash.png'
import SideBarItems from '../../../FackApi/json/SideBar'
import { mapGetters } from 'vuex'
export default {
  name: 'NavBarStyle1',
  props: {
    homeURL: { type: Object, default: () => ({ name: 'dashboard.dashboard' }) },
    title: { type: String, default: 'Dashboard' },
    logo: { type: String, default: require('../../../assets/images/logo.png') },
    horizontal: { type: Boolean, default: false },
    items: { type: Array }
  },
  mounted () {
    document.addEventListener('click', this.closeSearch, true)
  },
  components: {
    List
  },
  computed: {
    ...mapGetters({
      bookmark: 'Setting/bookmarkState'
    })
  },
  data () {
    return {
      sidebar: SideBarItems,
      logoSplash: logoSplash
    }
  },
  methods: {
    miniSidebar () {
      this.$emit('toggle')
    }
  }
}
</script>
