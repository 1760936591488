<template>
  <b-row>
    <b-col md="12">
      <iq-card class-name="overflow-hidden">
        <template v-slot:headerTitle>
          <h4 class="card-title">Ranking Unidades Ejecutoras</h4>
        </template>
        <template v-slot:headerAction>
          <b-dropdown id="dropdownMenuButton1" right variant="none" data-toggle="dropdown">
            <template v-slot:button-content>
                <i class="ri-more-fill"></i>
            </template>
            <b-dropdown-group id="dropdown-group-1" header="Columnas" class="pl-0">
              <b-dropdown-form>
                <div
                  v-for="field in fieldsUESEG"
                  :key="field.id">
                  <b-form-checkbox
                    class="d-block"
                    switch
                    :disabled="field.disabledF"
                    v-if="!field.hiddenF"
                    v-model="field.visible"
                    v-show="false"
                    >
                    {{ field.label }}
                  </b-form-checkbox>
                  </div>
              </b-dropdown-form>
            </b-dropdown-group>
            <!-- <b-dropdown-item @click="downloadXls('UE')"><i class="ri-file-excel-line mr-2 align-middle"></i> Descargar en Excel</b-dropdown-item>
            <b-dropdown-item @click="shareWtp('UE')"><i class="ri-whatsapp-line mr-2 align-middle"></i> Compartir por Whatsapp</b-dropdown-item> -->
          </b-dropdown>
        </template>
        <template v-slot:body>
          <b-table hover striped :items="tableUESEG" :fields="visibleFields" class="mb-0 table-borderless p-0" :sort-compare="compareCurrency" :sort-by.sync="sortByUEEG" :sort-desc.sync="sortDescUEEG" foot-clone @row-clicked="onRowClickedUE">
            <template #cell(index)="data">
              <b-badge pill variant="dark">{{ data.index + 1 }}</b-badge>
            </template>
            <template v-slot:cell(ue)="data">
              <b-badge pill variant="dark">{{ data.index + 1 }}</b-badge>
              <b-badge class="ml-1" pill :variant="data.value.color">{{ data.value.codigo }}</b-badge>
              <span class="font-size-9 pl-1">{{ getNombreEjecuctora(data.value.nombre) }}</span>
            </template>
            <template v-slot:cell(pia_ue)="data_ue_pia">
              <p class='text-right mb-0'><span class="font-size-10 text-nowrap">{{ getNumbertoMoney(data_ue_pia.value, 'S/.') }}</span></p>
            </template>
            <template v-slot:cell(pim_ue)="data_ue_pim">
              <p class='text-right mb-0'><span class="font-size-10 text-nowrap">{{ getNumbertoMoney(data_ue_pim.value, 'S/.') }}</span></p>
            </template>
            <template v-slot:cell(certificado_ue)="data_ue_certificado">
              <p class='text-right mb-0'><span class="font-size-10 text-nowrap">{{ getNumbertoMoney(data_ue_certificado.value, 'S/.') }}</span></p>
            </template>
            <template v-slot:cell(compromiso_a_ue)="data_ue_compromiso_a">
              <p class='text-right mb-0'><span class="font-size-10 text-nowrap">{{ getNumbertoMoney(data_ue_compromiso_a.value, 'S/.') }}</span></p>
            </template>
            <template v-slot:cell(compromiso_m_ue)="data_ue_compromiso_m">
              <p class='text-right mb-0'><span class="font-size-10 text-nowrap">{{ getNumbertoMoney(data_ue_compromiso_m.value, 'S/.') }}</span></p>
            </template>
            <template v-slot:cell(xcertificar_ue)="data_ue_xcert">
              <p class='text-right mb-0'><span class="font-size-10 text-nowrap">{{ getNumbertoMoney(data_ue_xcert.value, 'S/.') }}</span></p>
            </template>
            <template v-slot:cell(devengado_ue)="data_ue_dev">
              <p class='text-right mb-0'><span class="font-size-10 text-nowrap">{{ getNumbertoMoney(data_ue_dev.value, 'S/.') }}</span></p>
            </template>
            <template v-slot:cell(xdevengar_ue)="data_ue_xdev">
              <p class='text-right mb-0'><span class="font-size-10 text-nowrap">{{ getNumbertoMoney(data_ue_xdev.value, 'S/.') }}</span></p>
            </template>
            <template v-slot:cell(xdevengar_m_ue)="data_m_ue_xdev">
              <p class='text-right mb-0'><span class="font-size-10 text-nowrap">{{ getNumbertoMoney(data_m_ue_xdev.value, 'S/.') }}</span></p>
            </template>
            <template v-slot:cell(girado_ue)="data_ue_girado">
              <p class='text-right mb-0'><span class="font-size-10 text-nowrap">{{ getNumbertoMoney(data_ue_girado.value, 'S/.') }}</span></p>
            </template>
            <template v-slot:cell(avance_ue)="data_ue_avance">
              <center><span class="font-size-10 font-weight-bold">{{ data_ue_avance.value.count }}%</span></center>
              <b-progress :value="data_ue_avance.value.count" class="iq-progress-bar" :variant="getColorSemaforo(data_ue_avance.value.count, 'cls')">
              </b-progress>
            </template>
            <template class='text-right' v-slot:foot(ue)="foot_data_col1">
              <p class='text-right'><span>Total</span></p>
            </template>
            <template class='text-right' v-slot:foot(pia_ue)="foot_data_col3">
              <p class='text-right'><span class="font-size-10 font-weight-bold">S/. {{ totalPIAUES.toLocaleString('en-US') }}</span></p>
            </template>
            <template class='text-right' v-slot:foot(pim_ue)="foot_data_col3">
              <p class='text-right'><span class="font-size-10 font-weight-bold">S/. {{ totalPIMUES.toLocaleString('en-US') }}</span></p>
            </template>
            <template class='text-right' v-slot:foot(certificado_ue)="foot_data_col2">
              <p class='text-right'><span class="font-size-10 font-weight-bold">S/. {{ totalCertificadoUES.toLocaleString('en-US') }}</span></p>
            </template>
            <template class='text-right' v-slot:foot(compromiso_a_ue)="foot_data_col3">
              <p class='text-right'><span class="font-size-10 font-weight-bold">S/. {{ totalCompromisoAUES.toLocaleString('en-US') }}</span></p>
            </template>
            <template class='text-right' v-slot:foot(compromiso_m_ue)="foot_data_col3">
              <p class='text-right'><span class="font-size-10 font-weight-bold">S/. {{ totalCompromisoMUES.toLocaleString('en-US') }}</span></p>
            </template>
            <template class='text-right' v-slot:foot(xcertificar_ue)="foot_data_col6">
              <p class='text-right'><span class="font-size-10 font-weight-bold">S/. {{ totalXCertificadoUES.toLocaleString('en-US') }}</span></p>
            </template>
            <template class='text-right' v-slot:foot(devengado_ue)="foot_data_col4">
              <p class='text-right'><span class="font-size-10 font-weight-bold">S/. {{ totalDEVUES.toLocaleString('en-US') }}</span></p>
            </template>
            <template class='text-right' v-slot:foot(xdevengar_ue)="foot_data_col4">
              <p class='text-right'><span class="font-size-10 font-weight-bold">S/. {{ totalXDEVUES.toLocaleString('en-US') }}</span></p>
            </template>
            <template class='text-right' v-slot:foot(xdevengar_m_ue)="foot_data_col4">
              <p class='text-right'><span class="font-size-10 font-weight-bold">S/. {{ totalXDEVMUES.toLocaleString('en-US') }}</span></p>
            </template>
            <template class='text-right' v-slot:foot(girado_ue)="foot_data_col4">
              <p class='text-right'><span class="font-size-10 font-weight-bold">S/. {{ totalGiradoUES.toLocaleString('en-US') }}</span></p>
            </template>
            <template class='text-right' v-slot:foot(avance_ue)="foot_data_col5">
              <p class='text-right'><span class="font-size-10 font-weight-bold"></span></p>
            </template>
          </b-table>
        </template>
      </iq-card>
    </b-col>
  </b-row>
</template>
<script>
export default {
  name: 'TableUERanking',
  components: { },
  mounted () {
    // this.loadData()
  },
  data () {
    return {
      AnioSel: 0,
      ActProySel: '',
      tipoSemaforo: 'FIJO',
      PliegoSel: '013',
      tableUESEG: [],
      sortByUEEG: 'avance_ue',
      EjecutoraSel: '018',
      sortDescUEEG: true,
      fieldsUESEG: [
        {
          key: 'index',
          label: 'Posición',
          sortable: true,
          tdClass: 'd-none',
          thClass: 'd-none',
          visible: false,
          disabledF: true,
          hiddenF: true
        },
        {
          key: 'ue',
          label: 'Unidad Ejecutora',
          sortable: false,
          tdClass: 'pt-0 pb-0 align-middle',
          visible: true,
          disabledF: true,
          hiddenF: false
        },
        {
          key: 'pia_ue',
          label: 'Pia',
          sortable: true,
          tdClass: 'pt-0 pb-0 align-middle',
          thClass: 'text-right align-middle',
          visible: false,
          disabledF: false,
          hiddenF: false
        },
        {
          key: 'pim_ue',
          label: 'Pim',
          sortable: true,
          tdClass: 'pt-0 pb-0 align-middle',
          thClass: 'text-right align-middle',
          visible: true
        },
        {
          key: 'certificado_ue',
          label: 'Certificado',
          sortable: true,
          tdClass: 'pt-0 pb-0 align-middle',
          thClass: 'text-right align-middle',
          visible: false
        },
        {
          key: 'compromiso_a_ue',
          label: 'Compromiso Anual',
          sortable: true,
          tdClass: 'pt-0 pb-0 align-middle',
          thClass: 'text-right align-middle',
          visible: false
        },
        {
          key: 'compromiso_m_ue',
          label: 'Compromiso Mensual',
          sortable: true,
          tdClass: 'pt-0 pb-0 align-middle',
          thClass: 'text-right align-middle',
          visible: false
        },
        {
          key: 'xcertificar_ue',
          label: 'Por Certificar',
          sortable: true,
          tdClass: 'pt-0 pb-0 align-middle',
          thClass: 'text-right align-middle',
          visible: false
        },
        {
          key: 'devengado_ue',
          label: 'Devengado',
          sortable: true,
          tdClass: 'pt-0 pb-0 align-middle',
          thClass: 'text-right align-middle',
          visible: true
        },
        {
          key: 'xdevengar_ue',
          label: 'Por Devengar',
          sortable: true,
          tdClass: 'pt-0 pb-0 align-middle',
          thClass: 'text-right align-middle',
          visible: true
        },
        {
          key: 'xdevengar_m_ue',
          label: 'Por Devengar Mes',
          sortable: true,
          tdClass: 'pt-0 pb-0 align-middle',
          thClass: 'text-right align-middle',
          visible: false
        },
        {
          key: 'girado_ue',
          label: 'Girado',
          sortable: true,
          tdClass: 'pt-0 pb-0 align-middle',
          thClass: 'text-right align-middle',
          visible: false
        },
        {
          key: 'avance_ue',
          label: 'Avance',
          sortable: true,
          tdClass: 'pt-0 pb-0 align-middle',
          thClass: 'text-center align-middle',
          visible: true
        }
      ],
      totalPIAUES: 0,
      totalPIMUES: 0,
      totalCertificadoUES: 0,
      totalXCertificadoUES: 0,
      totalDEVUES: 0,
      totalXDEVUES: 0,
      totalXDEVMUES: 0,
      totalCompromisoAUES: 0,
      totalCompromisoMUES: 0,
      totalGiradoUES: 0,
      totalAvanceUES: 0,
      egUES: []
    }
  },
  methods: {
    downloadXls (seccion) {
      let that = this
      if (seccion === 'P') {
        this.$store
          .dispatch('gsplanifica/REPORTESXLS_GETPLIEGOS', { anio: that.AnioSel, actProy: that.ActProySel, codPliego: 'todos', modulo: 'diaria' })
          .then(res => {
            let a = document.createElement('a')
            a.target = '_blank'
            a.href = res
            a.download = 'descargar.xls'
            a.click()
          })
          .catch(error => {
            console.log('error de acceso' + error)
          })
      } else if (seccion === 'UE') {
        this.$store
          .dispatch('gsplanifica/REPORTESXLS_GETEJECUTORAS', { anio: that.AnioSel, actProy: that.ActProySel, codPliego: that.PliegoSel, codEjecutora: 'todos', modulo: 'diaria' })
          .then(res => {
            let a = document.createElement('a')
            a.target = '_blank'
            a.href = res
            a.download = 'descargar.xls'
            a.click()
          })
          .catch(error => {
            console.log('error de acceso' + error)
          })
      }
    },
    shareWtp (seccion) {
      let that = this
      let celularFmt = '51992722863'
      let urlWtp = 'https://wa.me/'
      let msj = '/?text=Saludos, estoy compartiendo en instantáneo a través de DATAManager el estado de ejecución presupuestal actual a nivel de '
      if (seccion === 'P') {
        this.$store
          .dispatch('gsplanifica/REPORTESXLS_GETPLIEGOS', { anio: that.AnioSel, actProy: that.ActProySel, codPliego: 'todos', modulo: 'diaria' })
          .then(res => {
            msj = msj + 'Pliegos en el siguiente link: ' + res
            let urlGo = urlWtp + celularFmt + msj
            window.open(urlGo, '_blank')
          })
          .catch(error => {
            console.log('error de acceso' + error)
          })
      } else if (seccion === 'UE') {
        this.$store
          .dispatch('gsplanifica/REPORTESXLS_GETEJECUTORAS', { anio: that.AnioSel, actProy: that.ActProySel, codPliego: that.PliegoSel, codEjecutora: 'todos', modulo: 'diaria' })
          .then(res => {
            msj = msj + 'Unidades Ejecutoras en el siguiente link: ' + res
            let urlGo = urlWtp + celularFmt + msj
            window.open(urlGo, '_blank')
          })
          .catch(error => {
            console.log('error de acceso' + error)
          })
      }
    },
    compareCurrency (itemA, itemB, key) {
      if (key !== 'pim' && key !== 'pia' && key !== 'devengado' && key !== 'certificacion') {
        return false
      } else {
        let a = itemA[key]
        let b = itemB[key]
        a = a.replace('S/. ', '')
        a = a.replace(/,/g, '')
        b = b.replace('S/. ', '')
        b = b.replace(/,/g, '')
        return a - b
      }
    },
    onRowClickedUE (item, index, event) {
      /* if (item.ue.codigo.split('-')[0] !== this.EjecutoraSel) {
        alert('No tienes permiso para acceder a esta información')
      } else {
        this.$router.push({ name: 'dashboard.dashboard-ue', params: { codigo: item.ue.codigo } })
      } */
    },
    getNombreEjecuctora (ejecutora) {
      let ejeFt = ejecutora.replace('REGION SAN MARTIN', '')
      ejeFt = ejeFt.replace('GOB.REG. DPTO. SAN MARTIN', '')
      ejeFt = ejeFt.replace('GOB.REG.DPTO. SAN MARTIN', '')
      ejeFt = ejeFt.replace('GOB. REG. SAN MARTIN', '')

      ejeFt = ejeFt.replace('REGION LA LIBERTAD', '')
      ejeFt = ejeFt.replace('GOB. REG. DE LA LIBERTAD', '')
      ejeFt = ejeFt.replace('R.LA LIBERTAD', '')

      ejeFt = ejeFt.replace('-', '')
      ejeFt = ejeFt.trim()
      return ejeFt
    },
    getNumbertoMoney (num, symbol) {
      return symbol + ' ' + Number(num).toLocaleString('en-US')
    },
    getColorSemaforo (avance, format) {
      let color = 'success'
      if (this.tipoSemaforo === 'FIJO') {
        if (avance >= 50) {
          color = 'success'
        } else if (avance >= 33 && avance < 50) {
          color = 'warning'
        } else {
          color = 'danger'
        }
      } else {
        let diasFeb = new Date(this.anioV, 2, 0).getDate()
        let diaActualAnio = Math.ceil((this.fechaHoy - new Date(this.fechaHoy.getFullYear(), 0, 1)) / 86400000) - 1
        let diasAnio = 365
        if (diasFeb === 29) {
          diasAnio = 366
        }
        let porcDiario = 100 / diasAnio
        let porcIdeal = diaActualAnio * porcDiario
        let valueDifference = avance - porcIdeal

        if (valueDifference >= 0) {
          color = 'success'
        } else {
          if (valueDifference > -6) {
            color = 'warning'
          } else {
            color = 'danger'
          }
        }
      }
      if (format === 'hex') {
        color = this.colorClstoHex(color)
      }
      return color
    },
    async loadData () {
      let that = this
      that.tableUESEG = []
      that.egUES = []
      await this.$store
        .dispatch('gsplanifica/REPORTES_GETEJECUTORAS', { anio: that.AnioSel, actProy: that.ActProySel, codPliego: that.PliegoSel, codEjecutora: 'todos', modulo: 'diaria' })
        .then(res => {
          if (res !== undefined) {
            that.totalPIAUES = 0
            that.totalPIMUES = 0
            that.totalCertificadoUES = 0
            that.totalXCertificadoUES = 0
            that.totalCompromisoAUES = 0
            that.totalCompromisoMUES = 0
            that.totalDEVUES = 0
            that.totalXDEVUES = 0
            that.totalXDEVMUES = 0
            that.totalGiradoUES = 0
            that.totalAvanceUES = 0
            res.forEach(function (eje) {
              if (eje.listaEG.length !== 0 && eje.codFinan !== '155') {
                that.egUES.push({ cod: eje.codFinan, eg: eje })
                that.totalPIAUES = that.totalPIMUES + eje.listaEG[0].pia
                that.totalPIMUES = that.totalPIMUES + eje.listaEG[0].pim
                that.totalCertificadoUES = that.totalCertificadoUES + eje.listaEG[0].certificacion
                that.totalCompromisoAUES = that.totalCompromisoAUES + eje.listaEG[0].compromisoAnual
                that.totalCompromisoMUES = that.totalCompromisoMUES + eje.listaEG[0].atencionCompromisoMensual
                that.totalXCertificadoUES = that.totalXCertificadoUES + (eje.listaEG[0].pim - eje.listaEG[0].certificacion)
                that.totalDEVUES = that.totalDEVUES + eje.listaEG[0].devengado
                that.totalXDEVUES = that.totalXDEVUES + (eje.listaEG[0].pim - eje.listaEG[0].devengado)
                that.totalXDEVMUES = that.totalXDEVUES + (eje.listaEG[0].atencionCompromisoMensual - eje.listaEG[0].devengado)
                that.totalGiradoUES = that.totalGiradoUES + eje.listaEG[0].girado
                that.totalAvanceUES = that.totalAvanceUES + eje.listaEG[0].avance
                let colorSema = that.getColorSemaforo(eje.listaEG[0].avance, 'cls')
                let nombreEje = eje.nombre.replace('REGION SAN MARTIN', '')
                nombreEje = nombreEje.replace('-', '')
                nombreEje = nombreEje.trim()
                let xcert = eje.listaEG[0].pim - eje.listaEG[0].certificacion
                let xdevengar = eje.listaEG[0].pim - eje.listaEG[0].devengado
                let xdevengarm = eje.listaEG[0].atencionCompromisoMensual - eje.listaEG[0].devengado
                let rowVariant = ''
                if (that.EjecutoraSel === eje.codPptal) {
                  rowVariant = 'warning'
                }
                that.tableUESEG.push({
                  ue: { color: colorSema, codigo: eje.codPptal + '-' + eje.codFinan, nombre: nombreEje },
                  pia_ue: eje.listaEG[0].pia,
                  pim_ue: eje.listaEG[0].pim,
                  certificado_ue: eje.listaEG[0].certificacion,
                  xcertificar_ue: xcert,
                  devengado_ue: eje.listaEG[0].devengado,
                  xdevengar_ue: xdevengar,
                  avance_ue: { color: 'danger', count: eje.listaEG[0].avance },
                  compromiso_a_ue: eje.listaEG[0].compromisoAnual,
                  compromiso_m_ue: eje.listaEG[0].atencionCompromisoMensual,
                  girado_ue: eje.listaEG[0].girado,
                  xdevengar_m_ue: xdevengarm,
                  _rowVariant: rowVariant
                })
              }
            })
            let totalAv = (that.totalDEVUES / that.totalPIMUES * 100)
            that.totalAvanceUES = Math.round(totalAv * 100) / 100
          }
        })
        .catch(error => {
          if (error.response.status === 401 || error.response.status === 500) {
            console.log('error de acceso')
          }
        })
    }
  },
  computed: {
    visibleFields () {
      return this.fieldsUESEG.filter(field => field.visible)
    }
  }
}
</script>
