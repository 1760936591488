import reportesService from '@/services/GSPlanifica/reportes'

export default {
  'REPORTES_GETPLIEGOS': (_, params) => {
    return new Promise((resolve, reject) => {
      reportesService.getPliegos(params).then(res => {
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  },
  'REPORTES_GETLINEATIEMPO': (_, params) => {
    return new Promise((resolve, reject) => {
      reportesService.getLineaTiempo(params).then(res => {
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  },
  'REPORTES_GETLINEATIEMPO_MENSUAL': (_, params) => {
    return new Promise((resolve, reject) => {
      reportesService.getLineaTiempoMensual(params).then(res => {
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  },
  'REPORTES_GETPRODPROY': (_, params) => {
    return new Promise((resolve, reject) => {
      reportesService.getProdProy(params).then(res => {
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  },
  'REPORTES_GETEJECUTORAS': (_, params) => {
    return new Promise((resolve, reject) => {
      reportesService.getEjecutoras(params).then(res => {
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  },
  'REPORTES_GETLISTAEJECUTORAS': (_, params) => {
    return new Promise((resolve, reject) => {
      reportesService.getListaEjecutoras(params).then(res => {
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  },
  'REPORTES_GETCLASIFICADORES': (_, params) => {
    return new Promise((resolve, reject) => {
      reportesService.getClasificadores(params).then(res => {
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  },
  'REPORTES_GETCLASIFICADORES_PY': (_, params) => {
    return new Promise((resolve, reject) => {
      reportesService.getClasificadoresPy(params).then(res => {
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  },
  'REPORTESXLS_GETCLASIFICADORES': (_, params) => {
    return reportesService.getClasificadoresXls(params)
  },
  'REPORTESXLS_GETPLIEGOS': (_, params) => {
    return reportesService.getPliegosXls(params)
  },
  'REPORTESXLS_GETEJECUTORAS': (_, params) => {
    return reportesService.getEjecutorasXls(params)
  },
  'REPORTES_GETFUENTEFINAN': (_, params) => {
    return new Promise((resolve, reject) => {
      reportesService.getFuenteFinan(params).then(res => {
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  }

}
