<template>
  <b-container fluid>
    <b-row>
      <b-col md="12">
        <iq-card>
          <div class="d-flex align-items-center justify-content-between pl-2 pr-3">
            <div class="d-flex align-items-center">
              <h4 class="mb-0 p-3">{{ page.name }}</h4><br />
              <b-breadcrumb :items="page.breadCrumb" class="bg-transparent mb-0 mt-1" />
            </div>
            <div class="d-flex justify-content-between">
              <div class="d-flex">
                <b-form-group
                  class="row"
                  label-cols-sm="2"
                  label="Reporte:"
                  label-for="tipoReporte"
                  style="padding-top:2%"
                  >
                    <b-form-select v-model="selectTipoReporte" type="select" class="mr-2" v-on:change="getSelectedReport" required>
                      <option  v-for="tr in optionTipoReporte"  :key="tr.value" :value="tr.value"  >{{tr.text}}</option>
                    </b-form-select>
                </b-form-group>
              </div>
            </div>
          </div>
        </iq-card>
      </b-col>
    </b-row>
    <b-row v-show="report4">
      <b-col md="12">
        <b-row>
        <b-col md="6">
          <iq-card class-name="overflow-hidden">
          <template v-slot:body>
            <b-row>
            <b-col md="12">
            <h4 class="card-title text-left">Filtro de Reportes</h4>
            </b-col>
            </b-row>
            <br>
            <b-row>
            <b-col md="12">
              <b-form-group label="Ejecutora" label-for="rpt_from1">
              <b-form-select v-model="selectEjecutoraR4" type="select" class="custom-select"  required>
                  <option  v-for="eje in optionEjecutora"  :key="eje.secEjec" :value="eje.secEjec"  >{{eje.nombre}}</option>
                </b-form-select>
              </b-form-group>
            </b-col>
            </b-row>
            <b-row>
            <b-col md="5">
              <b-form-group label="Año" label-for="rpt_from1">
                <b-form-select v-model="selectAnioR4" type="select" class="custom-select"  required>
                  <option value="">SELECCIONE::</option>
                  <option  v-for="a in optionAnio"  :key="a.anoEje" :value="a.anoEje"  >{{a.anoEje}}</option>
                </b-form-select>
              </b-form-group>
            </b-col>
            <b-col md="7">
              <b-form-group label="Número Expediente" label-for="numeroExpediente">
                <b-form-input type="text" id="numeroExpediente" v-model="expediente4" placeholder="N° Expediente..."></b-form-input>
              </b-form-group>
            </b-col>
            </b-row>
            <div id="divChtREG"></div>
            <b-row>
            <b-col md="8">
              <b-form-group>
                <b-form-radio-group  id="radio-group-2" name="radio-sub-component"  v-model="rucNombre" :options="optionsRucNombre" @change="checkRucNombre($event)">
                </b-form-radio-group>
              </b-form-group>
            </b-col>
            </b-row>
            <b-row>
            <b-col md="5" v-show="stateRuc">
              <b-form-group label="Ruc de Proveedor" label-for="rucProveedor">
                <b-form-input type="text" id="rucProveedor" v-model="ruc4" placeholder="Ruc del proveedor..."></b-form-input>
              </b-form-group>
            </b-col>
            <b-col md="12" v-show="stateNombre">
              <b-form-group label="Nombre del Proveedor" label-for="nombreProveedor">
                <b-form-input type="text" id="rucProveedor" v-model="nombreApellido4" placeholder="Nombre y apellidos del proveedor..." size="sm"></b-form-input>
              </b-form-group>
            </b-col>
            </b-row>
            <b-row>
              <b-col md="12" style="text-align:center">
              <b-button variant = "success" @click="generar4"><i class="ri-play-fill"></i>Generar</b-button>
              <b-button  style="margin-left:1%" variant = "danger" @click="limpiar4"><i class="ri-eraser-fill"></i>Limpiar</b-button>
              </b-col>
            </b-row>
            <div id="divChtREG"></div>
          </template>
          </iq-card>
        </b-col>
        <b-col md="6">
          <iq-card v-show="sifExpedienteReporte4">
          <template v-slot:body>
            <b-row>
            <b-col md="12">
            <h4 class="card-title text-left">Lista de Expedientes</h4>
            </b-col>
            </b-row>
            <br>
            <b-row>
            <div style="height: 270px;display: inline-block;width: 100%;overflow-y: auto;">
            <b-col md="12">
              <ul class="suggestions-lists m-0 p-0">
              <li v-for="(exp,index) in optionexpediente4" :key="index" class="d-flex mb-4 align-items-center">
                <div :class="'profile-icon iq-bg-success'" ><span><i class="ri-file-line"></i></span></div><!--ri-file-history-line-->
                <div class="media-support-info ml-3">
                <h6><span :class="'text-primary'" style=" font-weight:bold;">Rubro Financiamiento: </span> {{ exp.fuente_financ+'-'+exp.nombre_fuente_financ }}</h6>
                <p class="mb-0"><span :class="'text-success'" style=" font-weight:bold">Tipo recurso: </span><span style="font-size:13px">{{ exp.tipo_recurso+'-'+exp.nombre_tipo_recurso }}</span></p>
                </div>
                <div class="media-support-amount ml-3">
                <h5><a href="javascript:void(0)" @click.stop="versifExpedienteReporteResumen(exp)" style="text-decoration: none;"><span class="btn iq-bg-primary mb-1 mr-1 btn-none"><b>{{ exp.expediente }}</b></span></a></h5><!-- badge badge-primary badge-pill-->
                </div>
              </li>
              </ul>
            </b-col>
            </div>
            </b-row>
          </template>
          </iq-card>
        </b-col>
        </b-row>
        <b-row>
        <b-col md="12">
          <iq-card v-show="sifExpedienteReporteResumenR4">
          <template v-slot:body>
            <b-row>
            <b-col md="12">
              <b-input-group class="sm-12">
                <label class="typo__label">
                <b-button variant="none" class="iq-bg-success mb-1 mr-1">
                NÚMERO DE EXPEDIENTE :
                </b-button>
                </label>
                <b-input-group-append>
                <label class="typo__label">
                  <b-button variant="none" class="iq-bg-primary mb-1 mr-1">
                  <span>{{detNumeroExpedienteSiaf4}}</span>
                  </b-button>
                </label>
                </b-input-group-append>
              </b-input-group>
            </b-col>
              <b-col md="12">
              <b-input-group class="sm-12">
                <label class="typo__label">
                <b-button variant="none" class="iq-bg-success mb-1 mr-1">
                RUBRO DE FINANCIAMIENTO :
                </b-button>
                </label>
                <b-input-group-append>
                <label class="typo__label">
                  <b-button variant="none" class="iq-bg-primary mb-1 mr-1">
                  <span>{{detRubroFinanciamientoSiaf4}}</span>
                  </b-button>
                </label>
                </b-input-group-append>
              </b-input-group>
            </b-col>
            </b-row>
            <b-row>
            <b-col md="12">
            <h4 class="card-title text-center">Resumen</h4>
            </b-col>
            </b-row>
            <br>
            <b-row>
            <b-col md="12" class="table-responsive">
            <b-table bordered hover striped :items="ItemsResumenR4" :fields="fieldsResumenR4" :sort-compare="compareCurrency" class="table_ejecucion_presupuestal" foot-clone >
              <template v-slot:cell(ciclo)="data_ciclo">
              <p class='text-left mb-0'><span class="font-size-11">{{ data_ciclo.value }}</span></p>
              </template>
              <template v-slot:cell(fase)="data_fase">
              <p class='text-left mb-0'><span class="font-size-11">{{ data_fase.value }}</span></p>
              </template>
              <template v-slot:cell(certificacion)="data_certificacion">
              <p class='text-left mb-0'><span class="font-size-11">{{ data_certificacion.value }}</span></p>
              </template>
              <template v-slot:cell(nombre_documento)="data_nombre_documento">
              <p class='text-left mb-0'><span class="font-size-11">{{ data_nombre_documento.value }}</span></p>
              </template>
              <template v-slot:cell(numero)="data_numero">
              <p class='text-left mb-0'><span class="font-size-11">{{ data_numero.value }}</span></p>
              </template>
              <template v-slot:cell(fecha_doc)="data_fecha_doc">
              <p class='text-left mb-0'><span class="font-size-11">{{ data_fecha_doc.value }}</span></p>
              </template>
              <template v-slot:cell(monto)="data_monto">
              <p class='text-right mb-0'><span class="font-size-11">{{ data_monto.value }}</span></p>
              </template>
              <template v-slot:cell(ruc)="data_ruc">
              <p class='text-left mb-0'><span class="font-size-11">{{ data_ruc.value }}</span></p>
              </template>
              <template v-slot:cell(nombre)="data_nombre">
              <p class='text-left mb-0'><span class="font-size-11">{{ data_nombre.value }}</span></p>
              </template>
              <template v-slot:cell(banco)="data_banco">
              <p class='text-left mb-0'><span class="font-size-11">{{ data_banco.value }}</span></p>
              </template>
              <template v-slot:cell(cuenta)="data_cuenta">
              <p class='text-left mb-0'><span class="font-size-11">{{ data_cuenta.value }}</span></p>
              </template>
              <template v-slot:cell(anioCta)="data_anioCta">
              <p class='text-left mb-0'><span class="font-size-11">{{ data_anioCta.value }}</span></p>
              </template>
              <template v-slot:cell(estado_envio)="data_estado_envio">
                <b-badge pill variant="success">{{ data_estado_envio.value }}</b-badge>
              <!--<p class='text-center mb-0'><span class="font-size-11 badge badge-primary badge-pill">{{ data_estado_envio.value }}</span></p>-->
              </template>
              <template class='text-right' v-slot:foot(ciclo)="foot_data_col1">
              <p class='text-left'><span></span></p>
              </template>
              <template class='text-right' v-slot:foot(fase)="foot_data_col1">
              <p class='text-right'><span></span></p>
              </template>
              <template class='text-right' v-slot:foot(certificacion)="foot_data_col1">
              <p class='text-right'><span></span></p>
              </template>
              <template class='text-right' v-slot:foot(nombre_documento)="foot_data_col1">
              <p class='text-right'><span></span></p>
              </template>
              <template class='text-right' v-slot:foot(numero)="foot_data_col1">
              <p class='text-right'><span></span></p>
              </template>
              <template class='text-right' v-slot:foot(fecha_doc)="foot_data_col1">
              <p class='text-right'><span></span></p>
              </template>
              <template class='text-right' v-slot:foot(monto)="foot_data_col1">
              <p class='text-right'><span></span></p>
              </template>
              <template class='text-right' v-slot:foot(ruc)="foot_data_col1">
              <p class='text-right'><span></span></p>
              </template>
              <template class='text-right' v-slot:foot(nombre)="foot_data_col1">
              <p class='text-right'><span></span></p>
              </template>
              <template class='text-right' v-slot:foot(banco)="foot_data_col1">
              <p class='text-right'><span></span></p>
              </template>
              <template class='text-right' v-slot:foot(cuenta)="foot_data_col1">
              <p class='text-right'><span></span></p>
              </template>
                <template class='text-right' v-slot:foot(anioCta)="foot_data_col1">
              <p class='text-right'><span></span></p>
              </template>
              <template class='text-right' v-slot:foot(estado_envio)="foot_data_col1">
              <p class='text-right'><span></span></p>
              </template>
            </b-table>
            </b-col>
            </b-row>
            <b-row>
            <b-col md="12"  v-show="sifExpedienteReportDetalleGastoR4">
            <h4 class="card-title text-center">Detalle del Gasto</h4>
            </b-col>
            <b-col md="12" v-show="sifExpedienteReportDetalleIngresoR4">
            <h4 class="card-title text-center">Detalle del Ingreso</h4>
            </b-col>
            </b-row>
            <br>
            <b-row>
            <b-col md="12" v-show="sifExpedienteReportDetalleGastoR4" class="table-responsive">
              <b-table bordered hover striped  :items="ItemsGasto" :fields="fieldsGasto" :sort-compare="compareCurrency" class="table_ejecucion_presupuestal" foot-clone>
                <template v-slot:cell(ciclo)="data_ciclo">
                <p class='text-left mb-0'><span class="font-size-11">{{ data_ciclo.value }}</span></p>
                </template>
                <template v-slot:cell(fase)="data_fase">
                <p class='text-left mb-0'><span class="font-size-11">{{ data_fase.value }}</span></p>
                </template>
              <template v-slot:cell(secuencia)="data_secuencia">
                <p class='text-left mb-0'><span class="font-size-11">{{ data_secuencia.value }}</span></p>
                </template>
                <template v-slot:cell(sec_func)="data_sec_func">
                <p class='text-left mb-0'><span class="font-size-11">{{ data_sec_func.value }}</span></p>
                </template>
                <template v-slot:cell(clasificador)="data_clasificador">
                <p class='text-left mb-0'><span class="font-size-11">{{ data_clasificador.value }}</span></p>
                </template>
                <template v-slot:cell(descripcion)="data_descripcion">
                <p class='text-left mb-0'><span class="font-size-11">{{ data_descripcion.value }}</span></p>
                </template>
                <template v-slot:cell(monto)="data_monto">
                <p class='text-right mb-0'><span class="font-size-11">{{ data_monto.value }}</span></p>
                </template>

                <template class='text-right' v-slot:foot(ciclo)="foot_data_col1">
                <p class='text-right'><span></span></p>
                </template>
              <template class='text-right' v-slot:foot(fase)="foot_data_col1">
                <p class='text-right'><span></span></p>
                </template>
              <template class='text-right' v-slot:foot(secuencia)="foot_data_col1">
                <p class='text-right'><span></span></p>
                </template>
                <template class='text-right' v-slot:foot(sec_func)="foot_data_col1">
                <p class='text-right'><span></span></p>
                </template>
                <template class='text-right' v-slot:foot(clasificador)="foot_data_col1">
                <p class='text-right'><span></span></p>
                </template>
                <template class='text-right' v-slot:foot(descripcion)="foot_data_col1">
                <p class='text-right'><span></span></p>
                </template>
                <template class='text-right' v-slot:foot(monto)="foot_data_col1">
                <p class='text-right'><span></span></p>
                </template>
              </b-table>
            </b-col>
            <b-col md="12" v-show="sifExpedienteReportDetalleIngresoR4" class="table-responsive">
              <b-table bordered hover striped :items="ItemsIngreso" :fields="fieldsIngreso" :sort-compare="compareCurrency" class="table_ejecucion_presupuestal" foot-clone>
                <template v-slot:cell(ciclo)="data_ciclo">
                <p class='text-left mb-0'><span class="font-size-11">{{ data_ciclo.value }}</span></p>
                </template>
                <template v-slot:cell(fase)="data_fase">
                <p class='text-left mb-0'><span class="font-size-11">{{ data_fase.value }}</span></p>
                </template>
                <template v-slot:cell(secuencia)="data_secuencia">
                <p class='text-left mb-0'><span class="font-size-11">{{ data_secuencia.value }}</span></p>
                </template>
                <template v-slot:cell(clasificador)="data_clasificador">
                <p class='text-left mb-0'><span class="font-size-11">{{ data_clasificador.value }}</span></p>
                </template>
                <template v-slot:cell(descripcion)="data_descripcion">
                <p class='text-left mb-0'><span class="font-size-11">{{ data_descripcion.value }}</span></p>
                </template>
                <template v-slot:cell(monto)="data_monto">
                <p class='text-right mb-0'><span class="font-size-11">{{ data_monto.value }}</span></p>
                </template>

                <template class='text-right' v-slot:foot(ciclo)="foot_data_col1">
                <p class='text-right'><span></span></p>
                </template>
              <template class='text-right' v-slot:foot(fase)="foot_data_col1">
                <p class='text-right'><span></span></p>
                </template>
                <template class='text-right' v-slot:foot(secuencia)="foot_data_col1">
                <p class='text-right'><span></span></p>
                </template>
                <template class='text-right' v-slot:foot(clasificador)="foot_data_col1">
                <p class='text-right'><span></span></p>
                </template>
                <template class='text-right' v-slot:foot(descripcion)="foot_data_col1">
                <p class='text-right'><span></span></p>
                </template>
                <template class='text-right' v-slot:foot(monto)="foot_data_col1">
                <p class='text-right'><span></span></p>
                </template>
              </b-table>
            </b-col>
            </b-row>
          </template>
          </iq-card>
        </b-col>
        </b-row>
      </b-col>
    </b-row>
    <b-row v-show="report2">
      <b-col md="12">
        <b-row>
          <b-col md="12">
          <iq-card class-name="overflow-hidden">
          <template v-slot:body>
            <b-row>
            <b-col md="12">
            <h4 class="card-title text-left">Filtro de Reportes</h4>
            </b-col>
            </b-row>
            <br>
            <b-row>
            <b-col md="6">
              <b-form-group label="Ejecutora" label-for="rpt_from1">
              <b-form-select v-model="selectEjecutora2" type="select" v-on:change="chgSelectR2" class="custom-select"  required>
                  <option  v-for="eje in optionEjecutora"  :key="eje.secEjec" :value="eje.secEjec"  >{{eje.nombre}}</option>
                </b-form-select>
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group label="Año" label-for="rpt_from1">
                <b-form-select v-model="selectAnio2" type="select" v-on:change="chgSelectR2" class="custom-select"  required>
                  <option value="">SELECCIONE::</option>
                  <option  v-for="a in optionAnio2"  :key="a.anoEje" :value="a.anoEje"  >{{a.anoEje}}</option>
                </b-form-select>
              </b-form-group>
            </b-col>
              <b-col md="3">
              <b-form-group label="Acciones" label-for="acciones2">
                <b-button variant = "success" @click="generar2"><i class="ri-play-fill"></i>Generar</b-button>
                <b-button  style="margin-left:1%" variant = "danger" @click="limpiar2"><i class="ri-eraser-fill"></i>Limpiar</b-button>
              </b-form-group>
            </b-col>
            <!--<b-col md="3" style="text-align:center">
              <b-form-group >
                <b-button variant = "success" @click="generar2"><i class="ri-play-fill"></i>Generar</b-button>
                <b-button  style="margin-left:1%" variant = "danger" @click="limpiar2"><i class="ri-eraser-fill"></i>Limpiar</b-button>
              </b-form-group>
              </b-col>-->
            </b-row>
            <div id="divChtREG"></div>
          </template>
          </iq-card>
        </b-col>
        </b-row>
        <b-row v-show="reportGraficoTablaR2">
        <b-col md="12">
          <iq-card class-name="overflow-hidden">
          <template v-slot:body>
            <b-row>
              <b-col md="4">
                  <h4 class="card-title text-left">Avance de Ejecución Presupuestal (S/)</h4>
              </b-col>
              <b-col md="4">
                <h4 class="card-title text-left">Avance de Ejecución Presupuestal por Proyectos</h4>
              </b-col>
              <b-col md="4">
                <h4 class="card-title text-left">Avance de Ejecución Presupuestal por Productos</h4>
              </b-col>
            </b-row>
            <br>
            <b-row>
              <b-col md="4">
                  <div v-if="isLoadingChtClasR2G1 === true" class="text-center">
                    <img :src="imgLoadingChtClasR2G1" width="400"/>
                  </div>
                  <div id="divChtClasR2G1">
                  </div>
              </b-col>
              <b-col md="4">
                  <div v-if="isLoadingChtClasR2G2 === true" class="text-center">
                    <img :src="imgLoadingChtClasR2G2" width="400"/>
                  </div>
                  <div id="divChtClasR2G2"></div>
              </b-col>
              <b-col md="4">
                  <div v-if="isLoadingChtClasR2G3 === true" class="text-center">
                    <img :src="imgLoadingChtClasR2G3" width="400"/>
                  </div>
                  <div id="divChtClasR2G3"></div>
              </b-col>
            </b-row>
            <br>
            <b-row>
              <b-col md="12">
                <h4 class="card-title text-left">Avance de Ejecución Presupuestal - Mensualizada</h4>
              </b-col>
            </b-row>
            <br>
            <b-row>
              <b-col md="12">
                  <div v-if="isLoadingChtClasR2G4 === true" class="text-center">
                    <img :src="imgLoadingChtClasR2G4" width="400"/>
                  </div>
                  <div id="divChtClasR2G4"></div>
              </b-col>
            </b-row>
            <br>
            <b-row>
              <b-col md="12">
                <h4 class="card-title text-left">Avance de Ejecución Presupuestal por Proyectos - Mensualizada</h4>
              </b-col>
            </b-row>
            <br>
            <b-row>
              <b-col md="12">
                  <div v-if="isLoadingChtClasR2G5 === true" class="text-center">
                    <img :src="imgLoadingChtClasR2G5" width="400"/>
                  </div>
                  <div id="divChtClasR2G5"></div>
              </b-col>
            </b-row>
            <br>
            <b-row>
              <b-col md="12">
                <h4 class="card-title text-left">Avance de Ejecución Presupuestal por Productos - Mensualizada</h4>
              </b-col>
            </b-row>
            <br>
            <b-row>
              <b-col md="12">
                  <div v-if="isLoadingChtClasR2G6 === true" class="text-center">
                    <img :src="imgLoadingChtClasR2G6" width="400"/>
                  </div>
                  <div id="divChtClasR2G6"></div>
              </b-col>
            </b-row>
            <br>
            <b-row>
              <b-col md="12">
                <h4 class="card-title text-left">Ejecución Presupuestal por Proyecto/Producto</h4>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="12" class="table-responsive">
                <b-table bordered hover striped :items="ItemsResumenR2T1" :fields="fieldsResumenR2T1" :sort-compare="compareCurrency" foot-clone class="tablaResumen2 table_ejecucion_presupuestal" >
                  <template v-slot:cell(pia)="data_pia">
                  <p class='text-right mb-0'><span class="font-size-11">{{ data_pia.value }}</span></p>
                  </template>
                  <template v-slot:cell(pim)="data_pim">
                  <p class='text-right mb-0'><span class="font-size-11">{{ data_pim.value }}</span></p>
                  </template>
                  <template v-slot:cell(certificado)="data_certificado">
                  <p class='text-right mb-0'><span class="font-size-11">{{ data_certificado.value }}</span></p>
                  </template>
                  <template v-slot:cell(compromisoAnual)="data_ca">
                  <p class='text-right mb-0'><span class="font-size-11">{{ data_ca.value }}</span></p>
                  </template>
                  <template v-slot:cell(devengado)="data_devengado">
                  <p class='text-right mb-0'><span class="font-size-11">{{ data_devengado.value }}</span></p>
                  </template>
                  <template v-slot:cell(avance)="data_avance">
                  <p class='text-right mb-0'><span class="font-size-11">{{ data_avance.value }}</span></p>
                  </template>
                  <template class='text-right' v-slot:foot(actProy)="foot_data_col1">
                  <p class='text-left'><span>Total</span></p>
                  </template>
                  <template class='text-right' v-slot:foot(pia)="foot_data_col2">
                  <p class='text-right'><span class="footer_table">S/.{{ totalPIAtabla1R2.toLocaleString('en-US') }}</span></p><!-- S/.{{ totalPIM.toLocaleString('en-US') }}-->
                  </template>
                  <template class='text-right' v-slot:foot(pim)="foot_data_col1">
                  <p class='text-right'><span class="footer_table">S/.{{ totalPIMtabla1R2.toLocaleString('en-US') }}</span></p>
                  </template>
                  <template class='text-right' v-slot:foot(certificado)="foot_data_col1">
                  <p class='text-right'><span class="footer_table">S/.{{ totalCERTtabla1R2.toLocaleString('en-US') }}</span></p>
                  </template>
                  <template class='text-right' v-slot:foot(compromisoAnual)="foot_data_col1">
                  <p class='text-right'><span class="footer_table">S/.{{ totalCOMANUtabla1R2.toLocaleString('en-US') }}</span></p>
                  </template>
                  <template class='text-right' v-slot:foot(saldoComprometer)="foot_data_col1">
                  <p class='text-right'><span class="footer_table">S/.{{ totalSALCOMPtabla1R2.toLocaleString('en-US') }}</span></p>
                  </template>
                  <template class='text-right' v-slot:foot(devengado)="foot_data_col1">
                  <p class='text-right'><span class="footer_table">S/.{{ totalDEVtabla1R2.toLocaleString('en-US') }}</span></p>
                  </template>
                  <template class='text-right' v-slot:foot(avance)="foot_data_col1">
                  <p class='text-right'><span class="footer_table">{{ totalAVANtabla1R2.toLocaleString('en-US') }}</span></p>
                  </template>
                </b-table>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="12">
                <h4 class="card-title text-left">Ejecución Presupuestal por Genérica de Gasto</h4>
              </b-col>
            </b-row>
            <b-row>
            <b-col md="12" class="table-responsive">
                <b-table bordered hover striped :items="ItemsResumenR2T2" :fields="fieldsResumenR2T2" :sort-compare="compareCurrency" foot-clone class="tablaResumen2 table_ejecucion_presupuestal" >
                    <template v-slot:cell(descripcion)="data_descripcion">
                  <p class='text-left mb-0'><span class="font-size-11">{{ data_descripcion.value }}</span></p>
                  </template>
                    <template v-slot:cell(pia)="data_pia">
                  <p class='text-right mb-0'><span class="font-size-11">{{ data_pia.value }}</span></p>
                  </template>
                  <template v-slot:cell(pim)="data_pim">
                  <p class='text-right mb-0'><span class="font-size-11">{{ data_pim.value }}</span></p>
                  </template>
                  <template v-slot:cell(certificado)="data_certificado">
                  <p class='text-right mb-0'><span class="font-size-11">{{ data_certificado.value }}</span></p>
                  </template>
                  <template v-slot:cell(compromisoAnual)="data_ca">
                  <p class='text-right mb-0'><span class="font-size-11">{{ data_ca.value }}</span></p>
                  </template>
                  <template v-slot:cell(devengado)="data_devengado">
                  <p class='text-right mb-0'><span class="font-size-11">{{ data_devengado.value }}</span></p>
                  </template>
                  <template v-slot:cell(avance)="data_avance">
                  <p class='text-right mb-0'><span class="font-size-11">{{ data_avance.value }}</span></p>
                  </template>
                  <template class='text-right' v-slot:foot(descripcion)="foot_data_col1">
                  <p class='text-left'><span>Total</span></p>
                  </template>
                  <template class='text-right' v-slot:foot(pia)="foot_data_col2">
                  <p class='text-right'><span class="footer_table">S/.{{ totalPIAtabla2R2.toLocaleString('en-US') }}</span></p><!-- S/.{{ totalPIM.toLocaleString('en-US') }}-->
                  </template>
                  <template class='text-right' v-slot:foot(pim)="foot_data_col1">
                  <p class='text-right'><span class="footer_table">S/.{{ totalPIMtabla2R2.toLocaleString('en-US') }}</span></p>
                  </template>
                  <template class='text-right' v-slot:foot(certificado)="foot_data_col1">
                  <p class='text-right'><span class="footer_table">S/.{{ totalCERTtabla2R2.toLocaleString('en-US') }}</span></p>
                  </template>
                  <template class='text-right' v-slot:foot(compromisoAnual)="foot_data_col1">
                  <p class='text-right'><span class="footer_table">S/.{{ totalCOMANUtabla2R2.toLocaleString('en-US') }}</span></p>
                  </template>
                  <template class='text-right' v-slot:foot(saldoComprometer)="foot_data_col1">
                  <p class='text-right'><span class="footer_table">S/.{{ totalSALCOMPtabla2R2.toLocaleString('en-US') }}</span></p>
                  </template>
                  <template class='text-right' v-slot:foot(devengado)="foot_data_col1">
                  <p class='text-right'><span class="footer_table">S/.{{ totalDEVtabla2R2.toLocaleString('en-US') }}</span></p>
                  </template>
                  <template class='text-right' v-slot:foot(avance)="foot_data_col1">
                  <p class='text-right'><span class="footer_table">{{ totalAVANtabla2R2.toLocaleString('en-US') }}</span></p>
                  </template>
                </b-table>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="12">
                <h4 class="card-title text-left">Ejecución Presupuestal por Fuente Financiamiento</h4>
              </b-col>
            </b-row>
            <b-row>
            <b-col md="12" class="table-responsive">
                <b-table bordered hover striped :items="ItemsResumenR2T3" :fields="fieldsResumenR2T3" :sort-compare="compareCurrency" foot-clone class="tablaResumen2 table_ejecucion_presupuestal" >
                    <template v-slot:cell(nombre)="data_nombre">
                  <p class='text-left mb-0'><span class="font-size-11">{{ data_nombre.value }}</span></p>
                  </template>
                    <template v-slot:cell(pia)="data_pia">
                  <p class='text-right mb-0'><span class="font-size-11">{{ data_pia.value }}</span></p>
                  </template>
                  <template v-slot:cell(pim)="data_pim">
                  <p class='text-right mb-0'><span class="font-size-11">{{ data_pim.value }}</span></p>
                  </template>
                  <template v-slot:cell(certificado)="data_certificado">
                  <p class='text-right mb-0'><span class="font-size-11">{{ data_certificado.value }}</span></p>
                  </template>
                  <template v-slot:cell(compromisoAnual)="data_ca">
                  <p class='text-right mb-0'><span class="font-size-11">{{ data_ca.value }}</span></p>
                  </template>
                  <template v-slot:cell(devengado)="data_devengado">
                  <p class='text-right mb-0'><span class="font-size-11">{{ data_devengado.value }}</span></p>
                  </template>
                  <template v-slot:cell(avance)="data_avance">
                  <p class='text-right mb-0'><span class="font-size-11">{{ data_avance.value }}</span></p>
                  </template>
                  <template class='text-right' v-slot:foot(descripcion)="foot_data_col1">
                  <p class='text-left'><span>Total</span></p>
                  </template>
                  <template class='text-right' v-slot:foot(pia)="foot_data_col2">
                  <p class='text-right'><span class="footer_table">S/.{{ totalPIAtabla3R2.toLocaleString('en-US') }}</span></p><!-- S/.{{ totalPIM.toLocaleString('en-US') }}-->
                  </template>
                  <template class='text-right' v-slot:foot(pim)="foot_data_col1">
                  <p class='text-right'><span class="footer_table">S/.{{ totalPIMtabla3R2.toLocaleString('en-US') }}</span></p>
                  </template>
                  <template class='text-right' v-slot:foot(certificado)="foot_data_col1">
                  <p class='text-right'><span class="footer_table">S/.{{ totalCERTtabla3R2.toLocaleString('en-US') }}</span></p>
                  </template>
                  <template class='text-right' v-slot:foot(compromisoAnual)="foot_data_col1">
                  <p class='text-right'><span>S/.{{ totalCOMANUtabla3R2.toLocaleString('en-US') }}</span></p>
                  </template>
                  <template class='text-right' v-slot:foot(saldoComprometer)="foot_data_col1">
                  <p class='text-right'><span class="footer_table">S/.{{ totalSALCOMPtabla3R2.toLocaleString('en-US') }}</span></p>
                  </template>
                  <template class='text-right' v-slot:foot(devengado)="foot_data_col1">
                  <p class='text-right'><span class="footer_table">S/.{{ totalDEVtabla3R2.toLocaleString('en-US') }}</span></p>
                  </template>
                  <template class='text-right' v-slot:foot(avance)="foot_data_col1">
                  <p class='text-right'><span class="footer_table">{{ totalAVANtabla3R2.toLocaleString('en-US') }}</span></p>
                  </template>
                </b-table>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="12">
                <h4 class="card-title text-left">Ejecución Presupuestal por Categoría de Gasto</h4>
              </b-col>
            </b-row>
            <b-row>
            <b-col md="12" class="table-responsive">
                <b-table bordered hover striped :items="ItemsResumenR2T4" :fields="fieldsResumenR2T4" :sort-compare="compareCurrency" foot-clone class="tablaResumen2 table_ejecucion_presupuestal" >
                    <template v-slot:cell(nombre)="data_nombre">
                  <p class='text-left mb-0'><span class="font-size-11">{{ data_nombre.value }}</span></p>
                  </template>
                    <template v-slot:cell(pia)="data_pia">
                  <p class='text-right mb-0'><span class="font-size-11">{{ data_pia.value }}</span></p>
                  </template>
                  <template v-slot:cell(pim)="data_pim">
                  <p class='text-right mb-0'><span class="font-size-11">{{ data_pim.value }}</span></p>
                  </template>
                  <template v-slot:cell(certificado)="data_certificado">
                  <p class='text-right mb-0'><span class="font-size-11">{{ data_certificado.value }}</span></p>
                  </template>
                  <template v-slot:cell(compromisoAnual)="data_ca">
                  <p class='text-right mb-0'><span class="font-size-11">{{ data_ca.value }}</span></p>
                  </template>
                  <template v-slot:cell(devengado)="data_devengado">
                  <p class='text-right mb-0'><span class="font-size-11">{{ data_devengado.value }}</span></p>
                  </template>
                  <template v-slot:cell(avance)="data_avance">
                  <p class='text-right mb-0'><span class="font-size-11">{{ data_avance.value }}</span></p>
                  </template>
                  <template class='text-right' v-slot:foot(descripcion)="foot_data_col1">
                  <p class='text-left'><span>Total</span></p>
                  </template>
                  <template class='text-right' v-slot:foot(pia)="foot_data_col2">
                  <p class='text-right'><span class="footer_table">S/.{{ totalPIAtabla4R2.toLocaleString('en-US') }}</span></p><!-- S/.{{ totalPIM.toLocaleString('en-US') }}-->
                  </template>
                  <template class='text-right' v-slot:foot(pim)="foot_data_col1">
                  <p class='text-right'><span class="footer_table">S/.{{ totalPIMtabla4R2.toLocaleString('en-US') }}</span></p>
                  </template>
                  <template class='text-right' v-slot:foot(certificado)="foot_data_col1">
                  <p class='text-right'><span class="footer_table">S/.{{ totalCERTtabla4R2.toLocaleString('en-US') }}</span></p>
                  </template>
                  <template class='text-right' v-slot:foot(compromisoAnual)="foot_data_col1">
                  <p class='text-right'><span class="footer_table">S/.{{ totalCOMANUtabla4R2.toLocaleString('en-US') }}</span></p>
                  </template>
                  <template class='text-right' v-slot:foot(saldoComprometer)="foot_data_col1">
                  <p class='text-right'><span class="footer_table">S/.{{ totalSALCOMPtabla4R2.toLocaleString('en-US') }}</span></p>
                  </template>
                  <template class='text-right' v-slot:foot(devengado)="foot_data_col1">
                  <p class='text-right'><span class="footer_table">S/.{{ totalDEVtabla4R2.toLocaleString('en-US') }}</span></p>
                  </template>
                  <template class='text-right' v-slot:foot(avance)="foot_data_col1">
                  <p class='text-right'><span class="footer_table">{{ totalAVANtabla4R2.toLocaleString('en-US') }}</span></p>
                  </template>
                </b-table>
              </b-col>
            </b-row>
          </template>
          </iq-card>
        </b-col>
        </b-row>
      </b-col>
    </b-row>
    <b-row v-show="reportGraficoR3">
      <b-col md="12">
        <b-row>
          <b-col md="12">
          <iq-card class-name="overflow-hidden">
          <template v-slot:body>
            <b-row>
              <b-col md="12">
                  <h4 class="card-title text-left"></h4>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="12">

              </b-col>
            </b-row>
            <br>
          </template>
          </iq-card>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { mapGetters } from 'vuex'
import ApexCharts from 'apexcharts'
import { vito } from '../../../config/pluginInit'
import loading1 from '../../../assets/images/loading.gif'
export default {
  name: 'ejecucion-presupuestal',
  components: {},
  mounted () {
    vito.index()
    this.loadEjecutora()
    this.loadAnios()
    this.loadAniosr2()
  },
  data () {
    return {
      chtClasR2G1: null,
      chtClasR2G2: null,
      chtClasR2G3: null,
      chtClasR2G4: null,
      chtClasR2G5: null,
      chtClasR2G6: null,
      isLoadingChtClasR2G1: false,
      imgLoadingChtClasR2G1: loading1,
      isLoadingChtClasR2G2: false,
      imgLoadingChtClasR2G2: loading1,
      isLoadingChtClasR2G3: false,
      imgLoadingChtClasR2G3: loading1,
      isLoadingChtClasR2G4: false,
      imgLoadingChtClasR2G4: loading1,
      isLoadingChtClasR2G5: false,
      imgLoadingChtClasR2G5: loading1,
      isLoadingChtClasR2G6: false,
      imgLoadingChtClasR2G6: loading1,
      imgLoading: loading1,
      isLoading: false,
      leyendaS: 'Avance Financiero >= 16 % ',
      leyendaW: '10 % <= Avance Financiero < 16 % ',
      leyendaD: 'Avance Financiero < 10 %',
      sizeModal: 'sm',
      stdUEInfo: '',
      rucNombre: '1',
      stateRuc: true,
      stateNombre: false,
      reportGraficoTablaR2: false,
      sifExpedienteReporte4: false,
      reportGraficoR3: false,
      detNumeroExpedienteSiaf4: '',
      ItemsResumenR4: [],
      ItemsResumenR2T1: [],
      ItemsResumenR2T2: [],
      ItemsResumenR2T3: [],
      ItemsResumenR2T4: [],
      ItemsGasto: [],
      sifExpedienteReporteResumenR4: false,
      detRubroFinanciamientoSiaf4: '',
      ItemsIngreso: [],
      report4: true,
      report2: false,
      sifExpedienteReportDetalleGastoR4: false,
      sifExpedienteReportDetalleIngresoR4: false,
      expediente4: '',
      nombreApellido4: '',
      ruc4: '',
      selectEjecutoraR4: '000921',
      selectEjecutora2: '000921',
      optionEjecutora: [],
      optionAnio: [],
      optionAnio2: [],
      selectAnioR4: '',
      selectAnio2: '',
      optionexpediente4: [],
      expedienteTemp4: '',
      sizeColumnTable: 10,

      totalPIAtabla1R2: 0,
      totalPIMtabla1R2: 0,
      totalCERTtabla1R2: 0,
      totalCOMANUtabla1R2: 0,
      totalSALCOMPtabla1R2: 0,
      totalDEVtabla1R2: 0,
      totalAVANtabla1R2: 0,

      totalPIAtabla2R2: 0,
      totalPIMtabla2R2: 0,
      totalCERTtabla2R2: 0,
      totalCOMANUtabla2R2: 0,
      totalSALCOMPtabla2R2: 0,
      totalDEVtabla2R2: 0,
      totalAVANtabla2R2: 0,

      totalPIAtabla3R2: 0,
      totalPIMtabla3R2: 0,
      totalCERTtabla3R2: 0,
      totalCOMANUtabla3R2: 0,
      totalSALCOMPtabla3R2: 0,
      totalDEVtabla3R2: 0,
      totalAVANtabla3R2: 0,

      totalPIAtabla4R2: 0,
      totalPIMtabla4R2: 0,
      totalCERTtabla4R2: 0,
      totalCOMANUtabla4R2: 0,
      totalSALCOMPtabla4R2: 0,
      totalDEVtabla4R2: 0,
      totalAVANtabla4R2: 0,

      chtOptClasR2G1: {
        chart: {
          height: 350,
          type: 'bar'
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: '55%',
            endingShape: 'rounded',
            dataLabels: {
              position: 'top' // top, center, bottom
            }
          }
        },
        dataLabels: {
          enabled: true,
          formatter: function (val) {
            return val// + "%"
          },
          offsetY: -20,
          style: {
            fontSize: '12px',
            colors: ['#304758']
          }
        },
        stroke: {
          show: true,
          width: 10,
          colors: ['transparent']
        },
        colors: ['#0084ff', '#00ca00'],
        series: [],
        xaxis: {
          categories: ['Comparativa Anual']
        },
        yaxis: {
          title: {
            text: 'Ejecución (Millones S/)'
          }
        },
        fill: {
          opacity: 1

        },
        tooltip: {
          y: {
            formatter: function (val) {
              return 'S/ ' + val + ' Millones'
            }
          }
        }
      },
      chtOptClasR2G2: {
        chart: {
          height: 350,
          type: 'bar'
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: '55%',
            endingShape: 'rounded',
            dataLabels: {
              position: 'top' // top, center, bottom
            }
          }
        },
        dataLabels: {
          enabled: true,
          formatter: function (val) {
            return val// + "%"
          },
          offsetY: -20,
          style: {
            fontSize: '12px',
            colors: ['#304758']
          }
        },
        stroke: {
          show: true,
          width: 10,
          colors: ['transparent']
        },
        colors: ['#0084ff', '#00ca00'],
        series: [],
        xaxis: {
          categories: ['Comparativa Anual']
        },
        yaxis: {
          title: {
            text: 'Ejecución (Millones S/)'
          }
        },
        fill: {
          opacity: 1

        },
        tooltip: {
          y: {
            formatter: function (val) {
              return 'S/ ' + val + ' Millones'
            }
          }
        }
      },
      chtOptClasR2G3: {
        chart: {
          height: 350,
          type: 'bar'
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: '55%',
            endingShape: 'rounded',
            dataLabels: {
              position: 'top' // top, center, bottom
            }
          }
        },
        dataLabels: {
          enabled: true,
          formatter: function (val) {
            return val// + "%"
          },
          offsetY: -20,
          style: {
            fontSize: '12px',
            colors: ['#304758']
          }
        },
        stroke: {
          show: true,
          width: 10,
          colors: ['transparent']
        },
        colors: ['#0084ff', '#00ca00'],
        series: [],
        xaxis: {
          categories: ['Comparativa Anual']
        },
        yaxis: {
          title: {
            text: 'Ejecución (Millones S/)'
          }
        },
        fill: {
          opacity: 1

        },
        tooltip: {
          y: {
            formatter: function (val) {
              return 'S/ ' + val + ' Millones'
            }
          }
        }
      },
      chtOptClasR2G4: {
        chart: {
          height: 350,
          type: 'bar'
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: '90%',
            endingShape: 'rounded',
            dataLabels: {
              position: 'top' // top, center, bottom
            }
          }
        },
        dataLabels: {
          enabled: true,
          formatter: function (val) {
            return val
          },
          offsetY: -20,
          style: {
            fontSize: '12px',
            colors: ['#304758']
          }
        },
        stroke: {
          show: true,
          width: 2,
          colors: ['transparent']
        },
        colors: ['#0084ff', '#00ca00'],
        series: [],
        xaxis: {
          categories: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Setiembre', 'Octubre', 'Noviembre', 'Diciembre']
        },
        yaxis: {
          title: {
            text: 'Ejecución (Millones S/)'// Comparativa Anual
          }
        },
        fill: {
          opacity: 1

        },
        tooltip: {
          y: {
            formatter: function (val) {
              return 'S/ ' + val + ' Millones'
            }
          }
        },
        title: {
          text: 'Comparativa Mensual',
          floating: false,
          // offsetY: 330,
          align: 'center',
          style: {
            color: '#1B578C'
          }
        }
      },
      chtOptClasR2G5: {
        chart: {
          height: 350,
          type: 'bar'
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: '90%',
            endingShape: 'rounded',
            dataLabels: {
              position: 'top' // top, center, bottom
            }
          }
        },
        dataLabels: {
          enabled: true,
          formatter: function (val) {
            return val
          },
          offsetY: -20,
          style: {
            fontSize: '12px',
            colors: ['#304758']
          }
        },
        stroke: {
          show: true,
          width: 2,
          colors: ['transparent']
        },
        colors: ['#0084ff', '#00ca00'],
        series: [],
        xaxis: {
          categories: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Setiembre', 'Octubre', 'Noviembre', 'Diciembre']
        },
        yaxis: {
          title: {
            text: 'Ejecución (Millones S/)'// Comparativa Anual
          }
        },
        fill: {
          opacity: 1

        },
        tooltip: {
          y: {
            formatter: function (val) {
              return 'S/ ' + val + ' Millones'
            }
          }
        },
        title: {
          text: 'Comparativa Mensual',
          floating: false,
          // offsetY: 330,
          align: 'center',
          style: {
            color: '#1B578C'
          }
        }
      },
      chtOptClasR2G6: {
        chart: {
          height: 350,
          type: 'bar'
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: '90%',
            endingShape: 'rounded',
            dataLabels: {
              position: 'top' // top, center, bottom
            }
          }
        },
        dataLabels: {
          enabled: true,
          formatter: function (val) {
            return val
          },
          offsetY: -20,
          style: {
            fontSize: '12px',
            colors: ['#304758']
          }
        },
        stroke: {
          show: true,
          width: 2,
          colors: ['transparent']
        },
        colors: ['#0084ff', '#00ca00'],
        series: [],
        xaxis: {
          categories: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Setiembre', 'Octubre', 'Noviembre', 'Diciembre']
        },
        yaxis: {
          title: {
            text: 'Ejecución (Millones S/)'// Comparativa Anual
          }
        },
        fill: {
          opacity: 1

        },
        tooltip: {
          y: {
            formatter: function (val) {
              return 'S/ ' + val + ' Millones'
            }
          }
        },
        title: {
          text: 'Comparativa Mensual',
          floating: false,
          // offsetY: 330,
          align: 'center',
          style: {
            color: '#1B578C'
          }
        }
      },
      optionsRucNombre: [
        {
          text: 'RUC de Proveedor',
          value: '1'
        },
        {
          text: 'Nombre de Proveedor',
          value: '2'
        }
      ],
      selectTipoReporte: '04',
      optionTipoReporte: [
        { value: '11', text: 'Semáforo de Ejecución Presupuestal' },
        { value: '10', text: 'Resumen de Ejecución Presupuestal' },
        { value: '01', text: 'Ejecución de Gasto Histórico' },
        { value: '02', text: 'Ejecución de Gasto Anual' },
        { value: '03', text: 'Certificaciones Presupuestarias' },
        { value: '04', text: 'Expedientes SIAF' },
        { value: '05', text: 'Notas Modificatorias' },
        { value: '06', text: 'Recaudación de Ingresos Histórico' },
        { value: '07', text: 'Recaudación de Ingresos Anual' }
      ],
      fieldsResumenR4: [
        {
          key: 'ciclo',
          label: 'Ciclo',
          sortable: false,
          tdClass: 'pt-0 pb-0 align-middle',
          thClass: 'text-center'
        },
        {
          key: 'fase',
          label: 'Fase',
          sortable: false,
          tdClass: 'pt-0 pb-0 align-middle',
          thClass: 'text-center'
        },
        {
          key: 'certificacion',
          label: 'Certificación',
          sortable: false,
          tdClass: 'pt-0 pb-0 align-middle',
          thClass: 'text-center'
        },
        {
          key: 'nombre_documento',
          label: 'Documento',
          sortable: false,
          tdClass: 'pt-0 pb-0 align-middle',
          thClass: 'text-center'
        },
        {
          key: 'numero',
          label: 'Numero',
          sortable: false,
          tdClass: 'pt-0 pb-0 align-middle',
          thClass: 'text-center'
        },
        {
          key: 'fecha_doc',
          label: 'Fecha',
          sortable: false,
          tdClass: 'pt-0 pb-0 align-middle',
          thClass: 'text-center'
        },
        {
          key: 'monto',
          label: 'Monto',
          sortable: false,
          tdClass: 'pt-0 pb-0 align-middle text-right font-size-11 text-nowrap',
          thClass: 'text-right align-middle'
        },
        {
          key: 'ruc',
          label: 'RUC',
          sortable: false,
          tdClass: 'pt-0 pb-0 align-middle',
          thClass: 'text-center'
        },
        {
          key: 'nombre',
          label: 'Nombre',
          sortable: false,
          tdClass: 'pt-1 pb-1 align-middle text-center',
          thClass: 'text-center'
        },
        {
          key: 'banco',
          label: 'Banco',
          sortable: false,
          tdClass: 'pt-1 pb-1 align-middle text-center',
          thClass: 'text-center'
        },
        {
          key: 'cuenta',
          sortable: false,
          label: 'Cuenta',
          tdClass: 'pt-1 pb-1 align-middle text-center',
          thClass: 'text-center'
        },
        {
          key: 'anioCta',
          label: 'Año',
          sortable: false,
          tdClass: 'pt-0 pb-0 align-middle text-right font-size-11 text-nowrap',
          thClass: 'text-right align-middle'
        },
        {
          key: 'estado_envio',
          label: 'Estado',
          sortable: false,
          tdClass: 'pt-1 pb-1 align-middle text-center',
          thClass: 'text-center'
        }
      ],
      fieldsGasto: [
        {
          key: 'ciclo',
          label: 'Ciclo',
          sortable: false,
          tdClass: 'pt-0 pb-0 align-middle',
          thClass: 'text-center'
        },
        {
          key: 'fase',
          label: 'Fase',
          sortable: false,
          tdClass: 'pt-0 pb-0 align-middle',
          thClass: 'text-center'
        },
        {
          key: 'secuencia',
          label: 'Secuencia',
          sortable: false,
          tdClass: 'pt-0 pb-0 align-middle',
          thClass: 'text-center'
        },
        {
          key: 'sec_func',
          label: 'Meta',
          sortable: false,
          tdClass: 'pt-0 pb-0 align-middle',
          thClass: 'text-center'
        },
        {
          key: 'clasificador',
          label: 'Específica',
          sortable: false,
          tdClass: 'pt-0 pb-0 align-middle',
          thClass: 'text-center'
        },
        {
          key: 'descripcion',
          label: 'Descripción',
          sortable: false,
          tdClass: 'pt-0 pb-0 align-middle',
          thClass: 'text-center'
        },
        {
          key: 'monto',
          label: 'Monto',
          sortable: false,
          tdClass: 'pt-0 pb-0 align-middle',
          thClass: 'text-center'
        }
      ],
      fieldsIngreso: [
        {
          key: 'ciclo',
          label: 'Ciclo',
          sortable: false,
          tdClass: 'pt-0 pb-0 align-middle',
          thClass: 'text-center'
        },
        {
          key: 'fase',
          label: 'Case',
          sortable: false,
          tdClass: 'pt-0 pb-0 align-middle',
          thClass: 'text-center'
        },
        {
          key: 'secuencia',
          label: 'Secuencia',
          sortable: false,
          tdClass: 'pt-0 pb-0 align-middle',
          thClass: 'text-center'
        },
        {
          key: 'clasificador',
          label: 'Espesifica',
          sortable: false,
          tdClass: 'pt-0 pb-0 align-middle',
          thClass: 'text-center'
        },
        {
          key: 'descripcion',
          label: 'Descripción',
          sortable: false,
          tdClass: 'pt-0 pb-0 align-middle',
          thClass: 'text-center'
        },
        {
          key: 'monto',
          label: 'Monto',
          sortable: false,
          tdClass: 'pt-0 pb-0 align-middle',
          thClass: 'text-center'
        }
      ],
      fieldsResumenR2T1: [
        {
          key: 'actProy',
          label: 'Producto/Proyecto',
          sortable: false,
          tdClass: 'pt-0 pb-0 align-middle',
          thClass: 'text-center'
        },
        {
          key: 'pia',
          label: 'PIA',
          sortable: false,
          tdClass: 'pt-0 pb-0 align-middle',
          thClass: 'text-right'
        },
        {
          key: 'pim',
          label: 'PIM',
          sortable: false,
          tdClass: 'pt-0 pb-0 align-middle',
          thClass: 'text-right'
        },
        {
          key: 'certificado',
          label: 'Certificado',
          sortable: false,
          tdClass: 'pt-0 pb-0 align-middle',
          thClass: 'text-right'
        },
        {
          key: 'compromisoAnual',
          label: 'Compromiso Anual',
          sortable: false,
          tdClass: 'pt-0 pb-0 align-middle',
          thClass: 'text-right'
        },
        {
          key: 'saldoComprometer',
          label: 'Saldo por Comprometer',
          sortable: false,
          tdClass: 'pt-0 pb-0 align-middle text-right font-size-11 text-nowrap',
          thClass: 'text-right align-middle'
        },
        {
          key: 'devengado',
          label: 'Devengado',
          sortable: false,
          tdClass: 'pt-0 pb-0 align-middle',
          thClass: 'text-right'
        },
        {
          key: 'avance',
          label: 'Avance %',
          sortable: false,
          tdClass: 'pt-1 pb-1 align-middle text-center',
          thClass: 'text-right'
        }
      ],
      fieldsResumenR2T2: [
        {
          key: 'descripcion',
          label: 'Genérica de Gastos',
          sortable: false,
          tdClass: 'pt-0 pb-0 align-middle',
          thClass: 'text-center'
        },
        {
          key: 'pia',
          label: 'PIA',
          sortable: false,
          tdClass: 'pt-0 pb-0 align-middle',
          thClass: 'text-right'
        },
        {
          key: 'pim',
          label: 'PIM',
          sortable: false,
          tdClass: 'pt-0 pb-0 align-middle',
          thClass: 'text-right'
        },
        {
          key: 'certificado',
          label: 'Certificado',
          sortable: false,
          tdClass: 'pt-0 pb-0 align-middle',
          thClass: 'text-right'
        },
        {
          key: 'compromisoAnual',
          label: 'Compromiso Anual',
          sortable: false,
          tdClass: 'pt-0 pb-0 align-middle',
          thClass: 'text-right'
        },
        {
          key: 'saldoComprometer',
          label: 'Saldo por Comprometer',
          sortable: false,
          tdClass: 'pt-0 pb-0 align-middle text-right font-size-11 text-nowrap',
          thClass: 'text-right align-middle'
        },
        {
          key: 'devengado',
          label: 'Devengado',
          sortable: false,
          tdClass: 'pt-0 pb-0 align-middle',
          thClass: 'text-right'
        },
        {
          key: 'avance',
          label: 'Avance %',
          sortable: false,
          tdClass: 'pt-1 pb-1 align-middle text-center',
          thClass: 'text-right'
        }
      ],
      fieldsResumenR2T3: [
        {
          key: 'nombre',
          label: 'Fuente de Financimiento',
          sortable: false,
          tdClass: 'pt-0 pb-0 align-middle',
          thClass: 'text-center'
        },
        {
          key: 'pia',
          label: 'PIA',
          sortable: false,
          tdClass: 'pt-0 pb-0 align-middle',
          thClass: 'text-right'
        },
        {
          key: 'pim',
          label: 'PIM',
          sortable: false,
          tdClass: 'pt-0 pb-0 align-middle',
          thClass: 'text-right'
        },
        {
          key: 'certificado',
          label: 'Certificado',
          sortable: false,
          tdClass: 'pt-0 pb-0 align-middle',
          thClass: 'text-right'
        },
        {
          key: 'compromisoAnual',
          label: 'Compromiso Anual',
          sortable: false,
          tdClass: 'pt-0 pb-0 align-middle',
          thClass: 'text-right'
        },
        {
          key: 'saldoComprometer',
          label: 'Saldo por Comprometer',
          sortable: false,
          tdClass: 'pt-0 pb-0 align-middle text-right font-size-11 text-nowrap',
          thClass: 'text-right align-middle'
        },
        {
          key: 'devengado',
          label: 'Devengado',
          sortable: false,
          tdClass: 'pt-0 pb-0 align-middle',
          thClass: 'text-right'
        },
        {
          key: 'avance',
          label: 'Avance %',
          sortable: false,
          tdClass: 'pt-1 pb-1 align-middle text-center',
          thClass: 'text-right'
        }
      ],
      fieldsResumenR2T4: [
        {
          key: 'nombre',
          label: 'Categoría de Gasto',
          sortable: false,
          tdClass: 'pt-0 pb-0 align-middle',
          thClass: 'text-center'
        },
        {
          key: 'pia',
          label: 'PIA',
          sortable: false,
          tdClass: 'pt-0 pb-0 align-middle',
          thClass: 'text-right'
        },
        {
          key: 'pim',
          label: 'PIM',
          sortable: false,
          tdClass: 'pt-0 pb-0 align-middle',
          thClass: 'text-right'
        },
        {
          key: 'certificado',
          label: 'Certificado',
          sortable: false,
          tdClass: 'pt-0 pb-0 align-middle',
          thClass: 'text-right'
        },
        {
          key: 'compromisoAnual',
          label: 'Compromiso Anual',
          sortable: false,
          tdClass: 'pt-0 pb-0 align-middle',
          thClass: 'text-right'
        },
        {
          key: 'saldoComprometer',
          label: 'Saldo por Comprometer',
          sortable: false,
          tdClass: 'pt-0 pb-0 align-middle text-right font-size-11 text-nowrap',
          thClass: 'text-right align-middle'
        },
        {
          key: 'devengado',
          label: 'Devengado',
          sortable: false,
          tdClass: 'pt-0 pb-0 align-middle',
          thClass: 'text-right'
        },
        {
          key: 'avance',
          label: 'Avance %',
          sortable: false,
          tdClass: 'pt-1 pb-1 align-middle text-center',
          thClass: 'text-right'
        }
      ]
    }
  },
  methods: {
    getSelectedReport (option) {
      this.report2 = false
      this.report4 = false
      switch (option) {
        case '10':
          this.report2 = true
          break
        case '04':
          this.report4 = true
          break
        default:
          this.report4 = true
      }
    },
    chgSelectR2 () {
      if (this.chtClasR2G1 !== null) {
        this.chtClasR2G1.destroy()
      }
      if (this.chtClasR2G2 !== null) {
        this.chtClasR2G2.destroy()
      }
      if (this.chtClasR2G3 !== null) {
        this.chtClasR2G3.destroy()
      }
      if (this.chtClasR2G4 !== null) {
        this.chtClasR2G4.destroy()
      }
      if (this.chtClasR2G5 !== null) {
        this.chtClasR2G5.destroy()
      }
      if (this.chtClasR2G6 !== null) {
        this.chtClasR2G6.destroy()
      }
      this.cleanGrafico()
    },
    compareCurrency (itemA, itemB, key) {
      if (key !== 'monto') {
        return false
      } else {
        let a = itemA[key]
        let b = itemB[key]
        a = a.replace('S/. ', '')
        a = a.replace(/,/g, '')
        b = b.replace('S/. ', '')
        b = b.replace(/,/g, '')
        return a - b
      }
    },
    checkRucNombre (event) {
      if (event === 1) {
        this.stateRuc = true
        this.stateNombre = false
      } else {
        this.stateRuc = false
        this.stateNombre = true
      }
    },
    loadEjecutora () {
      this.$store
        .dispatch('sirsam/FIND_ALL_EJECUTORA', {})
        .then(res => {
          this.optionEjecutora = res === undefined ? [] : res
        })
        .catch(error => {
          if (error.response.status === 401 || error.response.status === 500) {

          }
        })
    },
    loadAnios () {
      this.$store
        .dispatch('sirsam/FIND_ALL_ANIO', {})
        .then(res => {
          this.optionAnio = res === undefined ? [] : res
          let countAnio = 0
          let anioSelect = ''
          this.optionAnio.forEach(function (r) {
            countAnio = countAnio + 1
            if (countAnio === 1) {
              anioSelect = r.anoEje
            }
          })
          this.selectAnioR4 = anioSelect
        })
        .catch(error => {
          if (error.response.status === 401 || error.response.status === 500) {

          }
        })
    },
    loadExpedientes () {
      this.$swal({
        width: 370,
        text: 'Cargando....',
        showCancelButton: false,
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false,
        onOpen: (swal) => {
          this.$swal.showLoading()
          this.$store
            .dispatch('sirsam/FIND_ALL_EXPEDIENTE_SIAF', { anio: this.selectAnioR4, ejecutora: this.selectEjecutoraR4, expediente: this.expediente4, nombreApellido: this.nombreApellido4, ruc: this.ruc4 })
            .then(res => {
              this.optionexpediente4 = res === undefined ? [] : res
              this.sifExpedienteReporte4 = true
              this.$swal.close()
            })
            .catch(error => {
              this.$swal.close()
              if (error.response.status === 401 || error.response.status === 500) {
                this.$swal(
                  error.response.data.errorMessage,
                  'Ud. no tiene permitido esta función',
                  'warning'
                )
              }
            })
        }

      }).then((result) => {
        console.log(result)
      })
    },
    loadExpedienteSiafDetalleFase () {
      this.$swal({
        width: 370,
        text: 'Cargando....',
        showCancelButton: false,
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false,
        onOpen: (swal) => {
          this.$swal.showLoading()
          this.sifExpedienteReportDetalleGastoR4 = false
          this.sifExpedienteReportDetalleIngresoR4 = false
          this.$store
            .dispatch('sirsam/FIND_ALL_EXPEDIENTE_SIAF_DETALLE_FASE', { anio: this.selectAnioR4, ejecutora: this.selectEjecutoraR4, expediente: this.expedienteTemp4 })
            .then(res => {
              this.ItemsResumenR4 = res.listEXF === undefined ? [] : res.listEXF
              this.ItemsIngreso = res.listEXFI === undefined ? [] : res.listEXFI
              this.ItemsGasto = res.listEXFG === undefined ? [] : res.listEXFG
              this.sifExpedienteReporteResumenR4 = true
              if (this.ItemsGasto && this.ItemsGasto.length > 0) {
                this.sifExpedienteReportDetalleGastoR4 = true
              }
              if (this.ItemsIngreso && this.ItemsIngreso.length > 0) {
                this.sifExpedienteReportDetalleIngresoR4 = true
              }
              for (let i = 0; i < this.ItemsResumenR4.length; i++) {
                this.ItemsResumenR4[i].monto = 'S/. ' + this.ItemsResumenR4[i].monto.toLocaleString('en-US')
              }
              for (let i = 0; i < this.ItemsIngreso.length; i++) {
                this.ItemsIngreso[i].monto = 'S/. ' + this.ItemsIngreso[i].monto.toLocaleString('en-US')
              }
              for (let i = 0; i < this.ItemsGasto.length; i++) {
                this.ItemsGasto[i].monto = 'S/. ' + this.ItemsGasto[i].monto.toLocaleString('en-US')
              }
              this.$swal.close()
            })
            .catch(error => {
              this.$swal.close()
              if (error.response.status === 401 || error.response.status === 500) {
                this.$swal(
                  error.response.data.errorMessage,
                  'Ud. no tiene permitido esta función',
                  'warning'
                )
              }
            })
        }

      }).then((result) => {
        console.log(result)
      })
    },
    /** reprote 2 */
    loadAniosr2 () {
      this.$store
        .dispatch('sirsam/FIND_ALL_ANIO_RESUMEN', {})
        .then(res => {
          this.optionAnio2 = res === undefined ? [] : res
          let countAnio = 0
          let anioSelect = ''
          this.optionAnio2.forEach(function (r) {
            countAnio = countAnio + 1
            if (countAnio === 1) {
              anioSelect = r.anoEje
            }
          })
          this.selectAnio2 = anioSelect
        })
        .catch(error => {
          if (error.response.status === 401 || error.response.status === 500) {
          }
        })
    },
    generar4: function (e) {
      this.sifExpedienteReporteResumenR4 = false
      this.sifExpedienteReportDetalleIngresoR4 = false
      this.sifExpedienteReportDetalleGastoR4 = false
      this.sifExpedienteReporte4 = false
      this.loadExpedientes()
      e.preventDefault()
    },
    limpiar4: function (e) {
      this.sifExpedienteReporte4 = false
      this.sifExpedienteReporteResumenR4 = false
      e.preventDefault()
    },
    generar2: function (e) {
      this.chgSelectR2()
      this.generateGraphicReport2()
      e.preventDefault()
    },
    limpiar2: function (e) {
      this.chgSelectR2()
      e.preventDefault()
    },
    versifExpedienteReporteResumen (row) {
      this.detNumeroExpedienteSiaf4 = row.expediente
      this.expedienteTemp4 = row.expediente
      this.detRubroFinanciamientoSiaf4 = row.fuente_financ + '-' + row.nombre_fuente_financ
      this.sifExpedienteReporteResumenR4 = true
      this.loadExpedienteSiafDetalleFase()
    },
    cleanGrafico () {
      let that = this
      that.chtOptClasR2G1.series = []
      that.chtOptClasR2G2.series = []
      that.chtOptClasR2G3.series = []
      that.chtOptClasR2G4.series = []
      that.chtOptClasR2G5.series = []
      that.chtOptClasR2G6.series = []
      that.ItemsResumenR2T1 = []
      that.ItemsResumenR2T2 = []
      that.ItemsResumenR2T4 = []
      that.ItemsResumenR2T3 = []
      that.totalPIAtabla1R2 = 0
      that.totalPIMtabla1R2 = 0
      that.totalCERTtabla1R2 = 0
      that.totalCOMANUtabla1R2 = 0
      that.totalSALCOMPtabla1R2 = 0
      that.totalDEVtabla1R2 = 0
      that.totalAVANtabla1R2 = 0
      that.totalPIAtabla2R2 = 0
      that.totalPIMtabla2R2 = 0
      that.totalCERTtabla2R2 = 0
      that.totalCOMANUtabla2R2 = 0
      that.totalSALCOMPtabla2R2 = 0
      that.totalDEVtabla2R2 = 0
      that.totalAVANtabla2R2 = 0
      that.totalPIAtabla3R2 = 0
      that.totalPIMtabla3R2 = 0
      that.totalCERTtabla3R2 = 0
      that.totalCOMANUtabla3R2 = 0
      that.totalSALCOMPtabla3R2 = 0
      that.totalDEVtabla3R2 = 0
      that.totalAVANtabla3R2 = 0
      that.totalPIAtabla4R2 = 0
      that.totalPIMtabla4R2 = 0
      that.totalCERTtabla4R2 = 0
      that.totalCOMANUtabla4R2 = 0
      that.totalSALCOMPtabla4R2 = 0
      that.totalDEVtabla4R2 = 0
      that.totalAVANtabla4R2 = 0
      this.reportGraficoTablaR2 = false
    },
    async generateGraphicReport2 () {
      let that = this
      this.sifExpedienteReportDetalleGastoR4 = false
      this.sifExpedienteReportDetalleIngresoR4 = false
      this.reportGraficoTablaR2 = true/// /////////
      /* CARGANDO AVANCE PRESUPUESTAL */
      this.isLoadingChtClasR2G1 = true
      await this.$store
        .dispatch('sirsam/FIND_ALL_GRAFICO_AVANCE_PRESUPUESTAL', { anio: that.selectAnio2, ejecutora: that.selectEjecutora2 })
        .then(res => {
          if (res !== undefined) {
            that.chtOptClasR2G1.series.push(
              { name: (that.selectAnio2 - 1).toString(), data: [(res.anioAnterior !== undefined) ? res.anioAnterior.value : 0] },
              { name: that.selectAnio2.toString(), data: [(res.anio !== undefined) ? res.anio.value : 0] }
            )
          }

          that.chtClasR2G1 = new ApexCharts(document.querySelector('#divChtClasR2G1'), that.chtOptClasR2G1)
          that.chtClasR2G1.render()
          this.isLoadingChtClasR2G1 = false
        })
        .catch(error => {
          if (error.response.status === 401 || error.response.status === 500) {
            this.$swal(
              error.response.data.errorMessage,
              'Ud. no tiene permitido esta función',
              'warning'
            )
          }
        })
      /* CARGANDO AVANCE DE EJECUCIÓN PRESUPUESTAL POR PROYECTO */
      this.isLoadingChtClasR2G2 = true
      await this.$store
        .dispatch('sirsam/FIND_ALL_GRAFICO_AVANCE_PRESUPUESTAL_PROYECTO', { anio: that.selectAnio2, ejecutora: that.selectEjecutora2 })
        .then(res => {
          if (res !== undefined) {
            that.chtOptClasR2G2.series.push(
              { name: (that.selectAnio2 - 1).toString(), data: [(res.anioAnterior !== undefined) ? res.anioAnterior.value : 0] },
              { name: that.selectAnio2.toString(), data: [(res.anio !== undefined) ? res.anio.value : 0] }
            )
          }
          that.chtClasR2G2 = new ApexCharts(document.querySelector('#divChtClasR2G2'), that.chtOptClasR2G2)
          that.chtClasR2G2.render()
          this.isLoadingChtClasR2G2 = false
        })
        .catch(error => {
          if (error.response.status === 401 || error.response.status === 500) {
            this.$swal(
              error.response.data.errorMessage,
              'Ud. no tiene permitido esta función',
              'warning'
            )
          }
        })
      /* CARGANDO AVANCE DE EJECUCIÓN PRESUPUESTAL POR PRODUCTO */
      this.isLoadingChtClasR2G3 = true
      await this.$store
        .dispatch('sirsam/FIND_ALL_GRAFICO_AVANCE_PRESUPUESTAL_PRODUCTO', { anio: that.selectAnio2, ejecutora: that.selectEjecutora2 })
        .then(res => {
          if (res !== undefined) {
            that.chtOptClasR2G3.series.push(
              { name: (that.selectAnio2 - 1).toString(), data: [(res.anioAnterior !== undefined) ? res.anioAnterior.value : 0] },
              { name: that.selectAnio2.toString(), data: [(res.anio !== undefined) ? res.anio.value : 0] }
            )
          }
          that.chtClasR2G3 = new ApexCharts(document.querySelector('#divChtClasR2G3'), that.chtOptClasR2G3)
          that.chtClasR2G3.render()
          this.isLoadingChtClasR2G3 = false
        })
        .catch(error => {
          if (error.response.status === 401 || error.response.status === 500) {
            this.$swal(
              error.response.data.errorMessage,
              'Ud. no tiene permitido esta función',
              'warning'
            )
          }
        })
      /* CARGANDO AVANCE DE EJECUCIÓN PRESUPUESTAL-MENSUALIZADA */
      this.isLoadingChtClasR2G4 = true
      await this.$store
        .dispatch('sirsam/FIND_ALL_GRAFICO_AVANCE_PRESUPUESTAL_MENSUALIZADA', { anio: that.selectAnio2, ejecutora: that.selectEjecutora2 })
        .then(res => {
          if (res !== undefined) {
            let data1 = []; let data2 = []
            if (res.anioAnterior !== undefined) {
              res.anioAnterior.forEach(function (clas) {
                data1.push(clas.value)
              })
            }
            if (res.anio !== undefined) {
              res.anio.forEach(function (clas) {
                data2.push(clas.value)
              })
            }
            that.chtOptClasR2G4.series.push(
              { name: (that.selectAnio2 - 1).toString(), data: data1 },
              { name: that.selectAnio2.toString(), data: data2 }
            )
          }
          that.chtClasR2G4 = new ApexCharts(document.querySelector('#divChtClasR2G4'), that.chtOptClasR2G4)
          that.chtClasR2G4.render()
          this.isLoadingChtClasR2G4 = false
        })
        .catch(error => {
          if (error.response.status === 401 || error.response.status === 500) {
            this.$swal(
              error.response.data.errorMessage,
              'Ud. no tiene permitido esta función',
              'warning'
            )
          }
        })
      /* CARGANDO AVANCE DE EJECUCIÓN PRESUPUESTAL-MENSUALIZADA - PROYECTO */
      this.isLoadingChtClasR2G5 = true
      await this.$store
        .dispatch('sirsam/FIND_ALL_GRAFICO_AVANCE_PRESUPUESTAL_MENSUALIZADA_PROYECTO', { anio: that.selectAnio2, ejecutora: that.selectEjecutora2 })
        .then(res => {
          if (res !== undefined) {
            let data1 = []; let data2 = []
            if (res.anioAnterior !== undefined) {
              res.anioAnterior.forEach(function (clas) {
                data1.push(clas.value)
              })
            }
            if (res.anio !== undefined) {
              res.anio.forEach(function (clas) {
                data2.push(clas.value)
              })
            }
            // console.log(data1,data2);
            that.chtOptClasR2G5.series.push(
              { name: (that.selectAnio2 - 1).toString(), data: data1 },
              { name: that.selectAnio2.toString(), data: data2 }
            )
          }
          that.chtClasR2G5 = new ApexCharts(document.querySelector('#divChtClasR2G5'), that.chtOptClasR2G5)
          that.chtClasR2G5.render()
          this.isLoadingChtClasR2G5 = false
        })
        .catch(error => {
          if (error.response.status === 401 || error.response.status === 500) {
            this.$swal(
              error.response.data.errorMessage,
              'Ud. no tiene permitido esta función',
              'warning'
            )
          }
        })
      /* CARGANDO AVANCE DE EJECUCIÓN PRESUPUESTAL-MENSUALIZADA - PRODUCTO */
      this.isLoadingChtClasR2G6 = true
      await this.$store
        .dispatch('sirsam/FIND_ALL_GRAFICO_AVANCE_PRESUPUESTAL_MENSUALIZADA_PRODUCTO', { anio: that.selectAnio2, ejecutora: that.selectEjecutora2 })
        .then(res => {
          if (res !== undefined) {
            let data1 = []
            let data2 = []
            res.anioAnterior.forEach(function (clas) {
              data1.push(clas.value)
            })
            res.anio.forEach(function (clas) {
              data2.push(clas.value)
            })
            that.chtOptClasR2G6.series.push(
              { name: (that.selectAnio2 - 1).toString(), data: data1 },
              { name: that.selectAnio2.toString(), data: data2 }
            )
          }

          that.chtClasR2G6 = new ApexCharts(document.querySelector('#divChtClasR2G6'), that.chtOptClasR2G6)
          that.chtClasR2G6.render()
          this.isLoadingChtClasR2G6 = false
        })
        .catch(error => {
          if (error.response.status === 401 || error.response.status === 500) {
            this.$swal(
              error.response.data.errorMessage,
              'Ud. no tiene permitido esta función',
              'warning'
            )
          }
        })
      /* CARGANDO REPORTE RESUMEN DE EJECUCIÓN PRESUPUESTAL PARA LAS TABLAS */
      await this.$store
        .dispatch('sirsam/FIND_ALL_TABLA_AVANCE_PRESUPUESTAL_PROYECTO', { anio: that.selectAnio2, ejecutora: that.selectEjecutora2 })
        .then(res => {
          if (res !== undefined && res.length !== 0) {
            let cert1 = 0
            let comp1 = 0
            let saldoC1 = 0
            let dev1 = 0
            let avance1 = 0
            let piaT = 0
            let pimT = 0
            let certT = 0
            let compAnT = 0
            let devT = 0
            res.forEach(function (clas) {
              if (!clas.certificado) {
                cert1 = 0
              } else {
                cert1 = clas.certificado
                certT += clas.certificado
              }
              if (!clas.compromiso_anual) {
                comp1 = 0
                saldoC1 = clas.pim
              } else {
                comp1 = clas.compromiso_anual
                saldoC1 = clas.pim - clas.compromiso_anual
                compAnT += clas.compromiso_anual
              }
              if (!clas.devengado) {
                dev1 = 0
                avance1 = 0
              } else {
                dev1 = clas.devengado
                avance1 = (clas.pim === 0.00) ? 0 : (clas.devengado / clas.pim) * 100
                devT += clas.devengado
              }
              that.ItemsResumenR2T1.push({
                actProy: (clas.tipo_act_proy === '2') ? 'Proyectos' : 'Productos',
                pia: 'S/. ' + clas.pia.toLocaleString('en-US'),
                pim: 'S/. ' + clas.pim.toLocaleString('en-US'),
                certificado: 'S/. ' + cert1.toLocaleString('en-US'), //
                compromisoAnual: 'S/. ' + comp1.toLocaleString('en-US'), //
                saldoComprometer: 'S/. ' + saldoC1.toLocaleString('en-US'), //
                devengado: 'S/. ' + dev1.toLocaleString('en-US'), //
                avance: avance1.toFixed(2).toLocaleString('en-US')//
              })
              piaT += clas.pia
              pimT += clas.pim
            })

            that.totalPIAtabla1R2 = piaT
            that.totalPIMtabla1R2 = pimT
            that.totalCERTtabla1R2 = certT
            that.totalCOMANUtabla1R2 = compAnT
            that.totalSALCOMPtabla1R2 = pimT - compAnT
            that.totalDEVtabla1R2 = devT
            that.totalAVANtabla1R2 = (devT / pimT) * 100
          }
        })
        .catch(error => {
          if (error.response.status === 401 || error.response.status === 500) {
            this.$swal(
              error.response.data.errorMessage,
              'Ud. no tiene permitido esta función',
              'warning'
            )
          }
        })

      await this.$store
        .dispatch('sirsam/FIND_ALL_TABLA_AVANCE_PRESUPUESTAL_GENERICA', { anio: that.selectAnio2, ejecutora: that.selectEjecutora2 })
        .then(res => {
          if (res !== undefined && res.length !== 0) {
            let cert1 = 0
            let comp1 = 0
            let saldoC1 = 0
            let dev1 = 0
            let avance1 = 0
            let piaT = 0
            let pimT = 0
            let certT = 0
            let compAnT = 0
            let devT = 0
            res.forEach(function (clas) {
              if (!clas.certificado) {
                cert1 = 0
              } else {
                cert1 = clas.certificado
                certT += clas.certificado
              }
              if (!clas.compromiso_anual) {
                comp1 = 0
                saldoC1 = clas.pim
              } else {
                comp1 = clas.compromiso_anual
                saldoC1 = clas.pim - clas.compromiso_anual
                compAnT += clas.compromiso_anual
              }
              if (!clas.devengado) {
                dev1 = 0
                avance1 = 0
              } else {
                dev1 = clas.devengado
                avance1 = (clas.pim === 0.00) ? 0 : (clas.devengado / clas.pim) * 100
                devT += clas.devengado
              }
              that.ItemsResumenR2T2.push({
                descripcion: clas.clasificador + ' - ' + clas.descripcion,
                pia: 'S/. ' + clas.pia.toLocaleString('en-US'),
                pim: 'S/. ' + clas.pim.toLocaleString('en-US'),
                certificado: 'S/. ' + cert1.toLocaleString('en-US'), //
                compromisoAnual: 'S/. ' + comp1.toLocaleString('en-US'), //
                saldoComprometer: 'S/. ' + saldoC1.toLocaleString('en-US'), //
                devengado: 'S/. ' + dev1.toLocaleString('en-US'), //
                avance: avance1.toFixed(2).toLocaleString('en-US')//
              })
              piaT += clas.pia
              pimT += clas.pim
            })
            that.totalPIAtabla2R2 = piaT
            that.totalPIMtabla2R2 = pimT
            that.totalCERTtabla2R2 = certT
            that.totalCOMANUtabla2R2 = compAnT
            that.totalSALCOMPtabla2R2 = pimT - compAnT
            that.totalDEVtabla2R2 = devT
            that.totalAVANtabla2R2 = (devT / pimT) * 100
          }
        })
        .catch(error => {
          if (error.response.status === 401 || error.response.status === 500) {
            this.$swal(
              error.response.data.errorMessage,
              'Ud. no tiene permitido esta función',
              'warning'
            )
          }
        })

      await this.$store
        .dispatch('sirsam/FIND_ALL_TABLA_AVANCE_PRESUPUESTAL_FUENTE', { anio: that.selectAnio2, ejecutora: that.selectEjecutora2 })
        .then(res => {
          if (res !== undefined && res.length !== 0) {
            let cert1 = 0
            let comp1 = 0
            let saldoC1 = 0
            let dev1 = 0
            let avance1 = 0
            let piaT = 0
            let pimT = 0
            let certT = 0
            let compAnT = 0
            let devT = 0
            res.forEach(function (clas) {
              if (!clas.certificado) {
                cert1 = 0
              } else {
                cert1 = clas.certificado
                certT += clas.certificado
              }
              if (!clas.compromiso_anual) {
                comp1 = 0
                saldoC1 = clas.pim
              } else {
                comp1 = clas.compromiso_anual
                saldoC1 = clas.pim - clas.compromiso_anual
                compAnT += clas.compromiso_anual
              }
              if (!clas.devengado) {
                dev1 = 0
                avance1 = 0
              } else {
                dev1 = clas.devengado
                avance1 = (clas.pim === 0.00) ? 0 : (clas.devengado / clas.pim) * 100
                devT += clas.devengado
              }
              that.ItemsResumenR2T3.push({
                nombre: clas.fuente_financ_agregada + ' - ' + clas.nombre,
                pia: 'S/. ' + clas.pia.toLocaleString('en-US'),
                pim: 'S/. ' + clas.pim.toLocaleString('en-US'),
                certificado: 'S/. ' + cert1.toLocaleString('en-US'), //
                compromisoAnual: 'S/. ' + comp1.toLocaleString('en-US'), //
                saldoComprometer: 'S/. ' + saldoC1.toLocaleString('en-US'), //
                devengado: 'S/. ' + dev1.toLocaleString('en-US'), //
                avance: avance1.toFixed(2).toLocaleString('en-US')//
              })
              piaT += clas.pia
              pimT += clas.pim
            })
            that.totalPIAtabla3R2 = piaT
            that.totalPIMtabla3R2 = pimT
            that.totalCERTtabla3R2 = certT
            that.totalCOMANUtabla3R2 = compAnT
            that.totalSALCOMPtabla3R2 = pimT - compAnT
            that.totalDEVtabla3R2 = devT
            that.totalAVANtabla3R2 = (devT / pimT) * 100
          }
        })
        .catch(error => {
          if (error.response.status === 401 || error.response.status === 500) {
            this.$swal(
              error.response.data.errorMessage,
              'Ud. no tiene permitido esta función',
              'warning'
            )
          }
        })

      await this.$store
        .dispatch('sirsam/FIND_ALL_TABLA_AVANCE_PRESUPUESTAL_CATEGORIA', { anio: that.selectAnio2, ejecutora: that.selectEjecutora2 })
        .then(res => {
          if (res !== undefined && res.length !== 0) {
            let cert1 = 0
            let comp1 = 0
            let saldoC1 = 0
            let dev1 = 0
            let avance1 = 0
            let piaT = 0
            let pimT = 0
            let certT = 0
            let compAnT = 0
            let devT = 0
            res.forEach(function (clas) {
              if (!clas.certificado) {
                cert1 = 0
              } else {
                cert1 = clas.certificado
                certT += clas.certificado
              }
              if (!clas.compromiso_anual) {
                comp1 = 0
                saldoC1 = clas.pim
              } else {
                comp1 = clas.compromiso_anual
                saldoC1 = clas.pim - clas.compromiso_anual
                compAnT += clas.compromiso_anual
              }
              if (!clas.devengado) {
                dev1 = 0
                avance1 = 0
              } else {
                dev1 = clas.devengado
                avance1 = (clas.pim === 0.00) ? 0 : (clas.devengado / clas.pim) * 100
                devT += clas.devengado
              }
              that.ItemsResumenR2T4.push({
                nombre: clas.categoria_gasto + ' - ' + clas.nombre,
                pia: 'S/. ' + clas.pia.toLocaleString('en-US'),
                pim: 'S/. ' + clas.pim.toLocaleString('en-US'),
                certificado: 'S/. ' + cert1.toLocaleString('en-US'), //
                compromisoAnual: 'S/. ' + comp1.toLocaleString('en-US'), //
                saldoComprometer: 'S/. ' + saldoC1.toLocaleString('en-US'), //
                devengado: 'S/. ' + dev1.toLocaleString('en-US'), //
                avance: avance1.toFixed(2).toLocaleString('en-US')//
              })
              piaT += clas.pia
              pimT += clas.pim
            })
            that.totalPIAtabla4R2 = piaT
            that.totalPIMtabla4R2 = pimT
            that.totalCERTtabla4R2 = certT
            that.totalCOMANUtabla4R2 = compAnT
            that.totalSALCOMPtabla4R2 = pimT - compAnT
            that.totalDEVtabla4R2 = devT
            that.totalAVANtabla4R2 = (devT / pimT) * 100
          }
        })
        .catch(error => {
          if (error.response.status === 401 || error.response.status === 500) {
            this.$swal(
              error.response.data.errorMessage,
              'Ud. no tiene permitido esta función',
              'warning'
            )
          }
        })
    }
  },
  computed: {
    ...mapGetters({
      page: 'Setting/activePage',
      navList: 'Setting/globalSearchState',
      bookmark: 'Setting/bookmarkState'
    })
  }
}
</script>

<style>
.table_ejecucion_presupuestal{
  font-size: 11px !important;
}
.form-control{
  height: calc(1.5em + .75rem + 5px) !important;
  border-radius: 5px !important;
  padding: 0.375rem 1.75rem 0.375rem 0.75rem !important;
}
</style>
