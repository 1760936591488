<template>
  <component :is="template"></component>
</template>
<script>
import FuncionarioList from './FuncionarioList'
import FuncionarioNew from './FuncionarioNew'
import FuncionarioEdit from './FuncionarioEdit'

import { mapGetters } from 'vuex'
export default {
  components: {
    FuncionarioList,
    FuncionarioNew,
    FuncionarioEdit
  },
  mounted () {
    let muta = {}

    if (this.$route.query.oper === undefined) {
      muta.oper = 'list'
    } else {
      muta.oper = this.$route.query.oper
    }
    if (this.$route.query.id === undefined) {
      muta.id = 0
    } else {
      muta.id = this.$route.query.id
    }
    this.$store.commit('gsplanifica/OPERACION_FUNCIONARIO', muta)
  },
  computed: { ...mapGetters({ template: 'gsplanifica/getFuncionarioTpl' }) }
}
</script>
<style>
</style>
