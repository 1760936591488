import presupuestoService from '@/services/Sirsam/presupuesto'

export default {
  'PRESUPUESTO_GETEXPEDIENTES': (_, params) => {
    return new Promise((resolve, reject) => {
      presupuestoService.getExpedientes(params).then(res => {
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  }
}
