<template>
  <div>
    <h1 class="mb-0">Iniciar Sesión</h1>
    <p>Ingrese su correo y contraseña para acceder a la Plataforma.</p>
    <div class="mb-3">
      <sign-in1-form formType="jwt" email="invitado@godatamanager.com" password="admin123"></sign-in1-form>
    </div>
  </div>
</template>

<script>
import auth0 from 'auth0-js'
import SignIn1Form from './Forms/SignIn1Form'
import constant from '../../../config/constant'

export default {
  name: 'SignIn1',
  components: { SignIn1Form },
  data: () => ({}),
  mounted () {
    const loggedIn = localStorage.getItem('access_token')
    if (loggedIn !== undefined && loggedIn !== null) {
      this.$router.push({ name: 'dashboard.dashboard' })
    }
  },
  methods: {
    loginOAuth0: function () {
      new auth0.WebAuth(constant.auth0Config).authorize()
    }
  }
}
</script>
