import proyectoService from '@/services/GSPlanifica/proyecto'

export default {
  'PROYECTO_FIND_ALL': (_, params) => {
    return new Promise((resolve, reject) => {
      proyectoService.findAll(params).then(res => {
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  },
  'PROYECTO_GET_NO_PRIORIZADOS': (_, params) => {
    return new Promise((resolve, reject) => {
      proyectoService.getNoPriorizados(params).then(res => {
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  },
  'PROYECTO_CREATE': (_, params) => {
    return new Promise((resolve, reject) => {
      proyectoService.create(params).then(res => {
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  },
  'PROYECTO_UPDATE': (_, params) => {
    return new Promise((resolve, reject) => {
      proyectoService.update(params).then(res => {
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  },
  'PROYECTO_FIND': (_, params) => {
    return new Promise((resolve, reject) => {
      proyectoService.find(params.id).then(res => {
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  },
  'PROYECTO_DELETE': (_, params) => {
    return new Promise((resolve, reject) => {
      proyectoService.delete(params).then(res => {
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  }
}
