<template>
  <b-container fluid>
    <b-row>
      <b-col md="12">
        <iq-card>
          <div class="d-flex align-items-center justify-content-between pl-2 pr-3">
            <div class="d-flex align-items-center">
              <h4 class="mb-0 p-3">{{ page.name }}</h4><br />
              <b-breadcrumb :items="page.breadCrumb" class="bg-transparent mb-0 mt-1" />
            </div>
            <div class="d-flex justify-content-between">

            </div>
          </div>
        </iq-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="12">
        <iq-card class-name="overflow-hidden">
          <template v-slot:headerTitle>
            <h4 class="card-title">Registro de Funcionario</h4>
          </template>
          <template v-slot:body>
            <b-row>
              <b-col md="12" >
                <b-alert  :show="errors.length>=0" v-for="error in errors" :key="error" variant="danger">
                  <div class="iq-alert-text">
                    {{ error }}
                  </div>
                </b-alert>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="12">
              <b-row>
                <b-col md="3">
                <b-form-group label="Dni" label-for="dni">
                  <b-form-input type="text" v-model="funcionario.documento" size="sm" id="documento" placeholder="Ingrese dni.."></b-form-input>
                </b-form-group>
                </b-col>
                <b-col md="3">
                <b-form-group label="Nombre" label-for="nombre">
                  <b-form-input type="text" v-model="funcionario.nombre" size="sm" id="nombre" placeholder="Ingrese nombre.."></b-form-input>
                </b-form-group>
                </b-col>
                <b-col md="3">
                <b-form-group label="Apellido Paterno" label-for="apellidoPaterno">
                  <b-form-input type="text" v-model="funcionario.apellidoPaterno" size="sm" id="apellidoPaterno" placeholder="Ingrese apellido paterno.."></b-form-input>
                </b-form-group>
                </b-col>
                  <b-col md="3">
                <b-form-group label="Apellido Materno" label-for="apellidoMaterno">
                  <b-form-input type="text" v-model="funcionario.apellidoMaterno" size="sm" id="apellidoMaterno" placeholder="Ingrese apellido materno.."></b-form-input>
                </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col md="3">
                <b-form-group label="celular" label-for="celular">
                  <b-form-input type="text" v-model="funcionario.celular" size="sm" id="celular" placeholder="Ingrese celular.."></b-form-input>
                </b-form-group>
                </b-col>
                <b-col md="3">
                  <b-form-group label="Email" label-for="Email">
                  <b-form-input type="text" v-model="funcionario.email" size="sm" id="email" placeholder="Ingrese email.."></b-form-input>
                </b-form-group>
                </b-col>
                  <b-col md="3">
                <b-form-group label="Cargo" label-for="cargo">
                  <b-form-input v-model="funcionario.cargo" type="text" size="sm" id="cargo"></b-form-input>
                </b-form-group>
                </b-col>
                <b-col md="3">
                  <b-form-group label="Unidad Ejecutora" label-for="unidadEjecutora">
                    <b-form-select v-model="modelUnidadEjecutora" type="select" class="custom-select"  required>
                      <option value="">SELECCIONE::</option>
                      <option  v-for="ue in optionUnidadEjecutora"  :key="ue.idUnidadEjecutora" :value="ue.idUnidadEjecutora"  >{{ue.nombre}}</option>
                    </b-form-select>
                  </b-form-group>
                </b-col>
              </b-row>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="12" style="text-align:end">
                <b-form-group label="" label-for="acciones2">
                  <b-button variant = "success" @click="save"><i class="far fa-save"></i>Guardar</b-button>
                  <b-button  style="margin-left:0.6%" variant = "danger" @click="onSalir"><i class="fas fa-sign-out-alt"></i>Cancelar</b-button>
                </b-form-group>
              </b-col>
            </b-row>
          </template>
         </iq-card>
       </b-col>
     </b-row>
  </b-container>
</template>
<script>
import { mapGetters } from 'vuex'
import { vito } from '../../../config/pluginInit'
export default {
  components: {},
  mounted () {
    vito.index()
    this.loadUnidadEjecutora()
  },
  data () {
    return {
      errors: [],
      modelo: [],
      b_val: true,
      optionUnidadEjecutora: [],
      unidadEjecutora: '',
      modelUnidadEjecutora: '',
      funcionario: {
        idFuncionario: 0,
        nombre: '',
        documento: '',
        apellidoPaterno: '',
        apellidoMaterno: '',
        email: '',
        celular: '',
        cargo: '',
        unidadEjecutora: {
          idUnidadEjecutora: 0
        }
      }
    }
  },
  methods: {
    onSalir () {
      this.$router.push({
        name: 'administracion.funcionario'
      })
      let mutacion = {
        oper: 'list'
      }
      this.$store.commit('gsplanifica/OPERACION_FUNCIONARIO', mutacion)
    },
    loadUnidadEjecutora () {
      this.$swal({
        width: 370,
        // height:10,
        text: 'Cargando....',
        showCancelButton: false,
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false,
        onOpen: (swal) => {
          this.$swal.showLoading()
          this.$store
            .dispatch('gsplanifica/UNIDAD_EJECUTORA_FIND_ALL', {})
            .then(res => {
              this.optionUnidadEjecutora = res === undefined ? [] : res
              this.$swal.close()
            })
            .catch(error => {
              this.$swal.close()
              if (error.response.status === 401 || error.response.status === 500) {
                this.$swal(
                  error.response.data.errorMessage,
                  'Ud. no tiene permitido esta función',
                  'warning'
                )
              }
            })
        }
      }).then((result) => {
        console.log(result)
      })
    },
    save () {
      this.errors = []
      this.b_val = true
      if (this.b_val && this.funcionario.documento.length === 0) {
        this.b_val = false
        this.errors.push('Ingrese su N° de documento')
      }
      if (this.b_val && this.funcionario.nombre.length === 0) {
        this.b_val = false
        this.errors.push('Ingrese su nombre')
      }
      if (this.b_val && this.funcionario.apellidoPaterno.length === 0) {
        this.b_val = false
        this.errors.push('Ingrese su apellido paterno')
      }
      if (this.b_val && this.funcionario.apellidoMaterno.length === 0) {
        this.b_val = false
        this.errors.push('Ingrese su apellido materno')
      }
      if (this.b_val && this.funcionario.celular.length === 0) {
        this.b_val = false
        this.errors.push('Ingrese su celular')
      }
      if (this.b_val && this.funcionario.email.length === 0) {
        this.b_val = false
        this.errors.push('Ingrese su email')
      }
      if (this.b_val && this.funcionario.cargo.length === 0) {
        this.b_val = false
        this.errors.push('Ingrese su email')
      }
      if (this.b_val) {
        this.funcionario.unidadEjecutora.idUnidadEjecutora = this.modelUnidadEjecutora
        this.$swal({
          width: 370,
          text: 'Cargando....',
          showCancelButton: false,
          showConfirmButton: false,
          allowOutsideClick: false,
          allowEscapeKey: false,
          onOpen: (swal) => {
            this.$swal.showLoading()
            this.$store
              .dispatch('gsplanifica/FUNCIONARIO_CREATE', this.funcionario)
              .then((res) => {
                this.isBusy = false
                this.$swal.close()
                this.modelo = res === undefined ? [] : res
                this.onSalir()
              })
              .catch((error) => {
                this.onSalir()
                if (error.response.status === 401 || error.response.status === 500) {
                  this.$swal(
                    error.response.data.errorMessage,
                    'Ud. no tiene permitido esta función',
                    'warning'
                  )
                }
              })
          }
        }).then((result) => {
          console.log(result)
        })
      }
    },
    requiredFields (val, field, message) {
      if (val && field.length === 0) {
        this.b_val = false
        this.errors.push(message)
      }
    }
  },
  created () {},
  computed: {
    ...mapGetters({
      page: 'Setting/activePage',
      navList: 'Setting/globalSearchState',
      bookmark: 'Setting/bookmarkState'
    })
  }
}
</script>
