<template>
  <b-container fluid>
    <b-row>
      <b-col md="12">
        <iq-card>
          <div
            class="d-flex align-items-center justify-content-between pl-2 pr-3"
          >
            <div class="d-flex align-items-center">
              <h4 class="mb-0 p-3">{{ page.name }}</h4>
              <br />
              <b-breadcrumb
                :items="page.breadCrumb"
                class="bg-transparent mb-0 mt-1"
              />
            </div>
            <div class="d-flex justify-content-between">
              <b-button
                variant="primary"
                v-on:click="open(0, 'new')"
                class="button_header"
                ><i class="las la-plus"></i>Nuevo Funcionario</b-button
              >
            </div>
          </div>
        </iq-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="12">
        <iq-card class-name="overflow-hidden" id="body-grid">
          <template v-slot:headerTitle>
            <h4 class="card-title">Lista de Funcionarios</h4>
          </template>
          <template v-slot:headerAction>
            <b-row>
              <b-col md="12" class="pr-0">
                <div class="iq-search-bar">
                  <form action="#" class="searchbox" style="width: auto !important;">
                    <input
                      type="text"
                      v-model="filter"
                      class="text search-input"
                      placeholder="Buscar...."
                    />
                    <a class="search-link" href="#"
                      ><i class="ri-search-line"></i
                    ></a>
                  </form>
                </div>
              </b-col>
            </b-row>
          </template>
          <template v-slot:body>
            <b-row>
              <b-col md="12">
                <b-table
                  hover
                  striped
                  :items="items"
                  :fields="fieldsFuncionario"
                  :current-page="currentPage"
                  :per-page="perPage"
                  :sort-compare="compareCurrency"
                  :filter="filter"
                  @filtered="onFiltered"
                  class="mb-0 table-borderless p-0 tgrid"
                >
                  <template v-slot:cell(documento)="data_documento">
                    <p class="text-center mb-0 ">
                      <span class="td_size">{{ data_documento.value }}</span>
                    </p>
                  </template>
                  <template v-slot:cell(nombre)="data_nombre">
                    <p class="text-left mb-0 ">
                      <span class="td_size">{{ data_nombre.value }}</span>
                    </p>
                  </template>
                  <template v-slot:cell(celular)="data_celular">
                    <p class="text-left mb-0 ">
                      <span class="td_size">{{ data_celular.value }}</span>
                    </p>
                  </template>
                  <template v-slot:cell(email)="data_email">
                    <p class="text-left mb-0 ">
                      <span class="td_size">{{ data_email.value }}</span>
                    </p>
                  </template>
                  <template v-slot:cell(unidadEjecutora)="data_unidad">
                    <p class="text-left mb-0 ">
                      <span class="td_size">{{ data_unidad.value }}</span>
                    </p>
                  </template>
                  <template v-slot:cell(estado)="data">
                    <b-badge pill :variant="data.value.color">{{
                      data.value.estado
                    }}</b-badge>
                  </template>
                  <template v-slot:cell(action)="row">
                    <b-button variant=" iq-bg-success mr-1 mb-1" size="sm" @click.stop="open(row.item, 'edit')"><i class="ri-ball-pen-fill m-0"></i></b-button>
                    <b-button variant=" iq-bg-danger" size="sm" @click.stop="borrar(row.item)"><i class="ri-delete-bin-line m-0"></i></b-button>
                  </template>
                </b-table>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="12" class="my-1 pagination">
                <b-pagination
                  :total-rows="totalRows"
                  :per-page="perPage"
                  v-model="currentPage"
                />
              </b-col>
            </b-row>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { mapGetters } from 'vuex'
import { vito } from '../../../config/pluginInit'
const items = []
export default {
  components: {},
  mounted () {
    vito.index()
    this.listar()
  },
  data () {
    return {
      totalRows: items.length,
      perPage: 10,
      currentPage: 1,
      filter: null,
      items: [],
      pageOptions: [10, 15, 20, 30],
      fieldsFuncionario: [
        {
          key: 'documento',
          label: 'Dni',
          sortable: true,
          tdClass: 'pt-0 pb-0 align-middle text-center',
          thClass: 'text-center'
        },
        {
          key: 'nombre',
          label: 'Nombres',
          sortable: true,
          tdClass: 'pt-0 pb-0 align-middle',
          thClass: 'text-left'
        },
        {
          key: 'celular',
          label: 'Celular',
          sortable: true,
          tdClass: 'pt-0 pb-0 align-middle',
          thClass: 'text-left'
        },
        {
          key: 'email',
          label: 'Email',
          sortable: true,
          tdClass: 'pt-0 pb-0 align-middle',
          thClass: 'text-left'
        },
        {
          key: 'unidadEjecutora',
          label: 'Unidad Ejecutora',
          sortable: true,
          tdClass: 'pt-0 pb-0 align-middle',
          thClass: 'text-left'
        },
        {
          key: 'estado',
          label: 'Estado',
          sortable: true,
          tdClass: 'pt-0 pb-0 align-middle text-center',
          thClass: 'text-center'
        },
        { label: '', key: 'action', class: 'text-center' }
      ]
    }
  },
  methods: {
    onFiltered (filteredItems) {
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    open (data, operacion) {
      let mutacion = {
        oper: operacion,
        id: data.idFuncionario
      }
      this.$router.push({
        name: 'administracion.funcionario',
        query: mutacion
      })
      this.$store.commit('gsplanifica/OPERACION_FUNCIONARIO', mutacion)
    },
    compareCurrency (itemA, itemB, key) {
      if (key !== 'pim' && key !== 'pia' && key !== 'devengado') {
        return false
      } else {
        let a = itemA[key]
        let b = itemB[key]
        a = a.replace('S/. ', '')
        a = a.replace(/,/g, '')
        b = b.replace('S/. ', '')
        b = b.replace(/,/g, '')
        return a - b
      }
    },
    listar () {
      let that = this
      this.$swal({
        width: 370,
        text: 'Cargando....',
        showCancelButton: false,
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false,
        onOpen: swal => {
          this.$swal.showLoading()
          this.$store
            .dispatch('gsplanifica/FUNCIONARIO_FIND_ALL', {})
            .then(res => {
              that.items = []
              res.forEach(function (row) {
                that.items.push({
                  idFuncionario: row.idFuncionario,
                  documento: row.documento,
                  nombre:
                    row.nombre +
                    ' ' +
                    row.apellidoMaterno +
                    ' ' +
                    row.apellidoPaterno,
                  celular: row.celular,
                  email: row.email,
                  cargo: row.cargo,
                  unidadEjecutora: row.unidadEjecutora.nombre,
                  estado: row.estado
                    ? { color: 'success', estado: 'ACTIVO' }
                    : { color: 'danger', estado: 'INACTIVO' }
                })
              })
              that.totalRows = that.items.length
              this.$swal.close()
            })
            .catch(error => {
              this.$swal.close()
              if (
                error.response.status === 401 ||
                error.response.status === 500
              ) {
                this.$swal(
                  error.response.data.errorMessage,
                  'Ud. no tiene permitido esta función',
                  'warning'
                )
              }
            })
        }
      }).then(result => {
        console.log(result)
      })
    },
    borrar (select) {
      this.$swal({
        title: 'Usted está seguro?',
        text: 'Se va borrar el registro!',
        type: 'warning',
        showCancelButton: true,
        cancelButtonText: 'No',
        confirmButtonText: 'Sí, Borrar',
        confirmButtonColor: '#d33'
      }).then(result => {
        if (result.value) {
          this.$store
            .dispatch('gsplanifica/FUNCIONARIO_DELETE', select)
            .then(res => {
              this.$swal.fire(
                'Borrado!',
                'El funcionario fue borrado satisfactoriamente.',
                'success'
              )
              this.listar()
            })
            .catch(error => {
              if (
                error.response.status === 401 ||
                error.response.status === 500
              ) {
                this.$swal(
                  error.response.data.errorMessage,
                  'Ud. no tiene permitido esta función',
                  'warning'
                )
              }
            })
        }
      })
    }
  },
  computed: {
    ...mapGetters({
      page: 'Setting/activePage',
      navList: 'Setting/globalSearchState',
      bookmark: 'Setting/bookmarkState'
    })
  }
}
</script>

<style>
.iq-card-body {
  flex: unset;
}
.pagination {
  justify-content: flex-end;
}
.pagination > ul {
  margin-right: 0.7% !important;
}
.fa-2x {
  font-size: 1.3em !important;
}
.pagination {
  padding: 3px !important;
}
.page-item.active .page-link {
  color: #fff;
  background-color: #207ce5 !important;
  border-color: #207ce5 !important;
}
.page-item.disabled .page-link {
  border-color: #207ce5 !important;
}
.page-item .page_list {
  background-color: #ffffff !important;
  border: 1px solid #dddddd !important;
  color: inherit !important;
  line-height: 1.42857 !important;
  margin-left: -1px !important;
  position: relative !important;
  text-decoration: none !important;
}
.form-control-sm {
  height: calc(1.5em + 0.5rem + 2px) !important;
}
.td_size {
  font-size: 11.5px !important;
}
#body-grid .iq-card-body {
  padding-top: 0px !important;
}
.tgrid thead tr {
  background: rgb(38,112,191);
  background: linear-gradient(180deg, rgba(38,112,191,1) 0%, rgba(38,112,191,1) 35%, rgba(4,51,102,1) 100%);
  color: white;
}
</style>
