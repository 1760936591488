export default {
  getFuncionarioTpl: (state) => {
    if (state.operacion === 'list') {
      console.log('listar ' + state.operacion)
      return 'FuncionarioList'
    } else if (state.operacion === 'new') {
      console.log('nuevo ' + state.operacion)
      return 'FuncionarioNew'
    } else if (state.operacion === 'edit') {
      console.log('editar ' + state.operacion)
      return 'FuncionarioEdit'
    }
  }
}
