import apiCall from '../index.js'

const apiService = '/api-datamanager/api/v1/unidadejecutora/'

const service = {
  findAll (params) {
    let query = ''
    return new Promise((resolve, reject) => {
      apiCall.get(apiService + query).then(res => resolve(res.data)).catch(err => reject(err))
    })
  }
}

export default service
