<template>
  <div>
    <iq-card id="DivPy">
      <template v-slot:headerTitle>
        <h4 class="card-title">Consolidado de Proyectos de Inversión</h4>
      </template>
      <template v-slot:headerAction>
          <b-dropdown id="dropdownMenuButton1" right variant="none" data-toggle="dropdown">
            <template v-slot:button-content>
                <i class="ri-more-fill"></i>
            </template>
            <b-dropdown-group id="dropdown-group-1" header="Columnas" class="pl-0">
              <b-dropdown-form>
                <div
                  v-for="field in fieldsPliegosEG"
                  :key="field.id">
                  <b-form-checkbox
                    class="d-block"
                    switch
                    :disabled="field.disabledF"
                    v-if="!field.hiddenF"
                    v-model="field.visible"
                    v-show="false"
                    >
                    {{ field.label }}
                  </b-form-checkbox>
                  </div>
              </b-dropdown-form>
            </b-dropdown-group>
            <!-- <b-dropdown-item @click="downloadXls('UE')"><i class="ri-file-excel-line mr-2 align-middle"></i> Descargar en Excel</b-dropdown-item> -->
            <!-- <b-dropdown-item @click="shareWtp('UE')"><i class="ri-whatsapp-line mr-2 align-middle"></i> Compartir por Whatsapp</b-dropdown-item> -->
          </b-dropdown>
        </template>
      <template v-slot:body>
        <b-table hover striped :items="tablePliegosEG" :fields="visibleFields" class="mb-0 table-borderless p-0" :sort-compare="compareCurrency" :sort-by.sync="sortByPEG" :sort-desc.sync="sortDescPEG" foot-clone>
          <template #cell(index)="data">
            <b-badge pill variant="dark">{{ data.index + 1 }}</b-badge>
          </template>
          <template v-slot:cell(pliego)="data">
            <b-badge pill variant="dark">{{ data.index + 1 }}</b-badge>
            <b-badge pill :variant="data.value.color" class="ml-1">{{ data.value.codigo }}</b-badge>
            <span class="font-size-9 pl-1">{{ data.value.nombre }}</span>
            <p class="mt-1 mb-0">
              <b-button size="sm" v-b-tooltip.top="'SSI'" variant="light" class="d-inline" v-on:click="getLinkFichaSSI(data.value.codigo)"><i class="ri-file-search-line mr-0"></i></b-button>
              <b-button size="sm" v-b-tooltip.top="'Ver Ficha'" variant="light" class="d-inline ml-1" v-on:click="getLinkFicha(data.value.codigo)"><i class="ri-link mr-0"></i></b-button>
              <b-button size="sm" v-b-tooltip.top="'Formato 12B'" variant="light" class="d-inline ml-1" v-on:click="getLinkFicha12B(data.value.codigo)"><i class="ri-file-list-line mr-0"></i></b-button>
              <!-- <b-button size="sm" v-b-tooltip.top="'Reporte Clasificadores'" variant="light" class="d-inline ml-1"><i class="ri-line-chart-line mr-0"></i></b-button> -->
            </p>
          </template>
          <template v-slot:cell(beneficiarios)="data_bnf">
            <p class='text-right mb-0' style="white-space: nowrap;"><span class="font-size-10">{{ data_bnf.value }}</span></p>
          </template>
          <template v-slot:cell(costoTotalActualizado)="data_cta">
            <p class='text-right mb-0' style="white-space: nowrap;"><span class="font-size-10">{{ data_cta.value }}</span></p>
          </template>
          <template v-slot:cell(pim)="data_pim">
            <p class='text-right mb-0' style="white-space: nowrap;"><span class="font-size-10">{{ data_pim.value }}</span></p>
          </template>
          <template v-slot:cell(certificacion)="data_certificacion">
            <p class='text-right mb-0' style="white-space: nowrap;"><span class="font-size-10">{{ data_certificacion.value }}</span></p>
          </template>

          <template v-slot:cell(compromiso_a)="data_compromiso_a">
              <p class='text-right mb-0'><span class="font-size-10 text-nowrap">{{ getNumbertoMoney(data_compromiso_a.value, 'S/.') }}</span></p>
            </template>
            <template v-slot:cell(compromiso_m)="data_compromiso_m">
              <p class='text-right mb-0'><span class="font-size-10 text-nowrap">{{ getNumbertoMoney(data_compromiso_m.value, 'S/.') }}</span></p>
            </template>
            <template v-slot:cell(xcertificar)="data_xcert">
              <p class='text-right mb-0'><span class="font-size-10 text-nowrap">{{ getNumbertoMoney(data_xcert.value, 'S/.') }}</span></p>
            </template>

          <template v-slot:cell(devengado)="data_dev">
            <p class='text-right mb-0' style="white-space: nowrap;"><span class="font-size-10">{{ data_dev.value }}</span></p>
          </template>

          <template v-slot:cell(xdevengar)="data_xdev">
              <p class='text-right mb-0'><span class="font-size-10 text-nowrap">{{ getNumbertoMoney(data_xdev.value, 'S/.') }}</span></p>
            </template>
            <template v-slot:cell(xdevengar_m)="data_m_xdev">
              <p class='text-right mb-0'><span class="font-size-10 text-nowrap">{{ getNumbertoMoney(data_m_xdev.value, 'S/.') }}</span></p>
            </template>
            <template v-slot:cell(girado)="data_girado">
              <p class='text-right mb-0'><span class="font-size-10 text-nowrap">{{ getNumbertoMoney(data_girado.value, 'S/.') }}</span></p>
            </template>

          <template v-slot:cell(avance)="data1">
            <center><span class="font-size-10 font-weight-bold">{{ data1.value.count }}%</span></center>
            <b-progress :value="data1.value.count" class="iq-progress-bar" :variant="getColorSemaforo(data1.value.count, 'cls')">
            </b-progress>
          </template>

          <template class='text-right' v-slot:foot(pliego)="foot_data_col1">
            <p class='text-right'><span>Total</span></p>
          </template>
          <template class='text-right' v-slot:foot(beneficiarios)="foot_data_col3">
            <p class='text-right'><span class="font-size-10 font-weight-bold">{{ totalBeneficiarios.toLocaleString('en-US') }}</span></p>
          </template>
          <template class='text-right' v-slot:foot(costoTotalActualizado)="foot_data_col3">
            <p class='text-right'><span class="font-size-10 font-weight-bold">S/. {{ totalCosto.toLocaleString('en-US') }}</span></p>
          </template>
          <template class='text-right' v-slot:foot(pia)="foot_data_col3">
            <p class='text-right'><span class="font-size-10 font-weight-bold">S/. {{ totalPIA.toLocaleString('en-US') }}</span></p>
          </template>
          <template class='text-right' v-slot:foot(pim)="foot_data_col3">
            <p class='text-right'><span class="font-size-10 font-weight-bold">S/. {{ totalPIM.toLocaleString('en-US') }}</span></p>
          </template>
          <template class='text-right' v-slot:foot(certificacion)="foot_data_col2">
            <p class='text-right'><span class="font-size-10 font-weight-bold">S/. {{ totalCertificado.toLocaleString('en-US') }}</span></p>
          </template>
          <template class='text-right' v-slot:foot(compromiso_a)="foot_data_col3">
            <p class='text-right'><span class="font-size-10 font-weight-bold">S/. {{ totalCompromisoA.toLocaleString('en-US') }}</span></p>
          </template>
          <template class='text-right' v-slot:foot(compromiso_m)="foot_data_col3">
            <p class='text-right'><span class="font-size-10 font-weight-bold">S/. {{ totalCompromisoM.toLocaleString('en-US') }}</span></p>
          </template>
          <template class='text-right' v-slot:foot(xcertificar)="foot_data_col6">
            <p class='text-right'><span class="font-size-10 font-weight-bold">S/. {{ totalXCertificado.toLocaleString('en-US') }}</span></p>
          </template>
          <template class='text-right' v-slot:foot(devengado)="foot_data_col4">
            <p class='text-right'><span class="font-size-10 font-weight-bold">S/. {{ totalDEV.toLocaleString('en-US') }}</span></p>
          </template>
          <template class='text-right' v-slot:foot(xdevengar)="foot_data_col4">
            <p class='text-right'><span class="font-size-10 font-weight-bold">S/. {{ totalXDEV.toLocaleString('en-US') }}</span></p>
          </template>
          <template class='text-right' v-slot:foot(xdevengar_m)="foot_data_col4">
            <p class='text-right'><span class="font-size-10 font-weight-bold">S/. {{ totalXDEVM.toLocaleString('en-US') }}</span></p>
          </template>
          <template class='text-right' v-slot:foot(girado)="foot_data_col4">
            <p class='text-right'><span class="font-size-10 font-weight-bold">S/. {{ totalGirado.toLocaleString('en-US') }}</span></p>
          </template>
          <template class='text-right' v-slot:foot(avance)="foot_data_col5">
            <p class='text-right'><span class="font-size-10 font-weight-bold"></span></p>
          </template>
        </b-table>
      </template>
    </iq-card>
    <b-modal id="mdlFichaSSI" size="xl" title="Sistema de Seguimiento de Inversiones - SSI Invierte.Pe" cancel-title="Cerrar">
      <iframe style="width:100%" :src="urlFichaSSI" class="vh-100" frameborder="0" scrolling="auto" allowtransparency="true"></iframe>
    </b-modal>
    <b-modal id="mdlFichaProdProy" size="lg" title="Ficha Banco de Proyectos - Invierte.Pe" cancel-title="Cerrar">
      <iframe style="width:100%" :src="urlFichaProdProy" class="vh-100" frameborder="0" scrolling="auto" allowtransparency="true"></iframe>
    </b-modal>
    <b-modal id="mdlFicha12B" size="xl" title="FICHA FORMATO Nº 12B" cancel-title="Cerrar">
      <iframe style="width:100%" :src="urlFicha12B" class="vh-100" frameborder="0" scrolling="auto" allowtransparency="true"></iframe>
    </b-modal>
  </div>
</template>
<script>
export default {
  name: 'ReportProdProy',
  components: {},
  mounted () {
    // this.loadData()
  },
  data () {
    return {
      AnioSel: 0,
      ActProySel: '',
      tipoSemaforo: 'FIJO',
      EjecutoraSel: '1331',
      urlFichaSSI: '',
      urlFichaProdProy: '',
      urlFicha12B: '',
      listProdProy: [],
      iconsProdProy: ['ri-window-line', 'ri-briefcase-3-line', 'ri-calendar-line', 'ri-computer-line', 'ri-file-line', 'ri-draft-line', 'ri-file-text-line', 'ri-file-copy-line', 'ri-task-line', 'ri-file-copy-2-line', 'ri-todo-line', 'ri-article-line', 'ri-booklet-line', 'ri-file-shield-2-line', 'ri-refund-line', 'ri-checkbox-line'],
      sortByPEG: 'avance',
      sortDescPEG: true,
      tablePliegosEG: [],
      fieldsPliegosEG: [
        {
          key: 'index',
          label: 'Posición',
          sortable: true,
          tdClass: 'd-none',
          thClass: 'd-none',
          visible: false,
          disabledF: true,
          hiddenF: true
        },
        {
          key: 'pliego',
          label: 'Proyecto de Inversión',
          sortable: false,
          tdClass: 'pt-0 pb-0 align-middle w500-lae text-justify',
          thClass: 'w500-lae',
          visible: true,
          disabledF: true,
          hiddenF: false
        },
        {
          key: 'beneficiarios',
          label: 'Benef.',
          sortable: true,
          tdClass: 'pt-0 pb-0 align-middle',
          thClass: 'text-right',
          visible: true
        },
        {
          key: 'costoTotalActualizado',
          label: 'Costo',
          sortable: true,
          tdClass: 'pt-0 pb-0 align-middle',
          thClass: 'text-right',
          visible: true
        },
        {
          key: 'pia',
          label: 'Pia',
          sortable: true,
          tdClass: 'pt-0 pb-0 align-middle',
          thClass: 'text-right',
          visible: false
        },
        {
          key: 'pim',
          label: 'Pim',
          sortable: true,
          tdClass: 'pt-0 pb-0 align-middle',
          thClass: 'text-right',
          visible: true
        },
        {
          key: 'certificacion',
          label: 'Certificación',
          sortable: true,
          tdClass: 'pt-0 pb-0 align-middle',
          thClass: 'text-right',
          visible: false
        },
        {
          key: 'compromiso_a',
          label: 'Compromiso Anual',
          sortable: true,
          tdClass: 'pt-0 pb-0 align-middle',
          thClass: 'text-right',
          visible: false
        },
        {
          key: 'compromiso_m',
          label: 'Compromiso Mensual',
          sortable: true,
          tdClass: 'pt-0 pb-0 align-middle',
          thClass: 'text-right',
          visible: false
        },
        {
          key: 'xcertificar',
          label: 'Por Certificar',
          sortable: true,
          tdClass: 'pt-0 pb-0 align-middle',
          thClass: 'text-right',
          visible: false
        },
        {
          key: 'devengado',
          label: 'Devengado',
          sortable: true,
          tdClass: 'pt-0 pb-0 align-middle',
          thClass: 'text-right',
          visible: false
        },
        {
          key: 'xdevengar',
          label: 'Por Devengar',
          sortable: true,
          tdClass: 'pt-0 pb-0 align-middle',
          thClass: 'text-right',
          visible: true
        },
        {
          key: 'xdevengar_m',
          label: 'Por Devengar Mes',
          sortable: true,
          tdClass: 'pt-0 pb-0 align-middle',
          thClass: 'text-right',
          visible: false
        },
        {
          key: 'girado',
          label: 'Girado',
          sortable: true,
          tdClass: 'pt-0 pb-0 align-middle',
          thClass: 'text-right',
          visible: false
        },
        {
          key: 'avance',
          label: 'Avance',
          sortable: true,
          tdClass: 'pt-0 pb-0 align-middle',
          thClass: 'text-center',
          visible: true
        }
      ],
      totalPIA: 0,
      totalPIM: 0,
      totalCertificado: 0,
      totalXCertificado: 0,
      totalDEV: 0,
      totalDEVAnt: 0,
      totalXDEV: 0,
      totalXDEVM: 0,
      totalCompromisoA: 0,
      totalCompromisoM: 0,
      totalGirado: 0,
      totalAvance: 0,
      totalBeneficiarios: 0,
      totalCosto: 0
    }
  },
  methods: {
    getNumbertoMoney (num, symbol) {
      return symbol + ' ' + Number(num).toLocaleString('en-US')
    },
    compareCurrency (itemA, itemB, key) {
      if (key !== 'pim' && key !== 'pia' && key !== 'devengado' && key !== 'certificacion') {
        return false
      } else {
        let a = itemA[key]
        let b = itemB[key]
        a = a.replace('S/. ', '')
        a = a.replace(/,/g, '')
        b = b.replace('S/. ', '')
        b = b.replace(/,/g, '')
        return a - b
      }
    },
    getColorSemaforo (avance, format) {
      let color = 'success'
      if (this.tipoSemaforo === 'FIJO') {
        if (avance >= 50) {
          color = 'success'
        } else if (avance >= 33 && avance < 50) {
          color = 'warning'
        } else {
          color = 'danger'
        }
      } else {
        let diasFeb = new Date(this.anioV, 2, 0).getDate()
        let diaActualAnio = Math.ceil((this.fechaHoy - new Date(this.fechaHoy.getFullYear(), 0, 1)) / 86400000) - 1
        let diasAnio = 365
        if (diasFeb === 29) {
          diasAnio = 366
        }
        let porcDiario = 100 / diasAnio
        let porcIdeal = diaActualAnio * porcDiario
        let valueDifference = avance - porcIdeal

        if (valueDifference >= 0) {
          color = 'success'
        } else {
          if (valueDifference > -6) {
            color = 'warning'
          } else {
            color = 'danger'
          }
        }
      }
      if (format === 'hex') {
        color = this.colorClstoHex(color)
      }
      return color
    },
    getLinkFichaSSI (codigoDnpp) {
      this.urlFichaSSI = 'https://ofi5.mef.gob.pe/ssi/Ssi/Index?codigo=' + codigoDnpp + '&tipo=2'
      this.$bvModal.show('mdlFichaSSI')
    },
    getLinkFicha (codigoDnpp) {
      this.urlFichaProdProy = 'https://ofi5.mef.gob.pe/invierte/formato/verInversion/' + codigoDnpp + '?tk=x4lyuMN02pl5Tz0vSEs9lm8Qr'
      this.$bvModal.show('mdlFichaProdProy')
    },
    getLinkFicha12B (codigoDnpp) {
      this.urlFicha12B = 'https://ofi5.mef.gob.pe/repseguim/proyinv09.html?codigo=' + codigoDnpp
      this.$bvModal.show('mdlFicha12B')
    },
    async loadData () {
      let that = this
      that.listProdProy = []
      that.tableUESEG = []
      that.egUES = []
      that.tablePliegosEG = []
      that.totalPIA = 0
      that.totalPIM = 0
      that.totalCertificado = 0
      that.totalXCertificado = 0
      that.totalDEV = 0
      that.totalDEVAnt = 0
      that.totalXDEV = 0
      that.totalXDEVM = 0
      that.totalCompromisoA = 0
      that.totalCompromisoM = 0
      that.totalGirado = 0
      that.totalAvance = 0
      that.totalBeneficiarios = 0
      that.totalCosto = 0

      await this.$store
        .dispatch('gsplanifica/REPORTES_GETPRODPROY', { anio: that.AnioSel, actProy: that.ActProySel, codSeccion: that.EjecutoraSel, seccion: 'ejecutora', limit: 100, modulo: 'diaria' })
        .then(res => {
          if (res !== undefined) {
            res.forEach(function (prodproy) {
              if (prodproy.codigoDnpp !== '2000270' && prodproy.codigoDnpp !== '3999999') {
                that.totalPIA = that.totalPIM + prodproy.listaEG[0].pia
                that.totalPIM = that.totalPIM + prodproy.listaEG[0].pim
                that.totalCertificado = that.totalCertificado + prodproy.listaEG[0].certificacion
                that.totalCompromisoA = that.totalCompromisoA + prodproy.listaEG[0].compromisoAnual
                that.totalCompromisoM = that.totalCompromisoM + prodproy.listaEG[0].atencionCompromisoMensual
                that.totalXCertificado = that.totalXCertificado + (prodproy.listaEG[0].pim - prodproy.listaEG[0].certificacion)
                that.totalDEV = that.totalDEV + prodproy.listaEG[0].devengado
                if (prodproy.listaEGAnt) {
                  that.totalDEVAnt = that.totalDEVAnt + prodproy.listaEGAnt[0].devengado
                }
                that.totalXDEV = that.totalXDEV + (prodproy.listaEG[0].pim - prodproy.listaEG[0].devengado)
                that.totalXDEVM = that.totalXDEV + (prodproy.listaEG[0].atencionCompromisoMensual - prodproy.listaEG[0].devengado)
                that.totalGirado = that.totalGirado + prodproy.listaEG[0].girado
                that.totalAvance = that.totalAvance + prodproy.listaEG[0].avance
                that.totalBeneficiarios = that.totalBeneficiarios + prodproy.beneficiarios
                that.totalCosto = that.totalCosto + prodproy.costoTotalActualizado

                let xcert = prodproy.listaEG[0].pim - prodproy.listaEG[0].certificacion
                let xdevengar = prodproy.listaEG[0].pim - prodproy.listaEG[0].devengado
                let xdevengarm = prodproy.listaEG[0].atencionCompromisoMensual - prodproy.listaEG[0].devengado

                let colorSema = that.getColorSemaforo(prodproy.listaEG[0].avance, 'cls')
                that.tablePliegosEG.push({
                  pliego: { color: colorSema, codigo: prodproy.codigoDnpp, nombre: prodproy.nombreDnpp },
                  beneficiarios: prodproy.beneficiarios,
                  costoTotalActualizado: prodproy.costoTotalActualizado ? 'S/. ' + prodproy.costoTotalActualizado?.toLocaleString('en-US') : '',
                  pia: 'S/. ' + prodproy.listaEG[0].pia.toLocaleString('en-US'),
                  pim: 'S/. ' + prodproy.listaEG[0].pim.toLocaleString('en-US'),
                  certificacion: 'S/. ' + prodproy.listaEG[0].certificacion.toLocaleString('en-US'),
                  xcertificar: xcert,
                  devengado: 'S/. ' + prodproy.listaEG[0].devengado.toLocaleString('en-US'),
                  xdevengar: xdevengar,
                  compromiso_a: prodproy.listaEG[0].compromisoAnual,
                  compromiso_m: prodproy.listaEG[0].atencionCompromisoMensual,
                  girado: prodproy.listaEG[0].girado,
                  xdevengar_m: xdevengarm,
                  avance: { color: 'danger', count: prodproy.listaEG[0].avance }
                  // _rowVariant: rowVariant
                })
                let iconR = that.iconsProdProy[Math.floor(Math.random() * that.iconsProdProy.length)]
                let colorS = that.getColorSemaforo(prodproy.listaEG[0].avance, 'cls')
                that.listProdProy.push({ codigoDnpp: prodproy.codigoDnpp, nombreDnpp: prodproy.nombreDnpp, listaEG: prodproy.listaEG, icon: iconR, color: colorS })
              }
            })
            that.$emit('enviar-valores', { totalBeneficiarios: that.totalBeneficiarios, totalCosto: that.totalCosto, totalXDEV: that.totalXDEV, totalDEV: that.totalDEV, totalDEVAnt: that.totalDEVAnt })
          }
        })
        .catch(error => {
          console.log('Ha ocurrido un error: ', error)
        })
    }
  },
  computed: {
    visibleFields () {
      return this.fieldsPliegosEG.filter(field => field.visible)
    }
  }
}
</script>
<style>
.custom-control-label{
  text-wrap: nowrap !important;
}
.w500-lae{
  max-width: 500px !important;
}
</style>
