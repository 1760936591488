import unidadEjecutoraService from '@/services/GSPlanifica/unidadEjecutora'

export default {
  'UNIDAD_EJECUTORA_FIND_ALL': ({ commit }, params) => {
    return new Promise((resolve, reject) => {
      unidadEjecutoraService.findAll().then(res => {
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  }
}
