import EjecucionPresupuestalService from '@/services/Sirsam/ejecucionPresupuestal'

export default {
  'FIND_ALL_ANIO': (_, params) => {
    return new Promise((resolve, reject) => {
      EjecucionPresupuestalService.findAllAnio(params).then(res => {
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  },
  'FIND_ALL_EXPEDIENTE_SIAF': (_, params) => {
    return new Promise((resolve, reject) => {
      EjecucionPresupuestalService.findAllExpedienteSiaf(params).then(res => {
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  },
  'FIND_ALL_EXPEDIENTE_SIAF_DETALLE_FASE': (_, params) => {
    return new Promise((resolve, reject) => {
      EjecucionPresupuestalService.findAllExpedienteSiafDetalleFase(params).then(res => {
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  },
  /* REPORTE 02 */
  'FIND_ALL_ANIO_RESUMEN': (_, params) => {
    return new Promise((resolve, reject) => {
      EjecucionPresupuestalService.findAllAnioResumen(params).then(res => {
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  },
  'FIND_ALL_GRAFICO_AVANCE_PRESUPUESTAL': (_, params) => {
    return new Promise((resolve, reject) => {
      EjecucionPresupuestalService.findAllGraficoAvancePresupuestal(params).then(res => {
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  },
  'FIND_ALL_GRAFICO_AVANCE_PRESUPUESTAL_PROYECTO': (_, params) => {
    return new Promise((resolve, reject) => {
      EjecucionPresupuestalService.findAllGraficoAvancePresupuestalProyecto(params).then(res => {
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  },
  'FIND_ALL_GRAFICO_AVANCE_PRESUPUESTAL_PRODUCTO': (_, params) => {
    return new Promise((resolve, reject) => {
      EjecucionPresupuestalService.findAllGraficoAvancePresupuestalProducto(params).then(res => {
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  },
  'FIND_ALL_GRAFICO_AVANCE_PRESUPUESTAL_MENSUALIZADA': (_, params) => {
    return new Promise((resolve, reject) => {
      EjecucionPresupuestalService.findAllGraficoAvancePresupuestalMensualizada(params).then(res => {
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  },
  'FIND_ALL_GRAFICO_AVANCE_PRESUPUESTAL_MENSUALIZADA_PROYECTO': (_, params) => {
    return new Promise((resolve, reject) => {
      EjecucionPresupuestalService.findAllGraficoAvancePresupuestalMensualizadaProyecto(params).then(res => {
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  },
  'FIND_ALL_GRAFICO_AVANCE_PRESUPUESTAL_MENSUALIZADA_PRODUCTO': (_, params) => {
    return new Promise((resolve, reject) => {
      EjecucionPresupuestalService.findAllGraficoAvancePresupuestalMensualizadaProducto(params).then(res => {
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  },
  'FIND_ALL_TABLA_AVANCE_PRESUPUESTAL_PROYECTO': (_, params) => {
    return new Promise((resolve, reject) => {
      EjecucionPresupuestalService.findAllTablaAvancePresupuestalProyecto(params).then(res => {
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  },
  'FIND_ALL_TABLA_AVANCE_PRESUPUESTAL_GENERICA': (_, params) => {
    return new Promise((resolve, reject) => {
      EjecucionPresupuestalService.findAllTablaAvancePresupuestalGenerica(params).then(res => {
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  },
  'FIND_ALL_TABLA_AVANCE_PRESUPUESTAL_FUENTE': (_, params) => {
    return new Promise((resolve, reject) => {
      EjecucionPresupuestalService.findAllTablaAvancePresupuestalFuente(params).then(res => {
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  },
  'FIND_ALL_TABLA_AVANCE_PRESUPUESTAL_CATEGORIA': (_, params) => {
    return new Promise((resolve, reject) => {
      EjecucionPresupuestalService.findAllTablaAvancePresupuestalCategoria(params).then(res => {
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  }
}
