<template>
  <iq-card id="DivClasPy">
      <template v-slot:headerTitle>
        <h4 class="card-title">Detalle de Gasto de Gestión Administrativa de Proyectos</h4>
      </template>
      <b-row>
        <b-col md="6">
          <b-row>
            <b-col md="6" sm="6" v-for="( data, index ) in dataClas" :key="`claspy-${index}`">
              <iq-card>
                <template v-slot:body>
                  <div>
                      <h6 class="font-size-10 font-weight-bold">{{ data.clasificadorGasto.nombre.trim() }}</h6>
                      <h5>{{ data.clasificadorGasto.clasificador }}</h5>
                    </div>
                    <div class="iq-customer-box d-flex align-items-center justify-content-between mt-3">
                      <div class="d-flex align-items-center">
                          <h2>{{ data.listaEG[0].avance }} %</h2>
                      </div>
                      <div class="iq-map text-primary font-size-32"><i class="ri-bar-chart-grouped-line"></i></div>
                    </div>
                    <div>
                      <b-progress :value="data.listaEG[0].avance" class="iq-progress-bar" :variant="getColorSemaforo(data.listaEG[0].avance, 'cls')"></b-progress>
                    </div>
                </template>
              </iq-card>
            </b-col>
          </b-row>
        </b-col>
        <b-col md="6">
          <b-row>
            <b-col md="6" sm="6">
              <iq-card>
                <template v-slot:body>
                  <div>
                      <h6 class="font-size-10 font-weight-bold">TOTAL</h6>
                      <h5>BENEFICIARIOS</h5>
                    </div>
                    <div class="iq-customer-box d-flex align-items-center justify-content-between mt-3">
                      <div class="d-flex align-items-center">
                          <h2>{{ totales.totalBeneficiarios }} UND</h2>
                      </div>
                      <div class="iq-map text-primary font-size-32"><i class="ri-user-heart-line"></i></div>
                    </div>
                    <div>
                      <!-- <b-progress :value="data.listaEG[0].avance" class="iq-progress-bar" :variant="getColorSemaforo(data.listaEG[0].avance, 'cls')"></b-progress> -->
                    </div>
                </template>
              </iq-card>
            </b-col>
            <b-col md="6" sm="6">
              <iq-card>
                <template v-slot:body>
                  <div>
                      <h6 class="font-size-10 font-weight-bold">TOTAL</h6>
                      <h5>COSTO</h5>
                    </div>
                    <div class="iq-customer-box d-flex align-items-center justify-content-between mt-3">
                      <div class="d-flex align-items-center">
                          <h2>S/. {{ totales.totalCosto.toLocaleString('en-US') }}</h2>
                      </div>
                      <div class="iq-map text-primary font-size-32"><i class="ri-calculator-line"></i></div>
                    </div>
                    <div>
                      <!-- <b-progress :value="data.listaEG[0].avance" class="iq-progress-bar" :variant="getColorSemaforo(data.listaEG[0].avance, 'cls')"></b-progress> -->
                    </div>
                </template>
              </iq-card>
            </b-col>
            <b-col md="6" sm="6">
              <iq-card>
                <template v-slot:body>
                  <div>
                      <h6 class="font-size-10 font-weight-bold">TOTAL</h6>
                      <h5>POR DEVENGAR</h5>
                    </div>
                    <div class="iq-customer-box d-flex align-items-center justify-content-between mt-3">
                      <div class="d-flex align-items-center">
                          <h2>S/. {{ totales.totalXDEV.toLocaleString('en-US') }}</h2>
                      </div>
                      <div class="iq-map text-primary font-size-32"><i class="ri-article-line"></i></div>
                    </div>
                    <div>
                      <!-- <b-progress :value="data.listaEG[0].avance" class="iq-progress-bar" :variant="getColorSemaforo(data.listaEG[0].avance, 'cls')"></b-progress> -->
                    </div>
                </template>
              </iq-card>
            </b-col>
            <b-col md="6" sm="6">
              <iq-card>
                <template v-slot:body>
                  <div>
                      <h6 class="font-size-10 font-weight-bold">ÚLTIMO INCREMENTO</h6>
                      <h5>DEVENGADO</h5>
                    </div>
                    <div class="iq-customer-box d-flex align-items-center justify-content-between mt-3">
                      <div class="d-flex align-items-center">
                          <h2>S/. {{ (totales.totalDEV - totales.totalDEVAnt).toLocaleString('en-US') }}</h2>
                      </div>
                      <div class="iq-map text-primary font-size-32"><i class="ri-24-hours-line"></i></div>
                    </div>
                    <div>
                      <!-- <b-progress :value="data.listaEG[0].avance" class="iq-progress-bar" :variant="getColorSemaforo(data.listaEG[0].avance, 'cls')"></b-progress> -->
                    </div>
                </template>
              </iq-card>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
  </iq-card>
</template>
<script>
export default {
  name: 'DetailOtherPy',
  components: {},
  props: {
    DataClasPy: {
      type: Array,
      required: true
    }
  },
  mounted () {
    this.loadData()
  },
  data () {
    return {
      dataClas: [],
      tipoSemaforo: 'FIJO',
      totales: {
        totalBeneficiarios: 0,
        totalCosto: 0,
        totalXDEV: 0,
        totalDEV: 0,
        totalDEVAnt: 0
      }
    }
  },
  methods: {
    async loadData () {
      let that = this
      that.dataClas = []
      that.DataClasPy.forEach(function (clas) {
        if (clas.clasificadorGasto.clasificador.includes('2.6.8 1.4')) {
          that.dataClas.push(clas)
        }
      })
    },
    getColorSemaforo (avance, format) {
      let color = 'success'
      if (this.tipoSemaforo === 'FIJO') {
        if (avance >= 50) {
          color = 'success'
        } else if (avance >= 33 && avance < 50) {
          color = 'warning'
        } else {
          color = 'danger'
        }
      } else {
        let diasFeb = new Date(this.anioV, 2, 0).getDate()
        let diaActualAnio = Math.ceil((this.fechaHoy - new Date(this.fechaHoy.getFullYear(), 0, 1)) / 86400000) - 1
        let diasAnio = 365
        if (diasFeb === 29) {
          diasAnio = 366
        }
        let porcDiario = 100 / diasAnio
        let porcIdeal = diaActualAnio * porcDiario
        let valueDifference = avance - porcIdeal

        if (valueDifference >= 0) {
          color = 'success'
        } else {
          if (valueDifference > -6) {
            color = 'warning'
          } else {
            color = 'danger'
          }
        }
      }
      if (format === 'hex') {
        color = this.colorClstoHex(color)
      }
      return color
    }
  }
}
</script>

<style>
[dir=ltr] h2 {
  font-size: 1.8em !important;
}
</style>
