import apiCall from '../index.js'
const apiService = '/sirsam/api/v1/presupuesto/getEjecutora'

const service = {
  findAllEjecutora (params) {
    return new Promise((resolve, reject) => {
      apiCall.get(apiService).then(res => resolve(res.data)).catch(err => reject(err))
    })
  }
}

export default service
