// import actions
import reportesAction from './actions/reportes'
import funcionarioAction from './actions/funcionario'
import unidadEjecutoraAction from './actions/unidadEjecutora'
import proyectoAction from './actions/proyecto'

// import getters
import reportesGetter from './getters/reportes'
import funcionarioGetter from './getters/funcionario'
import unidadEjecutoraGetter from './getters/unidadEjecutora'
import proyectoGetter from './getters/proyecto'

// import mutations
import reportesMutation from './mutations/reportes'
import funcionarioMutation from './mutations/funcionario'
import unidadEjecutoraMutation from './mutations/unidadEjecutora'
import proyectoMutation from './mutations/proyecto'

// import states
import reportesState from './states/reportes'
import funcionarioState from './states/funcionario'
import unidadEjecutoraState from './states/unidadEjecutora'
import proyectoState from './states/proyecto'

const actions = Object.assign({}, reportesAction, funcionarioAction, unidadEjecutoraAction, proyectoAction)

const getters = Object.assign({}, reportesGetter, funcionarioGetter, unidadEjecutoraGetter, proyectoGetter)

const mutations = Object.assign({}, reportesMutation, funcionarioMutation, unidadEjecutoraMutation, proyectoMutation)

const state = Object.assign({}, reportesState, funcionarioState, unidadEjecutoraState, proyectoState)

export default {
  namespaced: true,
  actions,
  state,
  getters,
  mutations
}
