<template>
  <b-container fluid>
    <b-row>
      <b-col md="12">
        <iq-card>
          <div class="d-flex align-items-center justify-content-between pl-2 pr-3">
            <div class="d-flex align-items-center">
              <h4 class="mb-0 p-3">{{ page.name }}</h4><br />
              <b-breadcrumb :items="page.breadCrumb" class="bg-transparent mb-0 mt-1" />
            </div>
            <div class="d-flex justify-content-between">
              <div class="d-flex">
                <v-select name="ftr_anio" class="mr-2" v-model="AnioSel" :options=ftrAnios :clearable="false" @input="chgSelect" />
                <v-select name="ftr_actproy" class="mr-2" v-model="ActProySel"  :options=ftrActProy :clearable="false" @input="chgSelect" />
                <b-button variant="primary" class=""><i class="ri-calendar-line" />{{ fechaWS }}</b-button>
              </div>
            </div>
          </div>
        </iq-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="8">
        <iq-card class-name="overflow-hidden">
          <template v-slot:headerTitle>
            <h4 class="card-title">Ranking Gobiernos Regionales</h4>
          </template>
          <template v-slot:headerAction>
            <b-dropdown id="dropdownMenuButton1" right variant="none" data-toggle="dropdown">
              <template v-slot:button-content>
                <i class="ri-more-fill"></i>
              </template>
              <b-dropdown-item @click="downloadXls('P')"><i class="ri-file-excel-line mr-2 align-middle"></i> Descargar en Excel</b-dropdown-item>
              <b-dropdown-item @click="shareWtp('P')"><i class="ri-whatsapp-line mr-2 align-middle"></i> Compartir por Whatsapp</b-dropdown-item>
            </b-dropdown>
          </template>
          <template v-slot:body>
            <b-table hover striped :items="tablePliegosEG" :fields="fieldsPliegosEG" class="mb-0 table-borderless p-0" :sort-compare="compareCurrency" :sort-by.sync="sortByPEG" :sort-desc.sync="sortDescPEG" foot-clone>
                  <template #cell(index)="data">
                    <b-badge pill variant="dark">{{ data.index + 1 }}</b-badge>
                  </template>
                  <template v-slot:cell(pliego)="data">
                    <b-badge pill variant="dark">{{ data.index + 1 }}</b-badge>
                    <b-badge pill :variant="data.value.color" class="ml-1">{{ data.value.codigo }}</b-badge>
                    <span class="font-size-9 pl-1">{{ data.value.nombre }}</span>
                  </template>
                  <template v-slot:cell(pim)="data_pim">
                    <p class='text-right mb-0'><span class="font-size-10">{{ data_pim.value }}</span></p>
                  </template>
                  <template v-slot:cell(certificacion)="data_certificacion">
                    <p class='text-right mb-0'><span class="font-size-10">{{ data_certificacion.value }}</span></p>
                  </template>
                  <template v-slot:cell(devengado)="data_dev">
                    <p class='text-right mb-0'><span class="font-size-10">{{ data_dev.value }}</span></p>
                  </template>
                  <template v-slot:cell(avance)="data1">
                    <center><span class="font-size-10 font-weight-bold">{{ data1.value.count }}%</span></center>
                    <b-progress :value="data1.value.count" class="iq-progress-bar" :variant="getColorSemaforo(data1.value.count, 'cls')">
                    </b-progress>
                  </template>
                  <template class='text-right' v-slot:foot(pliego)="foot_data_col1">
                    <p class='text-right'><span>Total</span></p>
                  </template>
                  <template class='text-right' v-slot:foot(pim)="foot_data_col3">
                    <p class='text-right'><span class="font-size-10 font-weight-bold">S/.{{ totalPIMPliegos.toLocaleString('en-US') }}</span></p>
                  </template>
                  <template class='text-right' v-slot:foot(certificacion)="foot_data_col2">
                    <p class='text-right'><span class="font-size-10 font-weight-bold">S/.{{ totalCertificadoPliegos.toLocaleString('en-US') }}</span></p>
                  </template>
                  <template class='text-right' v-slot:foot(devengado)="foot_data_col4">
                    <p class='text-right'><span class="font-size-10 font-weight-bold">S/.{{ totalDEVPliegos.toLocaleString('en-US') }}</span></p>
                  </template>
                  <template class='text-right' v-slot:foot(avance)="foot_data_col5">
                    <p class='text-right'><span class="font-size-10 font-weight-bold">{{ totalAvancePliegos.toLocaleString('en-US') }}%</span></p>
                  </template>
                </b-table>
          </template>
        </iq-card>
        <b-row>
          <b-col md="6">
            <iq-card>
              <template v-slot:headerTitle>
                <h4 class="card-title">Clasificadores</h4>
              </template>
              <template v-slot:headerAction>
                <b-dropdown id="dropdownMenuButton1" right variant="none" data-toggle="dropdown">
                  <template v-slot:button-content>
                    <i class="ri-more-fill"></i>
                  </template>
                  <b-dropdown-item v-on:click="getReport($event)" id="rptPliego"><i class="ri-pie-chart-line mr-2"></i>Reporte Detallado</b-dropdown-item>
                </b-dropdown>
              </template>
              <template v-slot:body>
                <div id="divChtClas"></div>
                <div class="text-center"><b-button type="button" variant="light" class="mb-3" v-on:click="getReport($event)" id="rptPliego"><i class="ri-pie-chart-line"></i>Reporte Detallado</b-button></div>
              </template>
            </iq-card>
          </b-col>
          <b-col md="6">
            <iq-card>
              <template v-slot:headerTitle>
                <h4 class="card-title">Fuente Financiamiento</h4>
              </template>
              <template v-slot:body>
                <div id="divChtFF"></div>
                <div class="text-center"><b-button type="button" variant="light" class="mb-3" disabled><i class="ri-bar-chart-fill"></i>Otros Reportes</b-button></div>
              </template>
            </iq-card>
          </b-col>
        </b-row>
      </b-col>
      <b-col md="4">
        <iq-card>
          <template v-slot:body>
            <div id="divChtREG"></div>
            <b-row>
              <b-col md="12" class="text-center">
                <div class="iq-card-body p-0">
                  <div class="text-center">
                    <span>LEYENDA</span>
                  </div>
                </div>
                <b-badge pill variant="success" class="mr-1">{{ leyendaS }}</b-badge>
                <b-badge pill variant="warning" class="mr-1">{{ leyendaW }}</b-badge>
                <b-badge pill variant="danger">{{ leyendaD }}</b-badge>
              </b-col>
            </b-row>
          </template>
        </iq-card>
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">Proyectos</h4>
          </template>
          <template v-slot:body>
            <ul class="suggestions-lists m-0 p-0">
              <li v-for="( prodproy,index ) in listProdProy" :key="index" class="d-flex mb-2 align-items-center">
                <div :class="'profile-icon bg-' + prodproy.color"><span><i :class="prodproy.icon"></i></span></div>
                <div class="media-support-info ml-3">
                    <h6>{{ prodproy.codigoDnpp }}</h6>
                    <p class="mb-0 font-size-9 text-justify">{{ prodproy.nombreDnpp }}</p>
                    <p class="mt-1 mb-0">
                      <b-button size="sm" v-b-tooltip.top="'Ver Ficha'" variant="light" class="mr-1 d-inline" v-on:click="getLinkFicha(prodproy.codigoDnpp)"><i class="ri-link"></i></b-button>
                      <b-button size="sm" v-b-tooltip.top="'Reporte Clasificadores'" variant="light" class="mr-1 d-inline"><i class="ri-line-chart-line"></i></b-button>
                    </p>
                </div>
                <div class="media-support-amount ml-3">
                    <h6 :class="'text-' + prodproy.color">{{ prodproy.listaEG[0].avance }}</h6>
                    <p class="mb-0"></p>
                </div>
              </li>
            </ul>
          </template>
        </iq-card>
      </b-col>
      <b-col md="12" sm="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">Linea de Tiempo de la Ejecución de Gasto (Últimos 30 días)</h4>
          </template>
          <template v-slot:body>
            <div id="divChtLTEG"></div>
          </template>
        </iq-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="12">
        <iq-card class-name="overflow-hidden">
          <template v-slot:headerTitle>
            <h4 class="card-title">Unidades Ejecutoras</h4>
          </template>
          <template v-slot:headerAction>
            <b-dropdown id="dropdownMenuButton1" right variant="none" data-toggle="dropdown">
              <template v-slot:button-content>
                 <i class="ri-more-fill"></i>
              </template>
              <b-dropdown-group id="dropdown-group-1" header="Columnas" class="pl-0">
                <b-dropdown-form>
                  <b-form-checkbox class="mb-3" switch>Pia</b-form-checkbox>
                  <b-form-checkbox class="mb-3" switch>Pim</b-form-checkbox>
                  <b-form-checkbox class="mb-3" switch>Certificación</b-form-checkbox>
                </b-dropdown-form>
              </b-dropdown-group>
              <b-dropdown-item @click="downloadXls('UE')"><i class="ri-file-excel-line mr-2 align-middle"></i> Descargar en Excel</b-dropdown-item>
              <b-dropdown-item @click="shareWtp('UE')"><i class="ri-whatsapp-line mr-2 align-middle"></i> Compartir por Whatsapp</b-dropdown-item>
            </b-dropdown>
          </template>
          <template v-slot:body>
            <b-table hover striped :items="tableUESEG" :fields="fieldsUESEG" class="mb-0 table-borderless p-0" :sort-compare="compareCurrency" :sort-by.sync="sortByUEEG" :sort-desc.sync="sortDescUEEG" foot-clone>
              <template #cell(index)="data">
                <b-badge pill variant="dark">{{ data.index + 1 }}</b-badge>
              </template>
              <template v-slot:cell(ue)="data">
                <b-badge pill variant="dark">{{ data.index + 1 }}</b-badge>
                <b-badge class="ml-1" pill :variant="data.value.color">{{ data.value.codigo }}</b-badge>
                <span class="font-size-9 pl-1">{{ getNombreEjecuctora(data.value.nombre) }}</span>
              </template>
              <template v-slot:cell(pim_ue)="data_ue_pim">
                <p class='text-right mb-0'><span class="font-size-10">{{ getNumbertoMoney(data_ue_pim.value, 'S/.') }}</span></p>
              </template>
              <template v-slot:cell(certificado_ue)="data_ue_certificado">
                <p class='text-right mb-0'><span class="font-size-10">{{ getNumbertoMoney(data_ue_certificado.value, 'S/.') }}</span></p>
              </template>
              <template v-slot:cell(xcertificar_ue)="data_ue_xcert">
                <p class='text-right mb-0'><span class="font-size-10">{{ getNumbertoMoney(data_ue_xcert.value, 'S/.') }}</span></p>
              </template>
              <template v-slot:cell(devengado_ue)="data_ue_dev">
                <p class='text-right mb-0'><span class="font-size-10">{{ getNumbertoMoney(data_ue_dev.value, 'S/.') }}</span></p>
              </template>
              <template v-slot:cell(xdevengar_ue)="data_ue_xdev">
                <p class='text-right mb-0'><span class="font-size-10">{{ getNumbertoMoney(data_ue_xdev.value, 'S/.') }}</span></p>
              </template>
              <template v-slot:cell(avance_ue)="data_ue_avance">
                <center><span class="font-size-10 font-weight-bold">{{ data_ue_avance.value.count }}%</span></center>
                <b-progress :value="data_ue_avance.value.count" class="iq-progress-bar" :variant="getColorSemaforo(data_ue_avance.value.count, 'cls')">
                </b-progress>
              </template>
              <template v-slot:cell(opera_ue)="environment">
                <b-button size="sm" variant="primary" @click="getMdlUES(environment.item)" v-b-tooltip.top="'Clasificadores'"><i class="ri-file-chart-line"></i></b-button>
              </template>
              <template class='text-right' v-slot:foot(ue)="foot_data_col1">
                <p class='text-right'><span>Total</span></p>
              </template>
              <template class='text-right' v-slot:foot(pim_ue)="foot_data_col3">
                <p class='text-right'><span class="font-size-10 font-weight-bold">S/. {{ totalPIMUES.toLocaleString('en-US') }}</span></p>
              </template>
              <template class='text-right' v-slot:foot(certificado_ue)="foot_data_col2">
                <p class='text-right'><span class="font-size-10 font-weight-bold">S/. {{ totalCertificadoUES.toLocaleString('en-US') }}</span></p>
              </template>
              <template class='text-right' v-slot:foot(xcertificar_ue)="foot_data_col6">
                <p class='text-right'><span class="font-size-10 font-weight-bold">S/. {{ totalXCertificadoUES.toLocaleString('en-US') }}</span></p>
              </template>
              <template class='text-right' v-slot:foot(devengado_ue)="foot_data_col4">
                <p class='text-right'><span class="font-size-10 font-weight-bold">S/. {{ totalDEVUES.toLocaleString('en-US') }}</span></p>
              </template>
              <template class='text-right' v-slot:foot(xdevengar_ue)="foot_data_col4">
                <p class='text-right'><span class="font-size-10 font-weight-bold">S/. {{ totalXDEVUES.toLocaleString('en-US') }}</span></p>
              </template>
              <template class='text-right' v-slot:foot(avance_ue)="foot_data_col5">
                <p class='text-right'><span class="font-size-10 font-weight-bold">{{ egPliego.avance }}%</span></p>
              </template>
            </b-table>
          </template>
        </iq-card>
      </b-col>
    </b-row>
    <b-modal id="mdlClas" @ok="getOKMdl" size="sm" title="Opciones Reporte" ok-title="Generar" cancel-title="Cerrar">
      <div v-if="TipoReport === 'rptPliego'">
        <b-form-group label="Tipo Reporte" label-for="rpt_from2">
          <v-select id="rpt_from2" name="rpt_from2" class="" v-model="rptFrom2" :options=rptFroms2 :clearable="false" />
        </b-form-group>
        <b-form-group label="Destino" label-for="rpt_from1">
          <v-select id="rpt_from1" name="rpt_from1" class="" v-model="rptFrom" :options=rptFroms :clearable="false" />
        </b-form-group>
        <b-form-group label="Detalle Clasificador" label-for="rpt_DetClas">
          <b-form-checkbox-group
            id="rpt_DetClas"
            v-model="selected_rptPliego"
            :options="options_rptPliego"
            switches
            stacked
          ></b-form-checkbox-group>
        </b-form-group>
      </div>
    </b-modal>
    <b-modal id="mdlClasUE" @ok="getOKMdlUE" size="sm" :title="titleMdlUE" ok-title="Generar" cancel-title="Cerrar">
      <b-form-group label="Destino" label-for="rpt_DestinoUE">
        <v-select id="rpt_DestinoUE" name="rpt_DestinoUE" class="" v-model="rptDestinoUE" :options=rptFroms :clearable="false" />
      </b-form-group>
      <b-form-group label="Detalle Clasificador" label-for="rpt_DetClasUE">
        <b-form-checkbox-group
          id="rpt_DetClasUE"
          v-model="stdDetCUE"
          :options="options_rptPliego"
          switches
          stacked
        ></b-form-checkbox-group>
      </b-form-group>
    </b-modal>
    <b-modal id="mdlRptWeb" size="xl" :title="titleRptWeb" cancel-title="Cerrar">
      <div v-if="isLoading === true" class="text-center">
        <img :src="imgLoading" width="400"/>
      </div>
      <div v-else-if="TipoReport === 'rptPliego' && rptFrom2.id === 'pliego'">
        <b-table striped :items="tableRptWebP" :fields="fieldsRptWebP" class="mb-0 table-borderless p-0" :sort-compare="compareCurrency" foot-clone>
          <template v-slot:cell(rwp_clas)="data_rwp_tit">
            <b-badge pill :variant="data_rwp_tit.value.color">{{ data_rwp_tit.value.clasificador }}</b-badge>
            <span class="font-size-9 pl-1">{{ data_rwp_tit.value.nombre }}</span>
          </template>
          <template v-slot:cell(pim_rwp)="data_rwp_pim">
            <p class='text-right mb-0'><span class="font-size-10">{{ getNumbertoMoney(data_rwp_pim.value, 'S/.') }}</span></p>
          </template>
          <template v-slot:cell(certificado_rwp)="data_rwp_certificado">
            <p class='text-right mb-0'><span class="font-size-10">{{ getNumbertoMoney(data_rwp_certificado.value, 'S/.') }}</span></p>
          </template>
          <template v-slot:cell(xcertificar_rwp)="data_rwp_xcert">
            <p class='text-right mb-0'><span class="font-size-10">{{ getNumbertoMoney(data_rwp_xcert.value, 'S/.') }}</span></p>
          </template>
          <template v-slot:cell(devengado_rwp)="data_rwp_dev">
            <p class='text-right mb-0'><span class="font-size-10">{{ getNumbertoMoney(data_rwp_dev.value, 'S/.') }}</span></p>
          </template>
          <template v-slot:cell(xdevengar_rwp)="data_rwp_xdev">
            <p class='text-right mb-0'><span class="font-size-10">{{ getNumbertoMoney(data_rwp_xdev.value, 'S/.') }}</span></p>
          </template>
          <template v-slot:cell(avance_rwp)="data_rwp_avance">
            <center><span class="font-size-10 font-weight-bold">{{ data_rwp_avance.value.count }}%</span></center>
            <b-progress :value="data_rwp_avance.value.count" class="iq-progress-bar" :variant="getColorSemaforo(data_rwp_avance.value.count, 'cls')">
            </b-progress>
          </template>
          <template class='text-right' v-slot:foot(rwp_clas)="foot_data_col1">
            <p class='text-right'><span>Total</span></p>
          </template>
          <template class='text-right' v-slot:foot(pim_rwp)="foot_data_col3">
            <p class='text-right'><span class="font-size-10 font-weight-bold text-nowrap">S/. {{ egPliego.pim.toLocaleString('en-US') }}</span></p>
          </template>
          <template class='text-right' v-slot:foot(certificado_rwp)="foot_data_col2">
            <p class='text-right'><span class="font-size-10 font-weight-bold text-nowrap">S/. {{ egPliego.certificacion.toLocaleString('en-US') }}</span></p>
          </template>
          <template class='text-right' v-slot:foot(xcertificar_rwp)="foot_data_col6">
            <p class='text-right'><span class="font-size-10 font-weight-bold text-nowrap">S/. {{ (egPliego.pim - egPliego.certificacion).toLocaleString('en-US') }}</span></p>
          </template>
          <template class='text-right' v-slot:foot(devengado_rwp)="foot_data_col4">
            <p class='text-right'><span class="font-size-10 font-weight-bold text-nowrap">S/. {{ egPliego.devengado.toLocaleString('en-US') }}</span></p>
          </template>
          <template class='text-right' v-slot:foot(xdevengar_rwp)="foot_data_col4">
            <p class='text-right'><span class="font-size-10 font-weight-bold text-nowrap">S/. {{ (egPliego.pim - egPliego.certificacion).toLocaleString('en-US') }}</span></p>
          </template>
          <template class='text-right' v-slot:foot(avance_rwp)="foot_data_col5">
            <p class='text-right'><span class="font-size-10 font-weight-bold">{{ egPliego.avance }}%</span></p>
          </template>
        </b-table>
      </div>
      <div v-else-if="TipoReport === 'rptPliego' && rptFrom2.id === 'pliegofull'">
        <b-table hover responsive headVariant="light" :items="tableRptWeb" :fields="fieldsRptWeb" class="mb-0 table-borderless p-0" :sort-compare="compareCurrency" foot-clone>
          <template v-slot:cell(rw_ue)="data_rw_tit">
            <span class="font-size-9">{{ data_rw_tit.value }}</span>
          </template>
          <template v-slot:cell(clas_rw)="data_rw_clas">
            <p class='mb-0' v-bind:class="[ data_rw_clas.value.clsadd, data_rw_clas.value.fontSize ]">{{ data_rw_clas.value.valor }}</p>
          </template>
          <template v-slot:cell(pim_rw)="data_rw_pim">
            <p class='mb-0' v-bind:class="[ data_rw_pim.value.clsadd, data_rw_pim.value.fontSize ]">{{ getNumbertoMoney(data_rw_pim.value.valor, 'S/.') }}</p>
          </template>
          <template v-slot:cell(certificado_rw)="data_rw_certificado">
            <p class='mb-0' v-bind:class="[ data_rw_certificado.value.clsadd, data_rw_certificado.value.fontSize ]">{{ getNumbertoMoney(data_rw_certificado.value.valor, 'S/.') }}</p>
          </template>
          <template v-slot:cell(xcertificar_rw)="data_rw_xcert">
            <p class='mb-0' v-bind:class="[ data_rw_xcert.value.clsadd, data_rw_xcert.value.fontSize ]">{{ getNumbertoMoney(data_rw_xcert.value.valor, 'S/.') }}</p>
          </template>
          <template v-slot:cell(devengado_rw)="data_rw_dev">
            <p class='mb-0' v-bind:class="[ data_rw_dev.value.clsadd, data_rw_dev.value.fontSize ]">{{ getNumbertoMoney(data_rw_dev.value.valor, 'S/.') }}</p>
          </template>
          <template v-slot:cell(xdevengar_rw)="data_rw_xdev">
            <p class='mb-0' v-bind:class="[ data_rw_xdev.value.clsadd, data_rw_xdev.value.fontSize ]">{{ getNumbertoMoney(data_rw_xdev.value.valor, 'S/.') }}</p>
          </template>
          <template v-slot:cell(avance_rw)="data_rw_avance">
            <center><span class="font-size-10 font-weight-bold">{{ data_rw_avance.value.count }}%</span></center>
            <b-progress :value="data_rw_avance.value.count" class="iq-progress-bar" :variant="getColorSemaforo(data_rw_avance.value.count, 'cls')">
            </b-progress>
          </template>
          <template class='text-right' v-slot:foot(rw_ue)="foot_data_colx">
            <p class='text-right'></p>
          </template>
          <template class='text-right' v-slot:foot(clas_rw)="foot_data_col1">
            <p class='text-right'><span>Total</span></p>
          </template>
          <template class='text-right' v-slot:foot(pim_rw)="foot_data_col3">
            <p class='text-right'><span class="font-size-10 font-weight-bold text-nowrap">S/. {{ egPliego.pim.toLocaleString('en-US') }}</span></p>
          </template>
          <template class='text-right' v-slot:foot(certificado_rw)="foot_data_col2">
            <p class='text-right'><span class="font-size-10 font-weight-bold text-nowrap">S/. {{ egPliego.certificacion.toLocaleString('en-US') }}</span></p>
          </template>
          <template class='text-right' v-slot:foot(xcertificar_rw)="foot_data_col6">
            <p class='text-right'><span class="font-size-10 font-weight-bold text-nowrap">S/. {{ (egPliego.pim - egPliego.certificacion).toLocaleString('en-US') }}</span></p>
          </template>
          <template class='text-right' v-slot:foot(devengado_rw)="foot_data_col4">
            <p class='text-right'><span class="font-size-10 font-weight-bold text-nowrap">S/. {{ egPliego.devengado.toLocaleString('en-US') }}</span></p>
          </template>
          <template class='text-right' v-slot:foot(xdevengar_rw)="foot_data_col4">
            <p class='text-right'><span class="font-size-10 font-weight-bold text-nowrap">S/. {{ (egPliego.pim - egPliego.certificacion).toLocaleString('en-US').toLocaleString('en-US') }}</span></p>
          </template>
          <template class='text-right' v-slot:foot(avance_rw)="foot_data_col5">
            <p class='text-right'><span class="font-size-10 font-weight-bold text-nowrap">{{ egPliego.avance }}%</span></p>
          </template>
        </b-table>
      </div>
      <div v-else-if="TipoReport === 'rptEjecutora'">
        <b-table striped :items="tableRptUEC" :fields="fieldsRptUEC" class="mb-0 table-borderless p-0" :sort-compare="compareCurrency" foot-clone>
          <template v-slot:cell(uec_clas)="data_uec_tit">
            <b-badge pill :variant="data_uec_tit.value.color">{{ data_uec_tit.value.clasificador }}</b-badge>
            <span class="font-size-9 pl-1">{{ data_uec_tit.value.nombre }}</span>
          </template>
          <template v-slot:cell(pim_uec)="data_uec_pim">
            <p class='text-right mb-0'><span class="font-size-10">{{ getNumbertoMoney(data_uec_pim.value, 'S/.') }}</span></p>
          </template>
          <template v-slot:cell(certificado_uec)="data_uec_certificado">
            <p class='text-right mb-0'><span class="font-size-10">{{ getNumbertoMoney(data_uec_certificado.value, 'S/.') }}</span></p>
          </template>
          <template v-slot:cell(xcertificar_uec)="data_uec_xcert">
            <p class='text-right mb-0'><span class="font-size-10">{{ getNumbertoMoney(data_uec_xcert.value, 'S/.') }}</span></p>
          </template>
          <template v-slot:cell(devengado_uec)="data_uec_dev">
            <p class='text-right mb-0'><span class="font-size-10">{{ getNumbertoMoney(data_uec_dev.value, 'S/.') }}</span></p>
          </template>
          <template v-slot:cell(xdevengar_uec)="data_uec_xdev">
            <p class='text-right mb-0'><span class="font-size-10">{{ getNumbertoMoney(data_uec_xdev.value, 'S/.') }}</span></p>
          </template>
          <template v-slot:cell(avance_uec)="data_uec_avance">
            <center><span class="font-size-10 font-weight-bold">{{ data_uec_avance.value.count }}%</span></center>
            <b-progress :value="data_uec_avance.value.count" class="iq-progress-bar" :variant="getColorSemaforo(data_uec_avance.value.count, 'cls')">
            </b-progress>
          </template>
          <template class='text-right' v-slot:foot(uec_clas)="foot_data_col1">
            <p class='text-right'><span>Total UE</span></p>
          </template>
          <template class='text-right' v-slot:foot(pim_uec)="foot_data_col3">
            <p class='text-right'><span class="font-size-10 font-weight-bold text-nowrap">S/. {{ stdUEInfo.eg.listaEG[0].pim.toLocaleString('en-US') }}</span></p>
          </template>
          <template class='text-right' v-slot:foot(certificado_uec)="foot_data_col2">
            <p class='text-right'><span class="font-size-10 font-weight-bold text-nowrap">S/. {{ stdUEInfo.eg.listaEG[0].certificacion.toLocaleString('en-US') }}</span></p>
          </template>
          <template class='text-right' v-slot:foot(xcertificar_uec)="foot_data_col6">
            <p class='text-right'><span class="font-size-10 font-weight-bold text-nowrap">S/. {{ (stdUEInfo.eg.listaEG[0].pim - stdUEInfo.eg.listaEG[0].certificacion).toLocaleString('en-US') }}</span></p>
          </template>
          <template class='text-right' v-slot:foot(devengado_uec)="foot_data_col4">
            <p class='text-right'><span class="font-size-10 font-weight-bold text-nowrap">S/. {{ stdUEInfo.eg.listaEG[0].devengado.toLocaleString('en-US') }}</span></p>
          </template>
          <template class='text-right' v-slot:foot(xdevengar_uec)="foot_data_col4">
            <p class='text-right'><span class="font-size-10 font-weight-bold text-nowrap">S/. {{ (stdUEInfo.eg.listaEG[0].pim - stdUEInfo.eg.listaEG[0].certificacion).toLocaleString('en-US') }}</span></p>
          </template>
          <template class='text-right' v-slot:foot(avance_uec)="foot_data_col5">
            <p class='text-right'><span class="font-size-10 font-weight-bold">{{ stdUEInfo.eg.listaEG[0].avance }}%</span></p>
          </template>
        </b-table>
      </div>
    </b-modal>
    <b-modal id="mdlFichaProdProy" size="lg" title="Ficha Banco de Proyectos - Invierte.Pe" cancel-title="Cerrar">
      <iframe style="width:100%" :src="urlFichaProdProy" class="vh-100" frameborder="0" scrolling="no" allowtransparency="true"></iframe>
    </b-modal>
  </b-container>
</template>
<script>
import { mapGetters } from 'vuex'
import ApexCharts from 'apexcharts'
import { vito } from '../../config/pluginInit'
import loading1 from '../../assets/images/loading.gif'
export default {
  name: 'DashboardCovid',
  components: { },
  mounted () {
    vito.index()
    this.getInit()
    this.getPanels()
  },
  data () {
    return {
      titleMdlUE: '',
      imgLoading: loading1,
      titleRptWeb: '',
      rptFroms: ['Web', 'Excel'],
      rptFroms2: [
        { id: 'pliego', label: 'Sólo Pliego' },
        { id: 'pliegofull', label: 'Consolidado UEs' }
      ],
      selected_rptPliego: ['G'],
      stdDetCUE: ['G'],
      options_rptPliego: [
        { text: 'Genérica', value: 'G' },
        { text: 'Sub Genérica', value: 'SG' },
        { text: 'Detalle Sub Genérica', value: 'DSG' },
        { text: 'Específica', value: 'E' },
        { text: 'Detalle Específica', value: 'DE' }
      ],
      ftrAnios: [2023, 2022, 2021, 2020, 2019, 2018, 2017, 2016, 2015],
      ftrActProy: [
        {
          value: 'ActProy',
          label: 'Actividad/Proyecto'
        },
        {
          value: 'Proyecto',
          label: 'Proyecto'
        },
        {
          value: 'Actividad',
          label: 'Actividad'
        }
      ],
      stdUEInfo: '',
      isLoading: false,
      btVariants: ['primary', 'secondary', 'info', 'danger', 'warning', 'success', 'light'],
      egUES: [],
      egPliego: {},
      tipoSemaforo: 'FIJO',
      leyendaS: 'MAYOR A 50',
      leyendaW: 'ENTRE 50 Y 33',
      leyendaD: 'MENOR a 33',
      sizeModal: 'sm',
      urlFichaProdProy: '',
      fechaWS: '',
      rptFrom: '',
      rptFrom2: '',
      rptDestinoUE: '',
      PliegoSel: '459',
      AnioSel: null,
      ActProySel: null,
      TipoReport: null,
      dataInfoEG: {},
      piaEG: 0,
      pimEG: 0,
      devengadoEG: 0,
      giradoEG: 0,
      totalPIAPliegos: 0,
      totalPIMPliegos: 0,
      totalCertificadoPliegos: 0,
      totalDEVPliegos: 0,
      totalAvancePliegos: 0,
      tablePliegosEG: [],
      tableUESEG: [],
      tableRptWeb: [],
      tableRptWebP: [],
      tableRptUEC: [],
      totalPIAUES: 0,
      totalPIMUES: 0,
      totalCertificadoUES: 0,
      totalXCertificadoUES: 0,
      totalDEVUES: 0,
      totalXDEVUES: 0,
      totalAvanceUES: 0,
      totalPIM_rw: 0,
      totalCertificado_rw: 0,
      totalXCertificado_rw: 0,
      totalDEV_rw: 0,
      totalXDEV_rw: 0,
      totalAvance_rw: 0,
      listProdProy: [],
      iconsProdProy: ['ri-window-line', 'ri-briefcase-3-line', 'ri-calendar-line', 'ri-computer-line', 'ri-file-line', 'ri-draft-line', 'ri-file-text-line', 'ri-file-copy-line', 'ri-task-line', 'ri-file-copy-2-line', 'ri-todo-line', 'ri-article-line', 'ri-booklet-line', 'ri-file-shield-2-line', 'ri-refund-line', 'ri-checkbox-line'],
      chtREG: null,
      chtOptREG: {
        series: [],
        colors: [],
        chart: {
          type: 'radialBar',
          height: 235
        },
        plotOptions: {
          radialBar: {
            hollow: {
              size: '70%'
            },
            dataLabels: {
              showOn: 'always',
              name: {
                offsetY: -10,
                show: true,
                color: '#888',
                fontSize: '13px'
              },
              value: {
                color: '#111',
                fontSize: '30px',
                show: true
              }
            }
          }
        },
        stroke: {
          lineCap: 'round'
        },
        labels: ['Avance']
      },
      chtLTEG: null,
      chtOptLTEG: {
        series: [],
        chart: {
          height: 400,
          type: 'line',
          dropShadow: {
            enabled: true,
            color: '#000',
            top: 18,
            left: 7,
            blur: 10,
            opacity: 0.2
          },
          toolbar: {
            show: true,
            offsetX: 0,
            offsetY: -30
          }
        },
        colors: ['#3dbb58', '#827af3', '#fbc647', '#6ce6f4'],
        tooltip: {
          y: {
            formatter: function (val) {
              return 'S/. ' + val.toLocaleString('en-US')
            }
          }
        },
        dataLabels: {
          enabled: true,
          formatter: function (val) {
            return 'S/. ' + val.toLocaleString('en-US')
          }
        },
        stroke: {
          curve: 'smooth'
        },
        title: {
          text: 'Evolución de Ejecución',
          align: 'left'
        },
        grid: {
          borderColor: '#e7e7e7',
          row: {
            colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
            opacity: 0.5
          }
        },
        markers: {
          size: 1
        },
        xaxis: {
          categories: [],
          title: {
            text: 'Día'
          }
        },
        yaxis: {
          title: {
            text: 'Millones de Soles'
          },
          min: 4,
          max: 5,
          labels: {
            formatter: function (val) {
              return 'S/.' + val.toLocaleString('en-US')
            }
          }
        },
        legend: {
          position: 'top',
          horizontalAlign: 'right',
          floating: true,
          offsetY: -25,
          offsetX: -5
        }
      },
      sortByPEG: 'avance',
      sortDescPEG: true,
      fieldsPliegosEG: [
        {
          key: 'index',
          label: 'Posición',
          sortable: true,
          tdClass: 'd-none',
          thClass: 'd-none'
        },
        {
          key: 'pliego',
          sortable: false,
          tdClass: 'pt-0 pb-0 align-middle'
        },
        {
          key: 'pim',
          sortable: true,
          tdClass: 'pt-0 pb-0 align-middle',
          thClass: 'text-right'
        },
        {
          key: 'certificacion',
          sortable: true,
          tdClass: 'pt-0 pb-0 align-middle',
          thClass: 'text-right'
        },
        {
          key: 'devengado',
          sortable: true,
          tdClass: 'pt-0 pb-0 align-middle',
          thClass: 'text-right'
        },
        {
          key: 'avance',
          sortable: true,
          tdClass: 'pt-0 pb-0 align-middle',
          thClass: 'text-center'
        }
      ],
      sortByUEEG: 'avance_ue',
      sortDescUEEG: true,
      fieldsUESEG: [
        {
          key: 'index',
          label: 'Posición',
          sortable: true,
          tdClass: 'd-none',
          thClass: 'd-none'
        },
        {
          key: 'ue',
          label: 'Unidad Ejecutora',
          sortable: false,
          tdClass: 'pt-0 pb-0 align-middle'
        },
        {
          key: 'pim_ue',
          label: 'Pim',
          sortable: true,
          tdClass: 'pt-0 pb-0 align-middle',
          thClass: 'text-right'
        },
        {
          key: 'certificado_ue',
          label: 'Certificado',
          sortable: true,
          tdClass: 'pt-0 pb-0 align-middle',
          thClass: 'text-right'
        },
        {
          key: 'xcertificar_ue',
          label: 'Por Certificar',
          sortable: true,
          tdClass: 'pt-0 pb-0 align-middle',
          thClass: 'text-right'
        },
        {
          key: 'devengado_ue',
          label: 'Devengado',
          sortable: true,
          tdClass: 'pt-0 pb-0 align-middle',
          thClass: 'text-right'
        },
        {
          key: 'xdevengar_ue',
          label: 'Por Devengar',
          sortable: true,
          tdClass: 'pt-0 pb-0 align-middle',
          thClass: 'text-right'
        },
        {
          key: 'avance_ue',
          label: 'Avance',
          sortable: true,
          tdClass: 'pt-0 pb-0 align-middle',
          thClass: 'text-center'
        },
        {
          key: 'opera_ue',
          label: 'Operaciones',
          tdClass: 'pt-1 pb-1 align-middle text-center',
          thClass: 'text-center'
        }
      ],
      fieldsRptWebP: [
        {
          key: 'rwp_clas',
          label: 'Clasificador',
          sortable: false,
          tdClass: 'pt-0 pb-0 align-middle text-left',
          thClass: 'text-left align-middle'
        },
        {
          key: 'pim_rwp',
          label: 'Pim',
          sortable: true,
          tdClass: 'pt-0 pb-0 align-middle text-right font-size-10 text-nowrap',
          thClass: 'text-right align-middle'
        },
        {
          key: 'certificado_rwp',
          label: 'Certificado',
          sortable: true,
          tdClass: 'pt-0 pb-0 align-middle text-right font-size-10 text-nowrap',
          thClass: 'text-right align-middle'
        },
        {
          key: 'xcertificar_rwp',
          label: 'Por Certificar',
          sortable: true,
          tdClass: 'pt-0 pb-0 align-middle text-right font-size-10 text-nowrap',
          thClass: 'text-center align-middle'
        },
        {
          key: 'devengado_rwp',
          label: 'Devengado',
          sortable: true,
          tdClass: 'pt-0 pb-0 align-middle text-right font-size-10 text-nowrap',
          thClass: 'text-right align-middle'
        },
        {
          key: 'xdevengar_rwp',
          label: 'Por Devengar',
          sortable: true,
          tdClass: 'pt-0 pb-0 align-middle text-right font-size-10 text-nowrap',
          thClass: 'text-center align-middle'
        },
        {
          key: 'avance_rwp',
          label: 'Avance',
          sortable: true,
          tdClass: 'pt-0 pb-0 align-middle',
          thClass: 'text-center align-middle'
        }
      ],
      fieldsRptUEC: [
        {
          key: 'uec_clas',
          label: 'Clasificador',
          sortable: false,
          tdClass: 'pt-0 pb-0 align-middle text-left',
          thClass: 'text-left align-middle'
        },
        {
          key: 'pim_uec',
          label: 'Pim',
          sortable: true,
          tdClass: 'pt-0 pb-0 align-middle text-right font-size-10 text-nowrap',
          thClass: 'text-right align-middle'
        },
        {
          key: 'certificado_uec',
          label: 'Certificado',
          sortable: true,
          tdClass: 'pt-0 pb-0 align-middle text-right font-size-10 text-nowrap',
          thClass: 'text-right align-middle'
        },
        {
          key: 'xcertificar_uec',
          label: 'Por Certificar',
          sortable: true,
          tdClass: 'pt-0 pb-0 align-middle text-right font-size-10 text-nowrap',
          thClass: 'text-center align-middle'
        },
        {
          key: 'devengado_uec',
          label: 'Devengado',
          sortable: true,
          tdClass: 'pt-0 pb-0 align-middle text-right font-size-10 text-nowrap',
          thClass: 'text-right align-middle'
        },
        {
          key: 'xdevengar_uec',
          label: 'Por Devengar',
          sortable: true,
          tdClass: 'pt-0 pb-0 align-middle text-right font-size-10 text-nowrap',
          thClass: 'text-center align-middle'
        },
        {
          key: 'avance_uec',
          label: 'Avance',
          sortable: true,
          tdClass: 'pt-0 pb-0 align-middle',
          thClass: 'text-center align-middle'
        }
      ],
      fieldsRptWeb: [
        {
          key: 'rw_ue',
          label: 'Unidad Ejecutora',
          sortable: false,
          tdClass: 'pt-0 pb-0 align-middle',
          thClass: 'text-left align-middle'
        },
        {
          key: 'clas_rw',
          label: 'Clasificador',
          sortable: false,
          tdClass: 'pt-0 pb-0 align-middle text-left font-size-9',
          thClass: 'text-left align-middle'
        },
        {
          key: 'pim_rw',
          label: 'Pim',
          sortable: false,
          tdClass: 'pt-0 pb-0 align-middle text-right font-size-10 text-nowrap',
          thClass: 'text-right align-middle'
        },
        {
          key: 'certificado_rw',
          label: 'Certificado',
          sortable: false,
          tdClass: 'pt-0 pb-0 align-middle text-right font-size-10 text-nowrap',
          thClass: 'text-right align-middle'
        },
        {
          key: 'xcertificar_rw',
          label: 'Por Certificar',
          sortable: false,
          tdClass: 'pt-0 pb-0 align-middle text-right font-size-10',
          thClass: 'align-middle text-center'
        },
        {
          key: 'devengado_rw',
          label: 'Devengado',
          sortable: false,
          tdClass: 'pt-0 pb-0 align-middle text-right font-size-10 text-nowrap',
          thClass: 'text-right align-middle'
        },
        {
          key: 'xdevengar_rw',
          label: 'Por Devengar',
          sortable: false,
          tdClass: 'pt-0 pb-0 align-middle text-right font-size-10',
          thClass: 'align-middle text-right'
        },
        {
          key: 'avance_rw',
          label: 'Avance',
          sortable: false,
          tdClass: 'pt-0 pb-0 align-middle',
          thClass: 'text-center align-middle'
        }
      ],
      chtUE: null,
      chtOptUE: {
        series: [],
        chart: {
          type: 'bar',
          height: 350
        },
        colors: ['#a1f348', '#827af3', '#6ce6f4'],
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: '55%',
            endingShape: 'rounded'
          }
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          show: true,
          width: 2,
          colors: ['transparent']
        },
        xaxis: {
          categories: []
        },
        yaxis: {
        },
        fill: {
          opacity: 1
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return 'S/. ' + val.toLocaleString('en-US')
            }
          }
        }
      },
      chtClas: null,
      chtFF: null,
      chtOptClas: {
        series: [],
        chart: {
          height: 350,
          type: 'radialBar',
          toolbar: {
            show: true
          }
        },
        plotOptions: {
          radialBar: {
            offsetY: 0,
            startAngle: 0,
            endAngle: 270,
            hollow: {
              margin: 5,
              size: '30%',
              background: 'transparent',
              image: undefined
            },
            dataLabels: {
              name: {
                show: false
              },
              value: {
                show: true,
                color: '#111',
                fontSize: '25px'
              }
            }
          }
        },
        colors: ['#0084ff', '#00ca00', '#e64141', '#ffd400', '#00d0ff', '#a09e9e', '#9c27b0'],
        labels: [],
        legend: {
          show: true,
          floating: true,
          fontSize: '10px',
          position: 'left',
          offsetX: 10,
          offsetY: -20,
          labels: {
            useSeriesColors: true
          },
          markers: {
            size: 0
          },
          formatter: function (seriesName, opts) {
            return seriesName + ': ' + opts.w.globals.series[opts.seriesIndex]
          },
          itemMargin: {
            vertical: 3
          }
        },
        responsive: [{
          breakpoint: 480,
          options: {
            legend: {
              show: false
            }
          }
        }]
      },
      chtOptFF: {
        series: [],
        chart: {
          height: 350,
          type: 'radialBar',
          toolbar: {
            show: true
          }
        },
        plotOptions: {
          radialBar: {
            offsetY: 0,
            startAngle: 0,
            endAngle: 270,
            hollow: {
              margin: 5,
              size: '30%',
              background: 'transparent',
              image: undefined
            },
            dataLabels: {
              name: {
                show: false
              },
              value: {
                show: true,
                color: '#111',
                fontSize: '25px'
              }
            }
          }
        },
        colors: ['#0084ff', '#00ca00', '#e64141', '#ffd400', '#00d0ff', '#a09e9e', '#9c27b0'],
        labels: [],
        legend: {
          show: true,
          floating: true,
          fontSize: '10px',
          position: 'left',
          offsetX: 0,
          offsetY: -20,
          labels: {
            useSeriesColors: true
          },
          markers: {
            size: 0
          },
          formatter: function (seriesName, opts) {
            let dots = seriesName.length > 21 ? '' : ''
            let strEnd = seriesName.substring(0, 21) + dots
            return strEnd + ': ' + opts.w.globals.series[opts.seriesIndex]
          },
          itemMargin: {
            vertical: 3
          }
        },
        responsive: [{
          breakpoint: 480,
          options: {
            legend: {
              show: false
            }
          }
        }]
      },
      chtOptClas2: {
        title: {
          text: 'Ejecución Por Genérica',
          align: 'center'
        },
        labels: [],
        series: [],
        colors: ['#0084ff', '#00ca00', '#e64141', '#ffd400', '#00d0ff', '#a09e9e', '#9c27b0'],
        chart: {
          width: 300,
          height: 180,
          type: 'donut',
          toolbar: {
            show: true,
            offsetX: -50,
            offsetY: -0
          }
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return 'S/. ' + val.toLocaleString('en-US')
            }
          }
        },
        dataLabels: {
          enabled: true
        },
        responsive: [{
          breakpoint: 480,
          options: {
            chart: {
              width: 200
            },
            legend: {
              show: false
            }
          }
        }],
        legend: {
          position: 'right',
          offsetY: 10,
          offsetX: 40,
          height: 150
        }
      }
    }
  },
  methods: {
    downloadXls (seccion) {
      let that = this
      if (seccion === 'P') {
        this.$store
          .dispatch('gsplanifica/REPORTESXLS_GETPLIEGOS', { anio: that.AnioSel, actProy: that.ActProySel.value, codPliego: 'todos', modulo: 'covid19' })
          .then(res => {
            let a = document.createElement('a')
            a.target = '_blank'
            a.href = res
            a.download = 'descargar.xls'
            a.click()
          })
          .catch(error => {
            console.log('error de acceso' + error)
          })
      } else if (seccion === 'UE') {
        this.$store
          .dispatch('gsplanifica/REPORTESXLS_GETEJECUTORAS', { anio: that.AnioSel, actProy: that.ActProySel.value, codPliego: that.PliegoSel, codEjecutora: 'todos', modulo: 'covid19' })
          .then(res => {
            let a = document.createElement('a')
            a.target = '_blank'
            a.href = res
            a.download = 'descargar.xls'
            a.click()
          })
          .catch(error => {
            console.log('error de acceso' + error)
          })
      }
    },
    shareWtp (seccion) {
      let that = this
      let celularFmt = '51992722863'
      let urlWtp = 'https://wa.me/'
      let msj = '/?text=Saludos, estoy compartiendo en instantáneo a través de DATAManager el estado de ejecución presupuestal actual a nivel de '
      if (seccion === 'P') {
        this.$store
          .dispatch('gsplanifica/REPORTESXLS_GETPLIEGOS', { anio: that.AnioSel, actProy: that.ActProySel.value, codPliego: 'todos', modulo: 'covid19' })
          .then(res => {
            msj = msj + 'Pliegos en el siguiente link: ' + res
            let urlGo = urlWtp + celularFmt + msj
            window.open(urlGo, '_blank')
          })
          .catch(error => {
            console.log('error de acceso' + error)
          })
      } else if (seccion === 'UE') {
        this.$store
          .dispatch('gsplanifica/REPORTESXLS_GETEJECUTORAS', { anio: that.AnioSel, actProy: that.ActProySel.value, codPliego: that.PliegoSel, codEjecutora: 'todos', modulo: 'covid19' })
          .then(res => {
            msj = msj + 'Unidades Ejecutoras en el siguiente link: ' + res
            let urlGo = urlWtp + celularFmt + msj
            window.open(urlGo, '_blank')
          })
          .catch(error => {
            console.log('error de acceso' + error)
          })
      }
    },
    getMdlUES (item) {
      let cods = item.ue.codigo.split('-')
      this.stdUEInfo = this.egUES.find(eje => eje.cod === cods[1])
      this.titleMdlUE = 'UE ' + cods[1] + ' - OPCIONES DE REPORTE'
      this.TipoReport = 'rptEjecutora'
      this.$bvModal.show('mdlClasUE')
    },
    getLinkFicha (codigoDnpp) {
      this.urlFichaProdProy = 'https://ofi5.mef.gob.pe/invierte/formato/verInversion/' + codigoDnpp + '?tk=x4lyuMN02pl5Tz0vSEs9lm8Qr'
      this.$bvModal.show('mdlFichaProdProy')
    },
    getReport (ev) {
      this.TipoReport = ev.currentTarget.id
      this.$bvModal.show('mdlClas')
    },
    getColorPliegos (avance) {
      let color = 'success'
      if (avance <= this.totalAvancePliegos) {
        color = 'danger'
      } else if ((avance - this.totalAvancePliegos) < 5) {
        color = 'warning'
      }
      return color
    },
    async getOKMdlUE () {
      let that = this
      if (this.stdDetCUE.length !== 0) {
        if (this.rptDestinoUE.toLowerCase() === 'excel') {
          this.$store
            .dispatch('gsplanifica/REPORTESXLS_GETCLASIFICADORES', { anio: that.AnioSel, actProy: that.ActProySel.value, codSeccion: that.stdUEInfo.cod, seccion: 'ejecutora', nivelClasificador: that.stdDetCUE, modulo: 'covid19' })
            .then(res => {
              let a = document.createElement('a')
              a.target = '_blank'
              a.href = res
              a.download = 'descargar.xls'
              a.click()
            })
            .catch(error => {
              console.log('error de acceso' + error)
            })
        } else {
          this.$bvModal.show('mdlRptWeb')
          that.tableRptUEC = []
          that.titleRptWeb = 'REPORTE DE EJECUCIÓN POR CLASIFICADORES UNIDAD EJECUTORA ' + that.stdUEInfo.cod
          that.isLoading = true

          await this.$store
            .dispatch('gsplanifica/REPORTES_GETCLASIFICADORES', { anio: that.AnioSel, actProy: that.ActProySel.value, codSeccion: that.stdUEInfo.cod, seccion: 'ejecutora', nivelClasificador: that.stdDetCUE, modulo: 'covid19' })
            .then(res => {
              if (res !== undefined) {
                res.forEach(function (ejep) {
                  if (ejep.listaEG.length !== 0) {
                    let colorSema = that.getColorSemaforo(ejep.listaEG[0].avance, 'cls')
                    let xcert = ejep.listaEG[0].pim - ejep.listaEG[0].certificacion
                    let xdevengar = ejep.listaEG[0].pim - ejep.listaEG[0].devengado
                    that.tableRptUEC.push({
                      uec_clas: { color: colorSema, clasificador: ejep.clasificadorGasto.clasificador, nombre: ejep.clasificadorGasto.nombre },
                      pim_uec: ejep.listaEG[0].pim,
                      certificado_uec: ejep.listaEG[0].certificacion,
                      xcertificar_uec: xcert,
                      devengado_uec: ejep.listaEG[0].devengado,
                      xdevengar_uec: xdevengar,
                      avance_uec: { color: 'danger', count: ejep.listaEG[0].avance }
                    })
                  }
                })
              }
            })
            .catch(error => {
              if (error.response.status === 401 || error.response.status === 500) {
                console.log('error de acceso')
              }
            })
          that.isLoading = false
        }
      } else {
        alert('Seleccione Detalle de Clasificador')
      }
    },
    async getOKMdl () {
      let that = this
      if (this.TipoReport === 'rptPliego') {
        if (this.selected_rptPliego.length !== 0) {
          if (this.rptFrom.toLowerCase() === 'excel') {
            this.$store
              .dispatch('gsplanifica/REPORTESXLS_GETCLASIFICADORES', { anio: that.AnioSel, actProy: that.ActProySel.value, codSeccion: that.PliegoSel, seccion: that.rptFrom2.id, nivelClasificador: that.selected_rptPliego, modulo: 'covid19', from: that.rptFrom.toLowerCase() })
              .then(res => {
                let a = document.createElement('a')
                a.target = '_blank'
                a.href = res
                a.download = 'descargar.xls'
                a.click()
              })
              .catch(error => {
                console.log('error de acceso' + error)
              })
          } else {
            this.$bvModal.show('mdlRptWeb')
            if (that.rptFrom2.id === 'pliegofull') {
              that.titleRptWeb = 'REPORTE DE EJECUCIÓN CONSOLIDADO UNIDADES EJECUTORAS - PLIEGO ' + that.PliegoSel
              that.tableRptWeb = []
              that.isLoading = true
              await this.$store
                .dispatch('gsplanifica/REPORTES_GETCLASIFICADORES', { anio: that.AnioSel, actProy: that.ActProySel.value, codSeccion: that.PliegoSel, seccion: that.rptFrom2.id, nivelClasificador: that.selected_rptPliego, modulo: 'covid19' })
                .then(res => {
                  if (res !== undefined) {
                    let ixColor = -1
                    let ixMain = 0
                    let codEje = ''
                    res.forEach(function (rsl) {
                      ixMain = ixMain + 1
                      let nombreEje = that.egUES.find(eje => eje.cod === rsl.idSeccion.toString())
                      if (codEje !== nombreEje.cod) {
                        if (codEje !== '') {
                          let tmpTotalUE = that.egUES.find(eje => eje.cod === codEje)
                          that.tableRptWeb.push({
                            _rowVariant: that.btVariants[ixColor],
                            rw_ue: '',
                            clas_rw: { valor: 'TOTAL', clsadd: 'font-weight-bold', fontSize: 'font-size-12' },
                            pim_rw: { valor: tmpTotalUE.eg.listaEG[0].pim, clsadd: 'font-weight-bold', fontSize: 'font-size-10' },
                            certificado_rw: { valor: tmpTotalUE.eg.listaEG[0].certificacion, clsadd: 'font-weight-bold', fontSize: 'font-size-10' },
                            xcertificar_rw: { valor: tmpTotalUE.eg.listaEG[0].pim - tmpTotalUE.eg.listaEG[0].certificacion, clsadd: 'font-weight-bold', fontSize: 'font-size-10' },
                            devengado_rw: { valor: tmpTotalUE.eg.listaEG[0].devengado, clsadd: 'font-weight-bold', fontSize: 'font-size-10' },
                            xdevengar_rw: { valor: tmpTotalUE.eg.listaEG[0].pim - tmpTotalUE.eg.listaEG[0].devengado, clsadd: 'font-weight-bold', fontSize: 'font-size-10' },
                            avance_rw: { color: 'danger', count: tmpTotalUE.eg.listaEG[0].avance }
                          })
                        }
                        codEje = nombreEje.cod
                        ixColor++
                        if (ixColor === that.btVariants.length - 1) {
                          ixColor = -1
                        }
                      }
                      let xcert = rsl.listaEG[0].pim - rsl.listaEG[0].certificacion
                      let xdevengar = rsl.listaEG[0].pim - rsl.listaEG[0].devengado
                      that.tableRptWeb.push({
                        _rowVariant: that.btVariants[ixColor],
                        rw_ue: nombreEje.eg.codPptal + '-' + nombreEje.eg.codFinan + ' ' + that.getNombreEjecuctora(nombreEje.eg.nombre),
                        clas_rw: { valor: rsl.clasificadorGasto.clasificador + ' ' + rsl.clasificadorGasto.nombre, clsadd: '', fontSize: '' },
                        pim_rw: { valor: rsl.listaEG[0].pim, clsadd: '', fontSize: '' },
                        certificado_rw: { valor: rsl.listaEG[0].certificacion, clsadd: '', fontSize: '' },
                        xcertificar_rw: { valor: xcert, clsadd: '', fontSize: '' },
                        devengado_rw: { valor: rsl.listaEG[0].devengado, clsadd: '', fontSize: '' },
                        xdevengar_rw: { valor: xdevengar, clsadd: '', fontSize: '' },
                        avance_rw: { color: 'danger', count: rsl.listaEG[0].avance }
                      })
                      if (ixMain === res.length) {
                        let tmpTotalUE = that.egUES.find(eje => eje.cod === codEje)
                        that.tableRptWeb.push({
                          _rowVariant: that.btVariants[ixColor],
                          rw_ue: '',
                          clas_rw: { valor: 'TOTAL', clsadd: 'font-weight-bold', fontSize: 'font-size-12' },
                          pim_rw: { valor: tmpTotalUE.eg.listaEG[0].pim, clsadd: 'font-weight-bold', fontSize: 'font-size-10' },
                          certificado_rw: { valor: tmpTotalUE.eg.listaEG[0].certificacion, clsadd: 'font-weight-bold', fontSize: 'font-size-10' },
                          xcertificar_rw: { valor: tmpTotalUE.eg.listaEG[0].pim - tmpTotalUE.eg.listaEG[0].certificacion, clsadd: 'font-weight-bold', fontSize: 'font-size-10' },
                          devengado_rw: { valor: tmpTotalUE.eg.listaEG[0].devengado, clsadd: 'font-weight-bold', fontSize: 'font-size-10' },
                          xdevengar_rw: { valor: tmpTotalUE.eg.listaEG[0].pim - tmpTotalUE.eg.listaEG[0].devengado, clsadd: 'font-weight-bold', fontSize: 'font-size-10' },
                          avance_rw: { color: 'danger', count: tmpTotalUE.eg.listaEG[0].avance }
                        })
                      }
                    })
                  }
                })
                .catch(error => {
                  console.log('error de acceso' + error)
                })
              that.isLoading = false
            } else {
              that.titleRptWeb = 'REPORTE DE EJECUCIÓN POR CLASIFICADORES PLIEGO ' + that.PliegoSel
              that.tableRptWebP = []
              that.isLoading = true

              await this.$store
                .dispatch('gsplanifica/REPORTES_GETCLASIFICADORES', { anio: that.AnioSel, actProy: that.ActProySel.value, codSeccion: that.PliegoSel, seccion: that.rptFrom2.id, nivelClasificador: that.selected_rptPliego, modulo: 'covid19' })
                .then(res => {
                  if (res !== undefined) {
                    res.forEach(function (ejep) {
                      if (ejep.listaEG.length !== 0) {
                        let colorSema = that.getColorSemaforo(ejep.listaEG[0].avance, 'cls')
                        let xcert = ejep.listaEG[0].pim - ejep.listaEG[0].certificacion
                        let xdevengar = ejep.listaEG[0].pim - ejep.listaEG[0].devengado
                        that.tableRptWebP.push({
                          rwp_clas: { color: colorSema, clasificador: ejep.clasificadorGasto.clasificador, nombre: ejep.clasificadorGasto.nombre },
                          pim_rwp: ejep.listaEG[0].pim,
                          certificado_rwp: ejep.listaEG[0].certificacion,
                          xcertificar_rwp: xcert,
                          devengado_rwp: ejep.listaEG[0].devengado,
                          xdevengar_rwp: xdevengar,
                          avance_rwp: { color: 'danger', count: ejep.listaEG[0].avance }
                        })
                      }
                    })
                  }
                })
                .catch(error => {
                  if (error.response.status === 401 || error.response.status === 500) {
                    console.log('error de acceso')
                  }
                })
              that.isLoading = false
            }
          }
        } else {
          alert('Seleccione Detalle de Clasificador')
        }
      }
    },
    getInit () {
      this.AnioSel = this.ftrAnios[0]
      this.ActProySel = this.ftrActProy[0]
      this.rptFrom = this.rptFroms[0]
      this.rptFrom2 = this.rptFroms2[0]
      this.rptDestinoUE = this.rptFroms[0]
    },
    chgSelect () {
      if (this.chtLTEG !== null) {
        this.chtLTEG.destroy()
      }
      if (this.chtREG !== null) {
        this.chtREG.destroy()
      }
      if (this.chtUE !== null) {
        this.chtUE.destroy()
      }
      this.getPanels()
    },
    compareCurrency (itemA, itemB, key) {
      if (key !== 'pim' && key !== 'pia' && key !== 'devengado' && key !== 'certificacion') {
        return false
      } else {
        let a = itemA[key]
        let b = itemB[key]
        a = a.replace('S/. ', '')
        a = a.replace(/,/g, '')
        b = b.replace('S/. ', '')
        b = b.replace(/,/g, '')
        return a - b
      }
    },
    getPanels () {
      let that = this
      that.egUES = []
      that.egPliego = {}
      that.chtOptUE.series = []
      that.chtOptUE.xaxis.categories = []
      that.chtOptClas.series = []
      that.chtOptClas.labels = []
      that.chtOptFF.series = []
      that.chtOptFF.labels = []
      that.tablePliegosEG = []
      that.tableUESEG = []
      that.chtOptREG.series = []
      that.chtOptREG.colors = []
      that.chtOptLTEG.series = []
      that.listProdProy = []
      this.$store
        .dispatch('gsplanifica/REPORTES_GETPLIEGOS', { anio: that.AnioSel, actProy: that.ActProySel.value, codPliego: 'todos', modulo: 'covid19' })
        .then(res => {
          if (res !== undefined) {
            that.totalPIAPliegos = 0
            that.totalPIMPliegos = 0
            that.totalCertificadoPliegos = 0
            that.totalDEVPliegos = 0
            that.totalAvancePliegos = 0
            that.fechaWS = res[0].listaEG.length === 0 ? '' : res[0].listaEG[0].webScraping.fechaActualizacion
            res.forEach(function (pliego) {
              if (pliego.listaEG.length !== 0) {
                that.totalPIAPliegos = that.totalPIAPliegos + pliego.listaEG[0].pia
                that.totalPIMPliegos = that.totalPIMPliegos + pliego.listaEG[0].pim
                that.totalCertificadoPliegos = that.totalCertificadoPliegos + pliego.listaEG[0].certificacion
                that.totalDEVPliegos = that.totalDEVPliegos + pliego.listaEG[0].devengado
                that.totalAvancePliegos = that.totalAvancePliegos + pliego.listaEG[0].avance
                let colorSema = that.getColorSemaforo(pliego.listaEG[0].avance, 'cls')
                let rowVariant = ''
                if (that.PliegoSel === pliego.codigo) {
                  rowVariant = 'warning'
                }
                that.tablePliegosEG.push({
                  pliego: { color: colorSema, codigo: pliego.codigo, nombre: pliego.nombre },
                  pia: 'S/. ' + pliego.listaEG[0].pia.toLocaleString('en-US'),
                  pim: 'S/. ' + pliego.listaEG[0].pim.toLocaleString('en-US'),
                  certificacion: 'S/. ' + pliego.listaEG[0].certificacion.toLocaleString('en-US'),
                  devengado: 'S/. ' + pliego.listaEG[0].devengado.toLocaleString('en-US'),
                  avance: { color: 'danger', count: pliego.listaEG[0].avance },
                  _rowVariant: rowVariant
                })
              }
            })
            let totalPorc = that.totalDEVPliegos / that.totalPIMPliegos * 100
            that.totalAvancePliegos = Math.round(totalPorc * 100) / 100
          }
        })
        .catch(error => {
          if (error.response.status === 401 || error.response.status === 500) {
            console.log('error de acceso')
          }
        })

      this.$store
        .dispatch('gsplanifica/REPORTES_GETPRODPROY', { anio: that.AnioSel, actProy: 'Proyecto', codSeccion: that.PliegoSel, seccion: 'pliego', limit: 20, modulo: 'covid19' })
        .then(res => {
          if (res !== undefined) {
            res.forEach(function (prodproy) {
              let iconR = that.iconsProdProy[Math.floor(Math.random() * that.iconsProdProy.length)]
              let colorS = that.getColorSemaforo(prodproy.listaEG[0].avance, 'cls')
              that.listProdProy.push({ codigoDnpp: prodproy.codigoDnpp, nombreDnpp: prodproy.nombreDnpp, listaEG: prodproy.listaEG, icon: iconR, color: colorS })
            })
          }
        })
        .catch(error => {
          if (error.response.status === 401 || error.response.status === 500) {
            console.log('error de acceso')
          }
        })

      this.$store
        .dispatch('gsplanifica/REPORTES_GETLINEATIEMPO', { anio: that.AnioSel, actProy: that.ActProySel.value, codSeccion: that.PliegoSel, seccion: 'pliego', cantDias: 30, modulo: 'covid19' })
        .then(res => {
          if (res !== undefined) {
            let dataPim = []
            let dataDev = []
            let dataDevSaldo = []
            let dataCertSaldo = []
            let dataFechas = []
            res.forEach(function (eje) {
              if (eje.vigente) {
                that.chtOptREG.series.push(eje.avance)
                that.chtOptREG.colors.push(that.getColorSemaforo(eje.avance, 'hex'))
                that.chtREG = new ApexCharts(document.querySelector('#divChtREG'), that.chtOptREG)
                that.chtREG.render()
                that.egPliego = eje
              }
              dataDev.push(eje.devengado)
              dataDevSaldo.push(eje.pim - eje.devengado)
              dataCertSaldo.push(eje.pim - eje.certificacion)
              dataPim.push(eje.pim)
              dataFechas.push(eje.webScraping.fechaActualizacion)
            })
            that.chtOptLTEG.xaxis.categories = dataFechas.reverse()
            let revDevSaldo = dataDevSaldo.reverse()
            let revDev = dataDev.reverse()
            let revPim = dataPim.reverse()
            let revCertiSaldo = dataCertSaldo.reverse()
            that.chtOptLTEG.series.push({ name: 'PIM', data: revPim })
            that.chtOptLTEG.series.push({ name: 'Por Devengar', data: revDevSaldo })
            that.chtOptLTEG.series.push({ name: 'Por Certificar', data: revCertiSaldo })
            that.chtOptLTEG.series.push({ name: 'Devengado', data: revDev })
            that.chtOptLTEG.yaxis.max = Math.max(...revPim) + 1000000
            that.chtOptLTEG.yaxis.min = Math.min(...dataDev) - 5000000
            that.chtLTEG = new ApexCharts(document.querySelector('#divChtLTEG'), that.chtOptLTEG)
            that.chtLTEG.render()
          }
        })
        .catch(error => {
          if (error.response.status === 401 || error.response.status === 500) {
            console.log('error de acceso')
          }
        })

      this.$store
        .dispatch('gsplanifica/REPORTES_GETCLASIFICADORES', { anio: that.AnioSel, actProy: that.ActProySel.value, codSeccion: that.PliegoSel, seccion: 'pliego', nivelClasificador: 'G', modulo: 'covid19' })
        .then(res => {
          if (res !== undefined) {
            res.forEach(function (clas) {
              if (clas.listaEG.length !== 0) {
                that.chtOptClas.series.push(clas.listaEG[0].avance)
                that.chtOptClas.labels.push(clas.clasificadorGasto.clasificador + ' ' + clas.clasificadorGasto.nombre)
              }
            })
            that.chtClas = new ApexCharts(document.querySelector('#divChtClas'), that.chtOptClas)
            that.chtClas.render()
          }
        })
        .catch(error => {
          console.log('error de acceso' + error)
        })

      this.$store
        .dispatch('gsplanifica/REPORTES_GETFUENTEFINAN', { anio: that.AnioSel, actProy: that.ActProySel.value, codSeccion: that.PliegoSel, seccion: 'pliego', modulo: 'covid19' })
        .then(res => {
          if (res !== undefined) {
            res.forEach(function (fuefi) {
              if (fuefi.listaEG.length !== 0) {
                that.chtOptFF.series.push(fuefi.listaEG[0].avance)
                that.chtOptFF.labels.push(fuefi.fuenteFinanciamiento.codigo + ' ' + fuefi.fuenteFinanciamiento.nombre)
              }
            })
            that.chtFF = new ApexCharts(document.querySelector('#divChtFF'), that.chtOptFF)
            that.chtFF.render()
          }
        })
        .catch(error => {
          console.log('error de acceso' + error)
        })

      this.$store
        .dispatch('gsplanifica/REPORTES_GETEJECUTORAS', { anio: that.AnioSel, actProy: that.ActProySel.value, codPliego: that.PliegoSel, codEjecutora: 'todos', modulo: 'covid19' })
        .then(res => {
          if (res !== undefined) {
            that.totalPIAUES = 0
            that.totalPIMUES = 0
            that.totalCertificadoUES = 0
            that.totalXCertificadoUES = 0
            that.totalDEVUES = 0
            that.totalXDEVUES = 0
            that.totalAvanceUES = 0
            res.forEach(function (eje) {
              if (eje.listaEG.length !== 0) {
                that.egUES.push({ cod: eje.codFinan, eg: eje })
                that.totalPIMUES = that.totalPIMUES + eje.listaEG[0].pim
                that.totalCertificadoUES = that.totalCertificadoUES + eje.listaEG[0].certificacion
                that.totalXCertificadoUES = that.totalXCertificadoUES + (eje.listaEG[0].pim - eje.listaEG[0].certificacion)
                that.totalDEVUES = that.totalDEVUES + eje.listaEG[0].devengado
                that.totalXDEVUES = that.totalXDEVUES + (eje.listaEG[0].pim - eje.listaEG[0].devengado)
                that.totalAvanceUES = that.totalAvanceUES + eje.listaEG[0].avance
                let colorSema = that.getColorSemaforo(eje.listaEG[0].avance, 'cls')
                let nombreEje = eje.nombre.replace('REGION SAN MARTIN', '')
                nombreEje = nombreEje.replace('-', '')
                nombreEje = nombreEje.trim()
                let xcert = eje.listaEG[0].pim - eje.listaEG[0].certificacion
                let xdevengar = eje.listaEG[0].pim - eje.listaEG[0].devengado
                that.tableUESEG.push({
                  ue: { color: colorSema, codigo: eje.codPptal + '-' + eje.codFinan, nombre: nombreEje },
                  pim_ue: eje.listaEG[0].pim,
                  certificado_ue: eje.listaEG[0].certificacion,
                  xcertificar_ue: xcert,
                  devengado_ue: eje.listaEG[0].devengado,
                  xdevengar_ue: xdevengar,
                  avance_ue: { color: 'danger', count: eje.listaEG[0].avance }
                })
              }
            })
            let totalAv = (that.totalDEVUES / that.totalPIMUES * 100)
            that.totalAvanceUES = Math.round(totalAv * 100) / 100
          }
        })
        .catch(error => {
          if (error.response.status === 401 || error.response.status === 500) {
            console.log('error de acceso')
          }
        })
    },
    getNumbertoMoney (num, symbol) {
      return symbol + ' ' + Number(num).toLocaleString('en-US')
    },
    getColorSemaforo (avance, format) {
      let color = 'success'
      if (this.tipoSemaforo === 'FIJO') {
        if (avance >= 50) {
          color = 'success'
        } else if (avance >= 33 && avance < 50) {
          color = 'warning'
        } else {
          color = 'danger'
        }
      } else {
        let diasFeb = new Date(this.anioV, 2, 0).getDate()
        let diaActualAnio = Math.ceil((this.fechaHoy - new Date(this.fechaHoy.getFullYear(), 0, 1)) / 86400000) - 1
        let diasAnio = 365
        if (diasFeb === 29) {
          diasAnio = 366
        }
        let porcDiario = 100 / diasAnio
        let porcIdeal = diaActualAnio * porcDiario
        let valueDifference = avance - porcIdeal

        if (valueDifference >= 0) {
          color = 'success'
        } else {
          if (valueDifference > -6) {
            color = 'warning'
          } else {
            color = 'danger'
          }
        }
      }
      if (format === 'hex') {
        color = this.colorClstoHex(color)
      }
      return color
    },
    colorClstoHex (cls) {
      let color
      switch (cls) {
        case 'danger':
          color = '#ff0b22'
          break
        case 'success':
          color = '#0ae43c'
          break
        case 'warning':
          color = '#ffc20b'
          break
        default:
          color = ''
          break
      }
      return color
    },
    getNombreEjecuctora (ejecutora) {
      let ejeFt = ejecutora.replace('REGION SAN MARTIN', '')
      ejeFt = ejeFt.replace('GOB.REG. DPTO. SAN MARTIN', '')
      ejeFt = ejeFt.replace('GOB.REG.DPTO. SAN MARTIN', '')
      ejeFt = ejeFt.replace('GOB. REG. SAN MARTIN', '')

      ejeFt = ejeFt.replace('REGION LA LIBERTAD', '')
      ejeFt = ejeFt.replace('GOB. REG. DE LA LIBERTAD', '')
      ejeFt = ejeFt.replace('R.LA LIBERTAD', '')

      ejeFt = ejeFt.replace('-', '')
      ejeFt = ejeFt.trim()
      return ejeFt
    },
    truncateStr (str, length) {
      let dots = str.length > length ? '...' : ''
      return str.substring(0, length) + dots
    }
  },
  created () {
  },
  computed: {
    ...mapGetters({
      page: 'Setting/activePage',
      navList: 'Setting/globalSearchState',
      bookmark: 'Setting/bookmarkState'
    })
  }
}
</script>

<style>
.iq-card-body{
  flex: unset;
}
</style>
