import apiCall from '../index.js'

const apiService = '/api-datamanager/api/v1/productoproyecto'

const service = {
  findAll (params) {
    let query = '?codTipo=' + params.codTipo + '&tipo=' + params.tipo
    return new Promise((resolve, reject) => {
      apiCall.get(apiService + query).then(res => resolve(res.data)).catch(err => reject(err))
    })
  },
  getNoPriorizados (params) {
    let query = '/no-priorizados?codTipo=' + params.codTipo + '&tipo=' + params.tipo
    return new Promise((resolve, reject) => {
      apiCall.get(apiService + query).then(res => resolve(res.data)).catch(err => reject(err))
    })
  },
  create (model) {
    return new Promise((resolve, reject) => {
      apiCall.post(apiService, model).then(res => resolve(res.data)).catch(err => reject(err))
    })
  },
  update (model) {
    return new Promise((resolve, reject) => {
      apiCall.put(apiService, model).then(res => resolve(res.data)).catch(err => reject(err))
    })
  },
  find (id) {
    return new Promise((resolve, reject) => {
      apiCall.get(apiService + id).then(res => resolve(res.data)).catch(err => reject(err))
    })
  },
  delete (model) {
    return new Promise((resolve, reject) => {
      apiCall.delete(apiService + model.idFuncionario).then(res => resolve(res.data)).catch(err => reject(err))
    })
  }
}

export default service
