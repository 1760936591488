import apiCall from '../index.js'
import config from '../config'

const apiService = '/api-datamanager/api/v1/reportes/'
const apiServiceUE = '/api-datamanager/api/v1/unidadejecutora'

const service = {
  getPliegos (params) {
    let query = 'getPliegos?anio=' + params.anio + '&actProy=' + params.actProy + '&codPliego=' + params.codPliego + '&modulo=' + params.modulo
    return new Promise((resolve, reject) => {
      apiCall.get(apiService + query).then(res => resolve(res.data)).catch(err => reject(err))
    })
  },
  getLineaTiempo (params) {
    let query = 'getLineaTiempo?anio=' + params.anio + '&actProy=' + params.actProy + '&codSeccion=' + params.codSeccion + '&seccion=' + params.seccion + '&cantDias=' + params.cantDias + '&modulo=' + params.modulo
    return new Promise((resolve, reject) => {
      apiCall.get(apiService + query).then(res => resolve(res.data)).catch(err => reject(err))
    })
  },
  getLineaTiempoMensual (params) {
    let query = 'getHistorialMesAnio?anioInicial=' + params.anioInicial + '&anioFinal=' + params.anioFinal + '&actProy=' + params.actProy + '&codSeccion=' + params.codSeccion + '&seccion=' + params.seccion
    return new Promise((resolve, reject) => {
      apiCall.get(apiService + query).then(res => resolve(res.data)).catch(err => reject(err))
    })
  },
  getProdProy (params) {
    let query = 'getProdProy?anio=' + params.anio + '&actProy=' + params.actProy + '&codSeccion=' + params.codSeccion + '&seccion=' + params.seccion + '&limit=' + params.limit + '&modulo=' + params.modulo
    return new Promise((resolve, reject) => {
      apiCall.get(apiService + query).then(res => resolve(res.data)).catch(err => reject(err))
    })
  },
  getEjecutoras (params) {
    let query = 'getUnidadesEjecutoras?actProy=' + params.actProy + '&anio=' + params.anio + '&codEjecutora=' + params.codEjecutora + '&codPliego=' + params.codPliego + '&modulo=' + params.modulo
    return new Promise((resolve, reject) => {
      apiCall.get(apiService + query).then(res => resolve(res.data)).catch(err => reject(err))
    })
  },
  getListaEjecutoras (params) {
    let query = '?codPliego=' + params.codPliego
    return new Promise((resolve, reject) => {
      apiCall.get(apiServiceUE + query).then(res => resolve(res.data)).catch(err => reject(err))
    })
  },
  getClasificadores (params) {
    let query = 'getClasificadoresGasto?actProy=' + params.actProy + '&anio=' + params.anio + '&codSeccion=' + params.codSeccion + '&nivelClasificador=' + params.nivelClasificador + '&seccion=' + params.seccion + '&modulo=' + params.modulo
    return new Promise((resolve, reject) => {
      apiCall.get(apiService + query).then(res => resolve(res.data)).catch(err => reject(err))
    })
  },
  getClasificadoresPy (params) {
    let query = 'getClasificadoresGastoPy?actProy=' + params.actProy + '&anio=' + params.anio + '&codSeccion=' + params.codSeccion + '&nivelClasificador=' + params.nivelClasificador + '&modulo=' + params.modulo
    return new Promise((resolve, reject) => {
      apiCall.get(apiService + query).then(res => resolve(res.data)).catch(err => reject(err))
    })
  },
  getPliegosXls (params) {
    let query = 'direct-xls/getPliegos?anio=' + params.anio + '&actProy=' + params.actProy + '&codPliego=' + params.codPliego + '&modulo=' + params.modulo
    return config.baseURL + apiService + query
  },
  getEjecutorasXls (params) {
    let query = 'direct-xls/getUnidadesEjecutoras?actProy=' + params.actProy + '&anio=' + params.anio + '&codEjecutora=' + params.codEjecutora + '&codPliego=' + params.codPliego + '&modulo=' + params.modulo
    return config.baseURL + apiService + query
  },
  getClasificadoresXls (params) {
    let query = 'direct-xls/getClasificadoresGasto?actProy=' + params.actProy + '&anio=' + params.anio + '&codSeccion=' + params.codSeccion + '&nivelClasificador=' + params.nivelClasificador + '&seccion=' + params.seccion + '&modulo=' + params.modulo
    return config.baseURL + apiService + query
  },
  getFuenteFinan (params) {
    let query = 'getFuentesFinanciamiento?actProy=' + params.actProy + '&anio=' + params.anio + '&codSeccion=' + params.codSeccion + '&seccion=' + params.seccion + '&modulo=' + params.modulo
    return new Promise((resolve, reject) => {
      apiCall.get(apiService + query).then(res => resolve(res.data)).catch(err => reject(err))
    })
  }
}

export default service
