// import actions
import presupuestoAction from './actions/presupuesto'
import ejecucionPresupuestalAction from './actions/ejecucionPresupuestal'
import ejecutoraAction from './actions/ejecutora'

// import getters
import presupuestoGetter from './getters/presupuesto'
import ejecucionPresupuestalGetter from './getters/ejecucionPresupuestal'
import ejecutoraGetter from './getters/ejecutora'

// import mutations
import presupuestoMutation from './mutations/presupuesto'
import ejecucionPresupuestalMutation from './mutations/ejecucionPresupuestal'
import ejecutoraMutation from './mutations/ejecutora'

// import states
import presupuestoState from './states/presupuesto'
import ejecucionPresupuestalState from './states/ejecucionPresupuestal'
import ejecutoraState from './states/ejecutora'

const actions = Object.assign({}, presupuestoAction, ejecucionPresupuestalAction, ejecutoraAction)

const getters = Object.assign({}, presupuestoGetter, ejecucionPresupuestalGetter, ejecutoraGetter)

const mutations = Object.assign({}, presupuestoMutation, ejecucionPresupuestalMutation, ejecutoraMutation)

const state = Object.assign({}, presupuestoState, ejecucionPresupuestalState, ejecutoraState)

export default {
  namespaced: true,
  actions,
  state,
  getters,
  mutations
}
