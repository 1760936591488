export default {
  getProyectoTpl: (state) => {
    if (state.operacion === 'list') {
      return 'ProyectoList'
    } else if (state.operacion === 'new') {
      return 'ProyectoFrm'
    } else if (state.operacion === 'edit') {
      return 'ProyectoFrm'
    }
  }
}
