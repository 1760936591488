import funcionarioService from '@/services/GSPlanifica/funcionario'

export default {
  'FUNCIONARIO_FIND_ALL': ({ commit }, params) => {
    return new Promise((resolve, reject) => {
      funcionarioService.findAll().then(res => {
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  },
  'FUNCIONARIO_CREATE': ({ commit }, params) => {
    return new Promise((resolve, reject) => {
      funcionarioService.create(params).then(res => {
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  },
  'FUNCIONARIO_UPDATE': ({ commit }, params) => {
    return new Promise((resolve, reject) => {
      funcionarioService.update(params).then(res => {
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  },
  'FUNCIONARIO_FIND': ({ commit }, params) => {
    return new Promise((resolve, reject) => {
      funcionarioService.find(params.id).then(res => {
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  },
  'FUNCIONARIO_DELETE': ({ commit }, params) => {
    return new Promise((resolve, reject) => {
      funcionarioService.delete(params).then(res => {
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  }
}
