import Vue from 'vue'
import VueRouter from 'vue-router'
/* Layouts */
import AuthLayout1 from '../layouts/AuthLayouts/AuthLayout1'
/* import Default from '../layouts/BlankLayout' */
import LayoutMain from '../layouts/LayoutMain'
/* Dashboards View */
import Dashboard from '../views/GSPlanifica/Dashboard.vue'
import DashboardCovid from '../views/GSPlanifica/DashboardCovid.vue'
import DashboardUE from '../views/GSPlanifica/DashboardUE.vue'
import DashboardFuncionario from '../views/GSPlanifica/Administracion/Funcionario.vue'
import Proyecto from '../views/GSPlanifica/Administracion/Proyecto/Proyecto.vue'
/* Sirsam vue */
import SirsamExpSiaf from '../views/Sirsam/EjecucionPresupuestal/EjecucionPresupuestal.vue'
/* Authentic View */
import SignIn1 from '../views/AuthPages/Default/SignIn1'
import SignUp1 from '../views/AuthPages/Default/SignUp1'
import RecoverPassword1 from '../views/AuthPages/Default/RecoverPassword1'
import LockScreen1 from '../views/AuthPages/Default/LockScreen1'
import ConfirmMail1 from '../views/AuthPages/Default/ConfirmMail1'

Vue.use(VueRouter)

const administracionChildRoute = (prop, mode) => [
  {
    path: 'proyectos',
    name: prop + '.proyectos',
    meta: { dark: mode, auth: true, name: 'Proyectos' },
    component: Proyecto
  },
  {
    path: 'funcionario',
    name: prop + '.funcionario',
    meta: { dark: mode, auth: true, name: 'Funcionarios' },
    component: DashboardFuncionario
  }
]

const childDashboardRoutes = (prop, mode) => [
  {
    path: 'ejecucion-pptal/pliego',
    name: prop + '.dashboard',
    meta: { dark: mode, auth: true, name: 'Tablero de Control' },
    component: Dashboard
  },
  {
    path: 'ejecucion-pptal/covid',
    name: prop + '.dashboard-covid',
    meta: { dark: mode, auth: true, name: 'Tablero Recursos Covid-19' },
    component: DashboardCovid
  },
  {
    path: 'ejecucion-pptal/ejecutora',
    name: prop + '.dashboard-ue',
    meta: { dark: mode, auth: true, name: 'Tablero Unidad Ejecutora' },
    component: DashboardUE
  }
]

const sirsamChildRoute = (prop, mode) => [
  {
    path: 'expedientes-siaf',
    name: prop + '.expsiaf',
    meta: { dark: mode, auth: true, name: 'Expedientes Siaf' },
    component: SirsamExpSiaf
  }
]

const authChildRoutes = (prop, mode = false) => [
  {
    path: 'sign',
    name: prop + '.sign',
    meta: { dark: mode, auth: true },
    component: SignIn1
  },
  {
    path: 'sign-up1',
    name: prop + '.sign-up1',
    meta: { dark: mode, auth: true },
    component: SignUp1
  },
  {
    path: 'password-reset1',
    name: prop + '.password-reset1',
    meta: { dark: mode, auth: true },
    component: RecoverPassword1
  },
  {
    path: 'lock-screen1',
    name: prop + '.lock-screen1',
    meta: { dark: mode, auth: true },
    component: LockScreen1
  },
  {
    path: 'confirm-mail1',
    name: prop + '.confirm-mail1',
    meta: { dark: mode, auth: true },
    component: ConfirmMail1
  }
]

const routes = [
  {
    path: '/',
    name: 'root',
    component: LayoutMain,
    meta: { auth: true }
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: LayoutMain,
    meta: { auth: true },
    children: childDashboardRoutes('dashboard')
  },
  {
    path: '/sirsam',
    name: 'sirsam',
    component: LayoutMain,
    meta: { auth: true },
    children: sirsamChildRoute('sirsam')
  },
  {
    path: '/administracion',
    name: 'administracion',
    component: LayoutMain,
    meta: { auth: true },
    children: administracionChildRoute('administracion')
  },
  {
    path: '/auth',
    name: 'auth1',
    component: AuthLayout1,
    meta: { auth: true },
    children: authChildRoutes('auth1')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: '/',
  routes
})
router.beforeEach((to, from, next) => {
  const publicPages = ['/auth/sign', '/auth/sign-up1']
  const authRequired = !publicPages.includes(to.path)
  const loggedIn = localStorage.getItem('user')
  if (to.meta.auth) {
    if (authRequired && loggedIn === null) {
      return next('/auth/sign')
    } else if (to.name === 'dashboard' || to.name === 'root') {
      return next('/dashboard/ejecucion-pptal/pliego')
    }
  }
  next()
})

export default router
